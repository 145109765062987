import { Box } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';

import { DropColor, DropTypography } from 'app/theme';

export const readingValuePlaceholder = '------';

interface ReadingTargetProps {
  value: string | undefined;
}

export const ReadingTarget: FC<ReadingTargetProps> = memo(
  function ReadingTarget({ value }) {
    return (
      <Box
        sx={{
          typography: DropTypography.Body1,
          color: DropColor.TextSecondary,
        }}
      >
        {value ?? readingValuePlaceholder}
      </Box>
    );
  }
);
