interface SignHmacArgs {
  data: string;
  secret: string;
}

/**
 * Signs data with HMAC and SHA-256 hash using given secret
 */
export const signHmac = async ({
  data,
  secret,
}: SignHmacArgs): Promise<string> => {
  const encoder = new TextEncoder();
  const key = await crypto.subtle.importKey(
    'raw',
    encoder.encode(secret),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );
  const signature = await crypto.subtle.sign('HMAC', key, encoder.encode(data));

  const sigHex = Buffer.from(signature).toString('hex');
  return sigHex;
};
