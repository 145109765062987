import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import type { MqttConnectionInfo } from 'api/mqtt';
import type { DropDeviceEventsIncomingMessages } from 'features/device/DeviceEvents';
import { handleDeviceEventsOnMessage } from 'features/device/deviceEventsSagas';
import { mockDropMqttConnectionInfo } from 'mocks/user/eventsChannel';

const mockMqttConnectionInfo: MqttConnectionInfo = {
  ...mockDropMqttConnectionInfo,
  subscriptionTopics: mockDropMqttConnectionInfo.events.map((x) => x.topic),
};

/**
 * Mock event to emulate device MQTT message
 */
export const mockIncomingDeviceMqttMessageRequested = createAction<{
  topic: string;
  message: DropDeviceEventsIncomingMessages;
}>('deviceEventsSlice/mockIncomingDeviceMqttMessageRequested');

function* mockIncomingDeviceMqttMessage({
  payload: { topic, message },
}: PayloadAction<{
  topic: string;
  message: DropDeviceEventsIncomingMessages;
}>): SagaIterator<void> {
  yield call(handleDeviceEventsOnMessage, {
    topic,
    payload: message,
    connectionInfo: mockMqttConnectionInfo,
  });
}

export function* mockIncomingDeviceMqttMessageRequestedWatcher(): SagaIterator<void> {
  yield takeEvery(
    mockIncomingDeviceMqttMessageRequested,
    mockIncomingDeviceMqttMessage
  );
}

export const mockMqttEventsSagasRestartable = [
  mockIncomingDeviceMqttMessageRequestedWatcher,
];
