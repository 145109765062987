import type { FC } from 'react';
import { memo } from 'react';

import { Button, ButtonColor } from 'components/Button/Button';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';

export const controlOnApplianceButtonText = 'Control on appliance';

export const ControlOnApplianceButton: FC<ProgramControlsProps> = memo(
  function ControlOnApplianceButton() {
    return (
      <Button color={ButtonColor.Secondary} disabled>
        {controlOnApplianceButtonText}
      </Button>
    );
  }
);
