export const getUrlHost = (fullUrl: string | null): string | null => {
  if (!fullUrl) {
    return null;
  }

  try {
    return new URL(fullUrl).host;
  } catch {
    return fullUrl;
  }
};
