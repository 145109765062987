import padStart from 'lodash/padStart';

export const formatSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60) || 0;
  const hours = Math.floor(minutes / 60) || 0;
  const minutesRemainder = minutes % 60 || 0;
  const secondsRemainder = seconds % 60 || 0;
  return `${padStart(hours.toString(), 2, '0')}:${padStart(
    minutesRemainder.toString(),
    2,
    '0'
  )}:${padStart(secondsRemainder.toString(), 2, '0')}`;
};
