import { Grid, Box, Skeleton } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropBorderRadius, DropColor } from 'app/theme';
import * as sxHeader from 'features/recipe/view/Header.styles';
import * as sxIngredients from 'features/recipe/view/Ingredients.styles';
import * as sxPage from 'features/recipe/view/RecipeViewPage.styles';
import * as sxServings from 'features/recipe/view/Servings.styles';
import * as sxSummary from 'features/recipe/view/Summary.styles';
import { sxCompose } from 'utils/sxCompose';

import backgroundPlaceholder from './header-background-placeholder.png';

export const recipeViewPageLoadingAriaLabel = 'Loading recipe';

export const RecipeViewPageSkeleton: FC = memo(
  function RecipeViewPageSkeleton() {
    return (
      <Box sx={sxPage.sxWrapper} aria-label={recipeViewPageLoadingAriaLabel}>
        <Box
          sx={sxCompose(sxHeader.sxWrapper, {
            backgroundImage: `url(${backgroundPlaceholder})`,
          })}
        >
          <Skeleton
            variant="rectangular"
            height={64}
            width={64}
            sx={sxCompose(sxHeader.sxExternalRecipeImage, {
              backgroundColor: DropColor.SurfaceSecondary,
            })}
          />
          <div>
            <Skeleton
              variant="text"
              height={20}
              width={380}
              sx={{
                marginTop: '7px',
                backgroundColor: DropColor.SurfaceSecondary,
              }}
            />
            <Skeleton
              variant="text"
              height={20}
              width={220}
              sx={{
                marginTop: '13px',
                backgroundColor: DropColor.SurfaceSecondary,
              }}
            />
          </div>
        </Box>
        <Box sx={sxSummary.sxStickyWrapper}>
          <Box sx={sxSummary.sxBackgroundWrapper}>
            <Box sx={sxSummary.sxBackground(false)} />
          </Box>
          <Box sx={sxSummary.sxContentFlexWrapper}>
            <Box sx={sxSummary.sxContentWrapper}>
              <Box sx={sxSummary.sxSectionWrapper}>
                <Box sx={sxSummary.sxTitle}>
                  <Skeleton variant="text" height={16} width={83} />
                </Box>
                <Box sx={sxSummary.sxText}>
                  <Skeleton
                    variant="text"
                    height={16}
                    width={113}
                    sx={{ marginTop: 3 }}
                  />
                </Box>
              </Box>
              <Box sx={sxSummary.sxSectionWrapper}>
                <Box sx={sxSummary.sxTitle}>
                  <Skeleton variant="text" height={16} width={83} />
                </Box>
                <Box sx={sxSummary.sxText}>
                  <Skeleton
                    variant="text"
                    height={16}
                    width={113}
                    sx={{ marginTop: 3 }}
                  />
                </Box>
              </Box>
              <Box sx={sxSummary.sxSectionWrapper}>
                <Box sx={sxSummary.sxTitle}>
                  <Skeleton variant="text" height={16} width={83} />
                </Box>
                <Box sx={sxSummary.sxText}>
                  <Skeleton
                    variant="text"
                    height={16}
                    width={113}
                    sx={{ marginTop: 3 }}
                  />
                </Box>
              </Box>
              <Skeleton
                variant="rectangular"
                height={60}
                sx={sxCompose(sxSummary.sxButton, {
                  borderRadius: DropBorderRadius.Pill,
                })}
              />
            </Box>
          </Box>
        </Box>
        <Grid container justifyContent="center">
          <Grid item xs={10} sx={sxPage.sxContent}>
            <Box sx={sxIngredients.sxTitleWrapper}>
              <Box sx={sxIngredients.sxTitle}>
                <Skeleton variant="text" height={20} width={152} />
              </Box>

              <Box sx={sxServings.sxWrapper}>
                <Box sx={sxServings.sxButtonWrapper}>
                  <Skeleton variant="circular" height={60} width={60} />
                </Box>
                <Box sx={sxServings.sxServingsWrapper}>
                  <Box sx={sxServings.sxTitle}>
                    <Skeleton
                      variant="text"
                      height={16}
                      width={83}
                      sx={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Box>
                  <Box sx={sxServings.sxText(false)}>
                    <Skeleton
                      variant="text"
                      height={16}
                      width={100}
                      sx={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={sxServings.sxButtonWrapper}>
                  <Skeleton variant="circular" height={60} width={60} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
);
