import { Box } from '@mui/material';
import range from 'lodash/range';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { DropAppIcon } from 'components/DropAppIcon/DropAppIcon';
import {
  sxAppIcon,
  sxCollectionName,
  sxHeader,
  sxList,
  sxRecipesCount,
  sxWrapper,
} from 'features/favorites/FavoritesPage.styles';
import { FavoritesPageSkeleton } from 'features/favorites/FavoritesPageSkeleton';
import { IntroductionScreen } from 'features/favorites/IntroductionScreen/IntroductionScreen';
import { RecipeCard } from 'features/favorites/RecipeCard';
import { RecipeCardSkeleton } from 'features/favorites/RecipeCardSkeleton';
import { tm6CollectionName } from 'features/favorites/constants';
import {
  favoritesNextPageRequested,
  selectFavoritesAll,
  selectFavoritesError,
  selectFavoritesFetched,
  selectFavoritesFetching,
  selectFavoritesHasMore,
  selectFavoritesTotalRecipes,
} from 'features/favorites/favoritesSlice';
import { recipeFinishRequested } from 'features/recipe/recipeSlice/sagas';

import { PullToRefresh } from './PullToRefresh';
import { Scroller } from './Scroller';

export const FavoritesPage: FC = memo(function FavoritesPage() {
  const dispatch = useDispatch();

  const isFetching = useSelector(selectFavoritesFetching);
  const isFetched = useSelector(selectFavoritesFetched);
  const fetchError = useSelector(selectFavoritesError);
  const hasMore = useSelector(selectFavoritesHasMore);
  const recipes = useSelector(selectFavoritesAll);
  const totalRecipes = useSelector(selectFavoritesTotalRecipes);

  useEffect(() => {
    if (!isFetching && !fetchError && hasMore) {
      dispatch(favoritesNextPageRequested());
    }
  }, [dispatch, fetchError, hasMore, isFetching]);

  useEffect(() => {
    dispatch(recipeFinishRequested());
  }, [dispatch]);

  if (!isFetched) {
    return <FavoritesPageSkeleton />;
  }

  return (
    <Box sx={sxWrapper}>
      <PullToRefresh />
      {totalRecipes ? (
        <>
          <Box sx={sxHeader}>
            <DropAppIcon sx={sxAppIcon} />
            <div>
              <Box sx={sxCollectionName}>{tm6CollectionName}</Box>
              <Box sx={sxRecipesCount}>{totalRecipes} Recipes</Box>
            </div>
          </Box>
          <Box sx={sxList}>
            {recipes.map((recipe) => (
              <Link
                key={recipe.id}
                to={`/recipes/${recipe.id}`}
                aria-label={recipe.name}
              >
                <RecipeCard recipe={recipe} />
              </Link>
            ))}

            {hasMore &&
              isFetching &&
              range(1, 4).map((i) => (
                <Box key={i}>
                  <RecipeCardSkeleton />
                </Box>
              ))}
          </Box>
          <Scroller />
        </>
      ) : (
        <IntroductionScreen />
      )}
    </Box>
  );
});
