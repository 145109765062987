import { authTokenApiUrl } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { DropUserFull } from 'types/api/user';

export const apiAuthClientId = 'd17d7c17-1695-4f76-bd41-0294bc0de75e';

export enum ApiAuthGrantType {
  RefreshToken = 'refresh_token',
  Password = 'password',
  AuthorizationCode = 'authorization_code',
}

export interface ApiAuthResponse {
  expiresIn: number;
  refreshToken: string;
  accessToken: string;
  user: DropUserFull;
}

export interface ApiAuthRequestRefreshToken {
  grantType: ApiAuthGrantType.RefreshToken;
  refreshToken: string;
}

export interface ApiAuthRequestAuthorizationCode {
  grantType: ApiAuthGrantType.AuthorizationCode;
  code: string;
}

export interface ApiAuthRequestPasswordLogin {
  grantType: ApiAuthGrantType.Password;
  username: string;
  password: string;
}

export type ApiPostAuthRequest =
  | ApiAuthRequestRefreshToken
  | ApiAuthRequestAuthorizationCode
  | ApiAuthRequestPasswordLogin;

export const apiPostAuth = (request: ApiPostAuthRequest) => {
  const body = new URLSearchParams();

  body.append('grant_type', request.grantType);
  body.append('client_id', apiAuthClientId);

  // eslint-disable-next-line default-case
  switch (request.grantType) {
    case ApiAuthGrantType.RefreshToken: {
      body.append('refresh_token', request.refreshToken);
      break;
    }
    case ApiAuthGrantType.AuthorizationCode: {
      body.append('code', request.code);
      break;
    }
    case ApiAuthGrantType.Password: {
      body.append('username', request.username);
      body.append('password', request.password);
      break;
    }
  }

  return fetchJson<ApiAuthResponse>({
    apiContext: { authData: null },
    url: authTokenApiUrl,
    body,
  });
};
