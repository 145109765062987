import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { CancelButton } from 'features/recipe/make/step/applianceControls/CancelButton';
import { ControlOnApplianceButton } from 'features/recipe/make/step/applianceControls/ControlOnApplianceButton';
import { PauseButton } from 'features/recipe/make/step/applianceControls/PauseButton';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';

export const ProgramRunningControls: FC<ProgramControlsProps> = memo(
  function ProgramRunningControls({ step }) {
    const usedApplianceCategory = useSelector(selectStepUsedAppliance(step))
      ?.appliance.category;

    switch (usedApplianceCategory) {
      case DropApplianceCategory.Timer:
        return (
          <>
            <CancelButton step={step} />
            <PauseButton step={step} />
          </>
        );
      // TODO: Category is absolutely not enough to figure out necessary controls,
      // but should be good enough for beta
      case DropApplianceCategory.Robot:
        return <ControlOnApplianceButton step={step} />;
      case DropApplianceCategory.Oven:
        return <CancelButton step={step} />;
      default:
        return null;
    }
  }
);
