import { Box, Grid } from '@mui/material';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  recipeFetchRequested,
  selectRecipe,
} from 'features/recipe/recipeSlice/slice';
import { About } from 'features/recipe/view/About';
import { Header } from 'features/recipe/view/Header';
import { Ingredients } from 'features/recipe/view/Ingredients';
import {
  sxContent,
  sxWrapper,
} from 'features/recipe/view/RecipeViewPage.styles';
import { RecipeViewPageSkeleton } from 'features/recipe/view/RecipeViewPageSkeleton';
import { Summary } from 'features/recipe/view/Summary';
import { Tools } from 'features/recipe/view/Tools';

export const recipeViewPageLoadingAriaLabel = 'Loading recipe';

export const RecipeViewPage: FC = memo(function RecipeViewPage() {
  const dispatch = useDispatch();
  const recipe = useSelector(selectRecipe);

  const { recipeId } = useParams<{ recipeId: string }>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const id = parseInt(recipeId!);
    dispatch(recipeFetchRequested(id));
  }, [dispatch, recipeId]);

  if (recipe?.id.toString() !== recipeId) {
    return <RecipeViewPageSkeleton />;
  }

  return (
    <Box sx={sxWrapper} aria-label={recipeViewPageLoadingAriaLabel}>
      <Header />
      <Summary />
      <Grid container justifyContent="center">
        <Grid item xs={10} sx={sxContent}>
          <Ingredients />
          <About />
          <Tools />
        </Grid>
      </Grid>
    </Box>
  );
});
