import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';
import { loginSuccess, logoutSuccess } from 'features/login/loginSlice';
import type { DropRecipeClient } from 'types/api/recipe';

export enum CurrentStepVisibility {
  Visible = 'visible',
  Above = 'above',
  Below = 'below',
}

interface RecipeState {
  isFetching: boolean;
  error?: string;
  recipe?: DropRecipeClient;
  /**
   * Current recipe rating by current user, fetched from recipe.rated
   * Kept separately in order not to mutate recipe and not to cause rerender of other components
   */
  recipeUserRating?: number;

  // View page
  originalServings?: number;
  targetServings?: number;
  isScalingPending: boolean;

  // Make page
  /**
   * When user is starting recipe we need to make a backend call to /recipe/:id/start,
   * this property marks if that call was done or not
   */
  isStarted: boolean;
  currentStepIndex: number | null;
  currentStepVisibility: CurrentStepVisibility | null;
  /**
   * We want to be able to force scroll page to current step from another components
   * it's convenient to do it via redux
   */
  scrollToCurrentStepPending: boolean;
}

export const initialState: RecipeState = {
  isFetching: false,
  isScalingPending: false,
  isStarted: false,
  currentStepIndex: 0,
  currentStepVisibility: CurrentStepVisibility.Visible,
  scrollToCurrentStepPending: false,
};

const recipeSlice = createSlice({
  name: 'recipeSlice',
  initialState,
  reducers: {
    recipeFetchRequested(state, { payload }: PayloadAction<number>) {
      state.error = undefined;
      if (state.recipe?.id !== payload) {
        state.isFetching = true;
        state.recipe = undefined;
        state.recipeUserRating = undefined;
        state.isStarted = false;
      }
    },
    recipeFetchFinished(
      _state,
      { payload }: PayloadAction<DropRecipeClient>
    ): RecipeState {
      return {
        ...initialState,
        recipe: payload,
        recipeUserRating: payload.rated ?? undefined,
        originalServings: payload.nutrition.servings,
        targetServings: payload.nutrition.servings,
      };
    },
    recipeFetchFailed(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
      state.recipe = undefined;
    },
    recipeScalingRequested(state, { payload }: PayloadAction<number>) {
      state.targetServings = payload;
    },
    recipeScalingStarted(state) {
      state.isScalingPending = true;
    },
    recipeScalingFinished(state, { payload }: PayloadAction<DropRecipeClient>) {
      state.recipe = payload;
      state.isScalingPending = false;
    },
    recipeScalingFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.targetServings = state.recipe?.nutrition.servings;
      state.isScalingPending = false;
    },
    recipeStarted(state) {
      state.isStarted = true;
    },
    recipeFinished(state) {
      state.recipe = undefined;
      state.isStarted = false;
    },
    recipeCurrentStepIndexSet(
      state,
      { payload }: PayloadAction<number | null>
    ) {
      setCurrentStepIndex(state, payload);
    },
    recipeCurrentStepCompleted(state) {
      if (state.currentStepIndex == null || !state.recipe) {
        return;
      }
      const nextStepIndex = state.currentStepIndex + 1;

      if (nextStepIndex < state.recipe.steps.length) {
        setCurrentStepIndex(state, nextStepIndex);
      } else {
        setCurrentStepIndex(state, null);
      }
    },
    recipeCurrentStepVisibilitySet(
      state,
      { payload }: PayloadAction<CurrentStepVisibility>
    ) {
      if (state.currentStepIndex === null || !state.recipe?.steps.length) {
        state.currentStepVisibility = null;
        return;
      }
      state.currentStepVisibility = payload;
    },
    recipeScrollToCurrentStepRequested(state) {
      state.scrollToCurrentStepPending = true;
    },
    recipeScrollToCurrentStepFinished(state) {
      state.scrollToCurrentStepPending = false;
    },
    recipeReviewRequested(
      state,
      {
        payload,
      }: PayloadAction<{
        rating: number;
      }>
    ) {
      state.recipeUserRating = payload.rating;
    },
    recipeReviewFailed(state) {
      state.recipeUserRating = state.recipe?.rated ?? undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
    builder.addCase(loginSuccess, () => initialState);
  },
});

function setCurrentStepIndex(state: RecipeState, index: number | null) {
  state.currentStepIndex = index;
  if (index !== null) {
    state.scrollToCurrentStepPending = true;
  }
}

export const {
  reducer: recipeReducer,
  actions: {
    recipeFetchRequested,
    recipeFetchFinished,
    recipeFetchFailed,
    recipeScalingRequested,
    recipeScalingStarted,
    recipeScalingFinished,
    recipeScalingFailed,
    recipeStarted,
    recipeFinished,
    recipeCurrentStepIndexSet,
    recipeCurrentStepCompleted,
    recipeCurrentStepVisibilitySet,
    recipeScrollToCurrentStepRequested,
    recipeScrollToCurrentStepFinished,
    recipeReviewRequested,
    recipeReviewFailed,
  },
} = recipeSlice;

export const selectRecipeFetched = (state: RootState): boolean =>
  !!state.recipe.recipe;

export const selectRecipeFetching = (state: RootState): boolean =>
  state.recipe.isFetching;

export const selectRecipe = (state: RootState): DropRecipeClient | undefined =>
  state.recipe.recipe;

export const selectRecipeError = (state: RootState): string | undefined =>
  state.recipe.error;

export const selectRecipeTargetServings = (
  state: RootState
): number | undefined => state.recipe.targetServings;

export const selectRecipeOriginalServings = (
  state: RootState
): number | undefined => state.recipe.originalServings;

export const selectRecipeScalingPending = (state: RootState): boolean =>
  state.recipe.isScalingPending;

export const selectRecipeIsStarted = (state: RootState): boolean =>
  state.recipe.isStarted;

export const selectCurrentStepIndex = (state: RootState): number | null =>
  state.recipe.currentStepIndex;

export const selectCurrentStepVisibility = (
  state: RootState
): CurrentStepVisibility | null => state.recipe.currentStepVisibility;

export const selectScrollToCurrentStepPending = (state: RootState): boolean =>
  state.recipe.scrollToCurrentStepPending;

export const selectStepIdsToIndicesMap = createSelector(
  (state: RootState) => state.recipe.recipe?.steps,
  (steps) =>
    steps?.reduce((acc, step, i) => {
      acc[step.id] = i;
      return acc;
    }, {} as Record<number, number>) || {}
);

export const selectStepById = (id: number) => (state: RootState) =>
  state.recipe.recipe?.steps[selectStepIdsToIndicesMap(state)[id]];

/**
 * Returns current recipe rating submitted by current user
 */
export const selectRecipeUserRating = (state: RootState) =>
  state.recipe.recipeUserRating;
