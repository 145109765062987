import noop from 'lodash/noop';

import { cookidooIdPolyfill } from 'features/device/cookidoo/cookidooIdPolyfill';

export enum CookidooScaleDialogWeightUnit {
  G = 'g',
  Oz = 'oz',
}

export interface CookidooDialogResult {
  type?: string;
  message?: string;
  state?: string;
}

export interface CookidooScaleDialogFnArgs {
  description?: string;
  details?: string[];
  weight?: {
    value?: number;
    unit?: CookidooScaleDialogWeightUnit;
  };
}

export type CookidooScaleDialogFn = (
  args?: CookidooScaleDialogFnArgs,
  state?: string
) => Promise<CookidooDialogResult>;

export type CookidooTTSDialogTemperatureValue = number | 'varoma';

export enum CookidooTTSDialogTemperatureUnit {
  C = 'C',
  F = 'F',
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum CookidooTTSDialogSpeedValue {
  SpeedSoft = 'soft',
  Speed0 = '0',
  Speed0_5 = '0.5',
  Speed1 = '1',
  Speed1_5 = '1.5',
  Speed2 = '2',
  Speed2_5 = '2.5',
  Speed3 = '3',
  Speed3_5 = '3.5',
  Speed4 = '4',
  Speed4_5 = '4.5',
  Speed5 = '5',
  Speed5_5 = '5.5',
  Speed6 = '6',
  Speed6_5 = '6.5',
  Speed7 = '7',
  Speed7_5 = '7.5',
  Speed8 = '8',
  Speed8_5 = '8.5',
  Speed9 = '9',
  Speed9_5 = '9.5',
  Speed10 = '10',
}
/* eslint-enable @typescript-eslint/naming-convention */

export enum CookidooTTSDialogSpeedDirection {
  CW = 'CW',
  CCW = 'CCW',
}

export enum CookidooTTSDialogFocus {
  Time = 'time',
  Temperature = 'temperature',
  Speed = 'speed',
}

export interface CookidooTTSDialogFnArgs {
  caption?: string;
  prompt?: {
    before?: string;
    inProgress?: string;
  };
  time?: number;
  temperature?: {
    value?: CookidooTTSDialogTemperatureValue;
    unit?: CookidooTTSDialogTemperatureUnit;
  };
  speed?: {
    value?: CookidooTTSDialogSpeedValue;
    direction?: CookidooTTSDialogSpeedDirection;
  };
  focus?: CookidooTTSDialogFocus;
}

export type CookidooTTSDialogFn = (
  args?: CookidooTTSDialogFnArgs,
  state?: string
) => Promise<CookidooDialogResult>;

export type CookidooAudioAlertFn = (
  delaySeconds: number
) => Promise<() => void>;

export const getCookidooApiStub = (apiName: string) => () => {
  throw Error(
    `COOKIDOO "${apiName}" API is not defined, but tried to open dialog`
  );
};

export const cookidooApi = {
  openTTSDialog:
    window.COOKIDOO?.device?.ttsDialog ?? getCookidooApiStub('ttsDialog'),
  openScaleDialog:
    window.COOKIDOO?.device?.scaleDialog ?? getCookidooApiStub('scaleDialog'),
  /**
   * This API preloads Scale dialog UI in memory so next time it opens instantly
   * it takes about 4 seconds, and without it every scale opening takes 4 seconds
   */
  preloadScaleDialog: window.COOKIDOO?.device?.preloadScaleDialog ?? noop,
  audioAlert:
    window.COOKIDOO?.device?.audioAlert ?? getCookidooApiStub('audioAlert'),
  id: window.COOKIDOO?.device?.id
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => window.COOKIDOO!.device!.id
    : cookidooIdPolyfill,
};
