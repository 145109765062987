import type { Task } from 'redux-saga';
import { cancel } from 'redux-saga/effects';

/**
 * Helper that just wraps redux-saga `cancel` effect
 * Helps in testing `cancel` because `expectSaga` doesn't support it
 */
export function* cancelTask(task: Task) {
  yield cancel(task);
}
