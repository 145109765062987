import type { Theme } from '@mui/material';
import { Box, keyframes } from '@mui/material';
import type { SxProps } from '@mui/system';
import { memo } from 'react';
import type { FC } from 'react';

import { DropColor, DropTypography } from 'app/theme';
import { sxCompose } from 'utils/sxCompose';

export const readingValuePlaceholder = '----';

const keyframesFlashing = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0 },
  100% { opacity: 1 },
`;

export enum ReadingCurrentSize {
  Small = 'small',
  Large = 'large',
}

export enum ReadingCurrentState {
  Default = 'default',
  Running = 'running',
  Paused = 'paused',
  Pending = 'pending',
}

interface ReadingCurrentProps {
  value: string | undefined;
  size: ReadingCurrentSize;
  state: ReadingCurrentState;
}

export const ReadingCurrent: FC<ReadingCurrentProps> = memo(
  function ReadingCurrent({ value, size, state }) {
    return (
      <Box
        sx={sxCompose(getStateSx(state, value), {
          typography:
            size === ReadingCurrentSize.Small
              ? DropTypography.NumberS
              : DropTypography.NumberL,
        })}
      >
        {value ?? readingValuePlaceholder}
      </Box>
    );
  }
);

const getStateSx = (
  state: ReadingCurrentState,
  value: string | undefined
): SxProps<Theme> => {
  const redText: SxProps<Theme> = { color: DropColor.ErrorMain };
  const greenText: SxProps<Theme> = {
    color: DropColor.PrimaryMain,
  };
  const grayText: SxProps<Theme> = {
    color: DropColor.TextSecondary,
  };
  const flashingAnimation: SxProps<Theme> = {
    animation: `${keyframesFlashing} 1.5s linear infinite`,
  };

  if (!value) {
    return grayText;
  }

  switch (state) {
    case ReadingCurrentState.Running:
      return greenText;
    case ReadingCurrentState.Paused:
      return [greenText, flashingAnimation];
    case ReadingCurrentState.Pending:
      return [redText, flashingAnimation];
    default:
      return grayText;
  }
};
