import { mockMqttEventsSagasRestartable } from 'features/debug/mockMqttEventsSagas';
import { deviceEventsSagasRestartable } from 'features/device/deviceEventsSagas';
import { favoritesSagasRestartable } from 'features/favorites/favoritesSlice';
import { loginInitSagasRestartable } from 'features/login/loginInit';
import { loginSagasRestartable } from 'features/login/loginSlice';
import { pairingSagasRestartable } from 'features/pairing/pairingSlice';
import { recipeSagasRestartable } from 'features/recipe/recipeSlice/sagas';
import { userAppliancesSagasRestartable } from 'features/userAppliances/userAppliancesSagasRestartable';
import { userEventsSagasRestartable } from 'features/userEvents/userEventsSlice';

/**
 * Most of app sagas are using `takeEvery` so in case if they accidentally crash
 * it makes sense to report an error and restart them automatically so they could
 * continue handling other actions
 */
export const getRestartableSagas = (): ((...args: unknown[]) => unknown)[] => [
  ...recipeSagasRestartable,
  ...favoritesSagasRestartable,
  ...userAppliancesSagasRestartable,
  ...loginSagasRestartable,
  ...userEventsSagasRestartable,
  ...deviceEventsSagasRestartable,
  ...pairingSagasRestartable,
  ...mockMqttEventsSagasRestartable,
  // Order matters here, loginInitSagas should go after all logout handlers
  ...loginInitSagasRestartable,
];
