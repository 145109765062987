import type { SxProps, Theme } from '@mui/material';

import { DropBorderRadius, DropColor } from 'app/theme';
import {
  recipeCardHeight,
  recipeCardMediaHeight,
  recipeCardWidth,
} from 'features/favorites/constants';

export const sxWrapper: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: DropColor.SurfacePrimary,
  borderRadius: DropBorderRadius.Container,
  boxSizing: 'border-box',
  height: theme.spacing(recipeCardHeight),
  width: theme.spacing(recipeCardWidth),
  overflow: 'hidden',
});

export const sxMediaWrapper: SxProps<Theme> = (theme) => ({
  position: 'relative',
  flexShrink: 0,
  flexGrow: 0,
  height: theme.spacing(recipeCardMediaHeight),
  width: theme.spacing(recipeCardWidth),
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'none',
  },
});

export const sxContent: SxProps<Theme> = {
  flexGrow: 1,
  width: '100%',
  boxSizing: 'border-box',
  pt: 2,
  px: 3,
};
