import { StarIcon } from '@dropkitchen/icons-react';
import { Box } from '@mui/material';
import type { FC, RefCallback } from 'react';
import { useCallback, useRef, useState, memo } from 'react';
import { useSelector } from 'react-redux';

import { DropColor, DropTypography } from 'app/theme';
import { Button, ButtonColor } from 'components/Button/Button';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import {
  sxBackground,
  sxBackgroundWrapper,
  sxButton,
  sxContentFlexWrapper,
  sxContentWrapper,
  sxSectionWrapper,
  sxStickyWrapper,
  sxText,
  sxTitle,
} from 'features/recipe/view/Summary.styles';
import { DurationFormat, formatDuration } from 'utils/formatDuration';

export const startCookingButtonText = 'Start Cooking';

export const testIdBackground = 'background';

export const Summary: FC = memo(function Summary() {
  const observer = useRef<IntersectionObserver>();
  const [isStuck, setIsStuck] = useState(false);
  const recipe = useSelector(selectRecipe);

  // Detect if sticky element is stuck, see comment above at stickyWrapper class
  const setRef: RefCallback<HTMLDivElement> = useCallback((ref) => {
    if (observer.current) {
      observer.current.disconnect();
      observer.current = undefined;
    }
    if (!ref) {
      return;
    }

    const visibilityThreshold = 1;

    observer.current = new IntersectionObserver(
      ([e]) => {
        setIsStuck(e.intersectionRatio < 1);
      },
      {
        threshold: visibilityThreshold,
      }
    );
    observer.current.observe(ref);
  }, []);

  if (!recipe) {
    return null;
  }

  return (
    <Box ref={setRef} sx={sxStickyWrapper}>
      <Box sx={sxBackgroundWrapper}>
        <Box sx={sxBackground(isStuck)} data-testid={testIdBackground} />
      </Box>
      <Box sx={sxContentFlexWrapper}>
        <Box sx={sxContentWrapper}>
          <Box sx={sxSectionWrapper}>
            <Box sx={sxTitle}>Rating</Box>
            <Box sx={sxText}>
              <StarIcon
                sx={{
                  color: DropColor.PrimaryMain,
                  width: 20,
                  height: 20,
                  marginRight: 1,
                }}
              />
              {recipe.rating.toFixed(1)}
              <Box
                component="span"
                sx={{
                  typography: DropTypography.Body1,
                  marginLeft: 1,
                }}
              >
                ({recipe.ratingsCount})
              </Box>
            </Box>
          </Box>
          <Box sx={sxSectionWrapper}>
            <Box sx={sxTitle}>Difficulty</Box>
            <Box sx={sxText}>{recipe.difficultyText}</Box>
          </Box>
          <Box sx={sxSectionWrapper}>
            <Box sx={sxTitle}>Time</Box>
            <Box sx={sxText}>
              {formatDuration(recipe.time, DurationFormat.HrMins)}
            </Box>
          </Box>
          <Button
            sx={sxButton}
            color={ButtonColor.Primary}
            to={`/recipes/${recipe.id}/make`}
          >
            {startCookingButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
