import { Box, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DropColor, DropTypography } from 'app/theme';
import { Image } from 'components/Image/Image';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import type { DropMedia } from 'types/api/media';

export const toolsTitle = 'Tools';

interface Tool {
  name: string;
  image: DropMedia | null;
  info?: string;
}

export const Tools: FC = memo(function Tools() {
  const theme = useTheme();

  const recipe = useSelector(selectRecipe);

  const tools = useMemo<Tool[]>(
    () => [
      ...((recipe?.appliances.map(({ name, image }) => ({
        name,
        image,
      })) || []) as Tool[]),
      ...((recipe?.containers
        .filter(({ container: { needed } }) => needed)
        .map(({ container }) => {
          const [name, info] = container.name.split(/-(.+)/);
          return {
            name: name.trim(),
            image: container.image,
            info: info?.trim(),
          };
        }) || []) as Tool[]),
    ],
    [recipe]
  );

  if (!tools.length) {
    return null;
  }

  return (
    <>
      <Typography
        component="h2"
        sx={{
          typography: DropTypography.H5Bold,
          mt: 12,
          mb: 2,
        }}
      >
        {toolsTitle}
      </Typography>
      {tools.map((tool, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 6,
          }}
        >
          <Box sx={{ marginRight: 4 }}>
            {tool?.image && (
              <Image
                imageData={tool.image.data}
                width={64}
                color={theme.palette.secondary.dark}
                alt={`${tool.name} icon`}
              />
            )}
          </Box>
          <div>
            <Box
              sx={{
                typography: DropTypography.Subtitle1Bold,
              }}
            >
              {tool.name}
            </Box>
            <Box
              sx={{
                typography: DropTypography.Body2,
                color: DropColor.TextSecondary,
              }}
            >
              {tool.info}
            </Box>
          </div>
        </Box>
      ))}
    </>
  );
});
