import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import type { History } from 'history';
import produce from 'immer';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import type { DropRecipeClient, DropRecipeLean } from 'types/api/recipe';

import type { RootState } from './rootReducer';
import { createRootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';

interface CreateStoreArgs {
  history: History;
  preloadedState?: RootState;
  /**
   * For testing purposes
   */
  disableSagaMiddleware?: boolean;
}

export const createStore = ({
  history,
  preloadedState,
  disableSagaMiddleware,
}: CreateStoreArgs) => {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history,
    });

  const rootReducer = createRootReducer(routerReducer);
  const sagaMiddleware = createSagaMiddleware({
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error('Unhandled redux-saga error:', e);
      Sentry.captureException(e);
    },
  });

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state: RootState) => {
      return produce(state, (draft) => {
        // Removing huge stuff
        draft.favorites.entities = keyBy(
          Object.values(draft.favorites.entities).map(
            (recipe) => pick(recipe, 'id', 'name') as DropRecipeLean
          )
        );
        draft.recipe.recipe = pick(
          draft.recipe.recipe,
          'id',
          'name'
        ) as DropRecipeClient;
        // Removing sensitive stuff
        if (draft.login.authData) {
          draft.login.authData.token = '';
          draft.login.authData.refreshToken = '';
        }
        draft.deviceEvents.secret = '';
      });
    },
  });

  const store = configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
        immutableCheck: false,
      }).concat([routerMiddleware, sagaMiddleware]),
    enhancers: (defaultEnhancers) => [sentryReduxEnhancer, ...defaultEnhancers],
  });

  if (!disableSagaMiddleware) {
    sagaMiddleware.run(rootSaga);
  }

  const reduxHistory = createReduxHistory(store);

  return {
    store,
    reduxHistory,
  };
};

export const { store: appStore, reduxHistory: appHistory } = createStore({
  history: createBrowserHistory(),
});

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer =
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      require('./rootReducer').createRootReducer(appHistory);
    appStore.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof appStore.dispatch;
