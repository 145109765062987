import { WeighIcon } from '@dropkitchen/icons-react';
import type { FC, SyntheticEvent } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonColor } from 'components/Button/Button';
import type { IconButtonProps } from 'components/IconButton/IconButton';
import { IconButton } from 'components/IconButton/IconButton';
import { PendingType, selectPending } from 'features/pending/pendingSlice';
import { userAppliancesExecuteWeightRequested } from 'features/userAppliances/requestUserAppliancesExecuteWeight';
import type { DropRecipeIngredient } from 'types/api/ingredient';
import { stopEventPropagation } from 'utils/stopEventPropagation';

export interface WeighButtonProps extends Omit<IconButtonProps, 'color'> {
  ingredient: DropRecipeIngredient;
}

export const WeighButton: FC<WeighButtonProps> = memo(function WeighButton({
  ingredient,
  ...iconButtonProps
}) {
  const dispatch = useDispatch();
  const isPending = useSelector(
    selectPending({
      pendingType: PendingType.Weight,
      pendingId: ingredient.id,
    })
  );

  const handleClick = (e: SyntheticEvent) => {
    stopEventPropagation(e);

    dispatch(
      userAppliancesExecuteWeightRequested({
        ingredient,
      })
    );
  };

  return (
    <IconButton
      color={ButtonColor.Secondary}
      onClick={handleClick}
      onMouseDown={stopEventPropagation}
      onTouchStart={stopEventPropagation}
      pending={isPending}
      {...iconButtonProps}
    >
      <WeighIcon />
    </IconButton>
  );
});
