/**
 * Returns id from platform entity uri
 */
export function getIdFromUri<TUri extends string | undefined>(
  uri: TUri
): TUri extends string ? string : undefined {
  if (typeof uri === 'undefined') {
    // Have to explicitly cast to return type, that is the only options
    // https://github.com/microsoft/TypeScript/issues/22735#issuecomment-374817151
    return undefined as TUri extends string ? string : undefined;
  }

  const uriParts = uri.split('/');

  if (uriParts.length <= 1) {
    return undefined as TUri extends string ? string : undefined;
  }

  const lastPart = uriParts.pop();
  return lastPart?.replace(/\?.*$/, '') as TUri extends string
    ? string
    : undefined;
}
