import type { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiRequestFnResponseType } from 'api/types';
import {
  apiGetUserAppliances,
  apiGetUserAppliancesForRecipe,
  apiPostUserAppliancesExecute,
} from 'api/userAppliances';
import { loginSuccess, logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  selectRecipeFetched,
} from 'features/recipe/recipeSlice/slice';
import {
  selectUserAppliancesFetched,
  selectUserAppliancesUsedInRecipe,
  selectUserApplianceTM6,
} from 'features/userAppliances/selectors';
import {
  userAppliancesFetchFailed,
  userAppliancesFetchFinished,
  userAppliancesFetchStarted,
} from 'features/userAppliances/userAppliancesSlice';
import type { DropUserAppliance } from 'types/api/user';

export const userApplianceTM6NotPairedErrorText =
  'There is no paired TM6 on this user account';

export const apiGetUserAppliancesForRecipeSaga = createRequestApiSaga(
  apiGetUserAppliancesForRecipe,
  'User appliances supported by recipe loading'
);

export const apiGetUserAppliancesSaga = createRequestApiSaga(
  apiGetUserAppliances,
  'User appliances loading'
);

export function* requestUserAppliances(): SagaIterator<void> {
  const isFetched: ReturnType<typeof selectUserAppliancesFetched> =
    yield select(selectUserAppliancesFetched);

  if (isFetched) {
    return;
  }

  yield put(userAppliancesFetchStarted());

  let page = 0;
  let hasMore = true;
  const fetchedUserAppliances: DropUserAppliance[] = [];

  while (hasMore) {
    page++;
    const response: ApiRequestFnResponseType<typeof apiGetUserAppliances> =
      yield call(apiGetUserAppliancesSaga, { page });

    if (!response.ok) {
      yield put(userAppliancesFetchFailed(response.details.message));
      return;
    }

    fetchedUserAppliances.push(...response.data.results);
    hasMore = page < response.data.meta.total / response.data.meta.perPage;
  }

  yield put(userAppliancesFetchFinished(fetchedUserAppliances));
}

export function* requestUserAppliancesWatcher() {
  yield takeLatest(loginSuccess, requestUserAppliances);
}

export const apiPostUserAppliancesExecuteSaga = createRequestApiSaga(
  apiPostUserAppliancesExecute,
  'Sending appliance command'
);

/**
 * Returns TM6 user appliance from state or waits util it's fetched
 */
export function* selectOrWaitUserApplianceTM6(): SagaIterator<DropUserAppliance> {
  const userAppliancesFetched: ReturnType<typeof selectUserAppliancesFetched> =
    yield select(selectUserAppliancesFetched);
  if (!userAppliancesFetched) {
    yield take(userAppliancesFetchFinished);
  }

  const userAppliance: ReturnType<typeof selectUserApplianceTM6> = yield select(
    selectUserApplianceTM6
  );

  // Should not ever happen, just in case
  if (!userAppliance) {
    yield put(logoutSuccess());
    throw new Error(userApplianceTM6NotPairedErrorText);
  }

  return userAppliance;
}

/**
 * Returns all user appliances that are used in a recipe from state or waits util it's fetched
 */
export function* selectOrWaitUserAppliancesUsedInRecipe(): SagaIterator<
  DropUserAppliance[]
> {
  const userAppliancesFetched: ReturnType<typeof selectUserAppliancesFetched> =
    yield select(selectUserAppliancesFetched);
  if (!userAppliancesFetched) {
    yield take(userAppliancesFetchFinished);
  }

  const recipeFetched: ReturnType<typeof selectRecipeFetched> = yield select(
    selectRecipeFetched
  );
  if (!recipeFetched) {
    yield take(recipeFetchFinished);
  }

  const usedAppliances: ReturnType<typeof selectUserAppliancesUsedInRecipe> =
    yield select(selectUserAppliancesUsedInRecipe);

  return usedAppliances;
}
