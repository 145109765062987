import { Box } from '@mui/material';
import type { FC } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';

import { DropColor, DropTypography } from 'app/theme';
import { DropAppIcon } from 'components/DropAppIcon/DropAppIcon';
import {
  sxAppDescriptionWrapper,
  sxAppDescriptionHeader,
  sxAppDescriptionIcon,
  sxAppDescriptionSecondaryText,
  sxBackground,
  sxContentWrapper,
  sxQrCode,
  sxText,
  sxTextSection,
  sxWrapper,
} from 'features/pairing/PairingPage.styles';
import { PairingPageSkeleton } from 'features/pairing/PairingPageSkeleton';
import { selectPairingMobileUrl } from 'features/pairing/pairingSlice';

export const pairingScreenText =
  'Download the free mobile app to discover new recipes for your TM6 and more!';

export const qrCodeAriaLabel = 'QR Code for easy pairing';

export const PairingPage: FC = () => {
  const mobileUrl = useSelector(selectPairingMobileUrl());

  if (!mobileUrl) {
    return <PairingPageSkeleton />;
  }

  return (
    <Box sx={sxWrapper}>
      <Box sx={sxBackground} />
      <Box sx={sxContentWrapper}>
        <div>
          <Box sx={sxQrCode} aria-label={qrCodeAriaLabel}>
            <QRCode size={180} value={mobileUrl} />
          </Box>
          <Box
            sx={{
              typography: DropTypography.Body1,
              color: DropColor.TextWhiteMedium,
              textAlign: 'center',
              marginTop: 2,
            }}
          >
            {
              // Code from url for manual input in mobile Drop client
              mobileUrl.split('/').pop()
            }
          </Box>
        </div>
        <Box sx={sxTextSection}>
          <Box sx={sxAppDescriptionWrapper}>
            <DropAppIcon sx={sxAppDescriptionIcon} />
            <div>
              <Box sx={sxAppDescriptionHeader}>Drop Recipes</Box>
              <Box sx={sxAppDescriptionSecondaryText}>
                Guided Cooking + Quick, Easy Meals
              </Box>
            </div>
          </Box>
          <Box sx={sxText}>{pairingScreenText}</Box>
        </Box>
      </Box>
    </Box>
  );
};
