import { select, call, put } from 'redux-saga/effects';

import type { ApiContext, ApiRequestFn } from 'api/types';
import { requestDebugMessageAdd } from 'features/debug/debugSlice';
import {
  logoutSuccess,
  requestTokenRefresh,
  selectLoginAuthData,
} from 'features/login/loginSlice';
import type { PromiseReturnType } from 'types/promiseReturnType';

/**
 * Creates saga that queries for auth data from store, refreshes token if needed,
 * performs request, handles errors and returns response
 */
export function createRequestApiSaga<TRequestFnArgs, TResponse>(
  /**
   * Factory function that accepts request arguments and returns request function of type `ApiRequestFn`
   */
  apiRequestFnFactory:
    | ((args: TRequestFnArgs) => ApiRequestFn<TResponse>)
    | (() => ApiRequestFn<TResponse>),
  /**
   * Request description to show error message to user like "{requestDescription} error: ..."
   */
  requestDescription: string
) {
  type ApiRequestFnType = ReturnType<typeof apiRequestFnFactory>;
  type ApiResponseType = PromiseReturnType<ApiRequestFnType>;

  function* requestApiSaga(args?: TRequestFnArgs) {
    yield call(requestTokenRefresh);

    const authData: ReturnType<typeof selectLoginAuthData> = yield select(
      selectLoginAuthData
    );
    const apiContext: ApiContext = {
      authData,
    };
    yield call(
      requestDebugMessageAdd,
      `${requestDescription}: ${JSON.stringify(args)}`
    );
    const apiRequestFn: ApiRequestFnType =
      args === undefined
        ? yield call(apiRequestFnFactory)
        : yield call(apiRequestFnFactory, args);
    const response: ApiResponseType = yield call(apiRequestFn, apiContext);
    if (!response.ok) {
      // Token looks not valid, let's forget it
      if (response.httpStatus === 401) {
        yield put(logoutSuccess());
      }
      // TODO: Show error to user?
      yield call(
        // eslint-disable-next-line no-console
        console.error,
        `${requestDescription} error: ${response.details.message}`
      );
    }
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  return requestApiSaga as TRequestFnArgs extends {}
    ? (args: TRequestFnArgs) => ReturnType<typeof requestApiSaga>
    : () => ReturnType<typeof requestApiSaga>;
}
