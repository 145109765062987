import { Box } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

import { DropColor } from 'app/theme';

interface ReadingProps {
  iconElement: ReactElement;
  readingCurrentElement: ReactElement;
  readingTargetElement: ReactElement;
}

export const Reading: FC<ReadingProps> = memo(function Reading({
  iconElement,
  readingCurrentElement,
  readingTargetElement,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          '& svg': {
            color: DropColor.TextSecondary,
            flexShrink: 0,
            height: 50,
            width: 50,
            marginTop: 3,
            marginRight: 4,
          },
        }}
      >
        {iconElement}
      </Box>
      <div>
        {readingCurrentElement}
        {readingTargetElement}
      </div>
    </Box>
  );
});
