import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropBorderRadius } from 'app/theme';
import { Image } from 'components/Image/Image';
import type { DropStepMedia } from 'types/api/step';
import { sxCompose } from 'utils/sxCompose';

export const ariaLabelStepMediaImage = 'Recipe step media image';

export interface MediaProps {
  sx?: SxProps<Theme>;
  media: DropStepMedia;
}

export const Media: FC<MediaProps> = memo(function Media({
  media: { image, video },
  sx,
}) {
  // Video support was removed in https://github.com/dropkitchen/drop-thermomix/pull/58
  // Because TM6 doesn't support <video />
  // Revert this PR to get support back if ever needed

  const imageData = image?.data || video?.image.data;
  if (!imageData) {
    return null;
  }

  return (
    <Box
      sx={sxCompose(
        {
          // Classic hack, forces 16:9 ratio because % padding is calculated based on parent width
          paddingTop: '56.25%',
          height: 0,
          overflow: 'hidden',
          position: 'relative',
          borderRadius: DropBorderRadius.Container,
        },
        sx
      )}
    >
      <Image
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        imageData={imageData}
        alt={ariaLabelStepMediaImage}
      />
    </Box>
  );
});
