import { stepProgramCancelRequestedWatcher } from 'features/userAppliances/requestStepProgramCancel';
import { stepProgramDoneRequestedWatcher } from 'features/userAppliances/requestStepProgramDone';
import { stepProgramExtendRequestedWatcher } from 'features/userAppliances/requestStepProgramExtend';
import { stepProgramPauseRequestedWatcher } from 'features/userAppliances/requestStepProgramPause';
import { stepProgramResumeRequestedWatcher } from 'features/userAppliances/requestStepProgramResume';
import { stepProgramStartRequestedWatcher } from 'features/userAppliances/requestStepProgramStart';
import { requestUserAppliancesExecuteWeightWatcher } from 'features/userAppliances/requestUserAppliancesExecuteWeight';
import { requestUserAppliancesWatcher } from 'features/userAppliances/userAppliancesSagas';

export const userAppliancesSagasRestartable = [
  stepProgramStartRequestedWatcher,
  stepProgramPauseRequestedWatcher,
  stepProgramCancelRequestedWatcher,
  stepProgramDoneRequestedWatcher,
  stepProgramExtendRequestedWatcher,
  stepProgramResumeRequestedWatcher,
  requestUserAppliancesWatcher,
  requestUserAppliancesExecuteWeightWatcher,
];
