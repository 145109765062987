import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { DropBorderRadius, DropColor } from 'app/theme';
import { RunningStep } from 'features/recipe/make/applianceScreen/RunningStep';
import { TemperatureReading } from 'features/recipe/make/applianceScreen/TemperatureReading';
import { TimeExtendControls } from 'features/recipe/make/applianceScreen/TimeExtendControls';
import { TimeReading } from 'features/recipe/make/applianceScreen/TimeReading';
import { selectApplianceScreenAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';

export const ApplianceScreen: FC = memo(function ApplianceScreen() {
  const applianceScreenAppliance = useSelector(
    selectApplianceScreenAppliance()
  );

  // Should never happen
  if (!applianceScreenAppliance) {
    return null;
  }

  const showTemperature =
    applianceScreenAppliance.appliance.category === DropApplianceCategory.Oven;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        padding: 'calc(1/12 * 100%)',
      }}
    >
      <Box
        sx={{
          backgroundColor: DropColor.BackgroundPaper,
          borderRadius: DropBorderRadius.Container,
          width: 4 / 10,
        }}
      >
        <Box
          sx={{
            padding: 4,
          }}
        >
          <TimeReading applianceId={applianceScreenAppliance.id} />
          {showTemperature && (
            <TemperatureReading applianceId={applianceScreenAppliance.id} />
          )}
        </Box>
        <TimeExtendControls
          sx={{
            padding: 4,
            borderTop: 1,
            borderColor: DropColor.SurfaceTertiary,
          }}
          applianceId={applianceScreenAppliance.id}
        />
      </Box>
      <RunningStep
        sx={{
          marginLeft: 'calc(1/10 * 100%)',
          width: 5 / 10,
        }}
        applianceId={applianceScreenAppliance.id}
      />
    </Box>
  );
});
