import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, take, put, select, takeLatest } from 'redux-saga/effects';

import { apiPostAuth, ApiAuthGrantType } from 'api/auth';
import type { RootState } from 'app/store/rootReducer';
import type { AppAuthData } from 'types/auth';
import type { PromiseReturnType } from 'types/promiseReturnType';
import { cookieStorageAuth } from 'utils/cookieStorage';
import { getErrorString } from 'utils/getErrorString';
import { getTime } from 'utils/getTime';

export const tokenRefreshPeriod = 60 * 60 * 1000;

interface LoginState {
  authData: AppAuthData | null;
  apiError?: string;
  apiPending: boolean;
}

export const initialState: LoginState = {
  apiPending: false,
  authData: null,
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    loginSuccess(state, { payload }: PayloadAction<AppAuthData>) {
      state.authData = { ...payload };
      state.apiPending = false;
      state.apiError = undefined;
    },
    loginApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    loginApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
    logoutSuccess() {
      return initialState;
    },
  },
});

export const {
  reducer: loginReducer,
  actions: { loginSuccess, loginApiPending, loginApiError, logoutSuccess },
} = loginSlice;

const selectLoginState = (state: RootState): LoginState => state.login;

export const selectLoginApiPending = (state: RootState): boolean =>
  selectLoginState(state).apiPending;

export const selectLoginApiError = (state: RootState): string | undefined =>
  selectLoginState(state).apiError;

export const selectLoginAuthData = (state: RootState): AppAuthData | null =>
  selectLoginState(state).authData;

export const selectLoginIsAuthenticated = (state: RootState): boolean =>
  !!selectLoginAuthData(state);

export function* requestTokenRefresh(): SagaIterator<void> {
  const isPending: ReturnType<typeof selectLoginApiPending> = yield select(
    selectLoginApiPending
  );
  const isAuthenticated: ReturnType<typeof selectLoginIsAuthenticated> =
    yield select(selectLoginIsAuthenticated);
  if (isPending || !isAuthenticated) {
    yield take(loginSuccess);
    return;
  }

  const authData: AppAuthData = yield select(selectLoginAuthData);
  const time: ReturnType<typeof getTime> = yield call(getTime);
  if (
    // Refreshing token only if it expires in an hour
    authData.expiresAt >
    time + tokenRefreshPeriod
  ) {
    return;
  }

  yield put(loginApiPending());

  try {
    const loginResponse: PromiseReturnType<typeof apiPostAuth> = yield call(
      apiPostAuth,
      {
        grantType: ApiAuthGrantType.RefreshToken,
        refreshToken: authData.refreshToken,
      }
    );
    if (!loginResponse.ok) {
      yield put(loginApiError(loginResponse.details.message));
      // If HTTP code is 4xx - it is probably something wrong with token, let's log out
      if (loginResponse.httpStatus?.toString()[0] === '4') {
        yield put(logoutSuccess());

        // And let's wait until login now before making a request
        yield take(loginSuccess);
      }
      return;
    }

    const data: AppAuthData = {
      expiresAt: time + loginResponse.data.expiresIn * 1000,
      refreshToken: loginResponse.data.refreshToken,
      token: loginResponse.data.accessToken,
      userId: loginResponse.data.user.id.toString(),
      userEmail: loginResponse.data.user.email,
    };

    yield put(loginSuccess(data));
  } catch (e) {
    yield put(loginApiError(getErrorString(e)));
  }
}

function* saveTokenToCookies({
  payload: authData,
}: ReturnType<typeof loginSuccess>): SagaIterator<void> {
  yield call(cookieStorageAuth.setValue, authData);
}

export function* saveTokenToCookiesWatcher(): SagaIterator<void> {
  yield takeLatest(loginSuccess, saveTokenToCookies);
}

function* clearTokenFromCookies(): SagaIterator<void> {
  yield call(cookieStorageAuth.removeValue);
}

export function* clearTokenFromCookiesWatcher(): SagaIterator<void> {
  yield takeLatest(logoutSuccess, clearTokenFromCookies);
}

export function* loadTokenFromCookies(): SagaIterator<boolean> {
  const authData: AppAuthData | undefined = yield call(
    cookieStorageAuth.getValue
  );
  if (authData) {
    yield put(loginSuccess(authData));
    return true;
  }
  return false;
}

export const loginSagasRestartable = [
  clearTokenFromCookiesWatcher,
  saveTokenToCookiesWatcher,
];
