import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { useCallback, memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

import type { RootState } from 'app/store/rootReducer';
import { DropBorderRadius, DropColor } from 'app/theme';
import { Media } from 'features/recipe/make/step/Media';
import { Sentence } from 'features/recipe/make/step/Sentence';
import { SmartApplianceSetup } from 'features/recipe/make/step/SmartApplianceSetup';
import { SmartIngredientList } from 'features/recipe/make/step/SmartIngredientList';
import { StepNumber } from 'features/recipe/make/step/StepNumber';
import { SmartApplianceControl } from 'features/recipe/make/step/applianceControls/SmartApplianceControl';
import {
  CurrentStepVisibility,
  recipeCurrentStepIndexSet,
  recipeCurrentStepVisibilitySet,
  recipeScrollToCurrentStepFinished,
  selectCurrentStepIndex,
  selectScrollToCurrentStepPending,
} from 'features/recipe/recipeSlice/slice';
import type { DropRecipeClient } from 'types/api/recipe';
import { sxCompose } from 'utils/sxCompose';

export const scrollSnapPointTestId = 'scroll-snap-point';

const sxStepSection: SxProps<Theme> = {
  marginTop: 4,
};

export interface StepProps {
  sx?: SxProps<Theme>;
  stepIndex: number;
  recipe: DropRecipeClient;
}

export const Step: FC<StepProps> = memo(function Step({
  sx,
  stepIndex,
  recipe,
}) {
  const dispatch = useDispatch();

  const isActive = useSelector((state: RootState) => {
    const currentStepIndex = selectCurrentStepIndex(state);
    return stepIndex === currentStepIndex;
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollPointRef = useRef<HTMLDivElement>(null);

  useScrollToActiveStep({
    elementRef: scrollPointRef,
    isActiveStep: isActive,
  });

  useStepVisibilityObserver({
    elementRef: wrapperRef,
    isActiveStep: isActive,
  });

  const handleStepClick = () => {
    if (!isActive) {
      dispatch(recipeCurrentStepIndexSet(stepIndex));
    }
  };

  const totalSteps = recipe.steps.length;
  const step = recipe.steps[stepIndex];

  return (
    <>
      <Box
        sx={sxCompose(
          {
            backgroundColor: DropColor.SurfacePrimary,
            position: 'relative',
            width: '100%',
            padding: 4,
            borderRadius: DropBorderRadius.Container,
            opacity: 0.5,
            transition: 'opacity 0.5s, transform 0.5s',
          },
          isActive && {
            opacity: 1,
            transform: 'scale(1.05)',
          },
          sx
        )}
        ref={wrapperRef}
        role="button"
        tabIndex={0}
        onClick={handleStepClick}
      >
        {/* 
          // HACK: WPE doesn't support scroll snap margins so using this workaround
          to scroll to the top of the step but with some additional margin
          to show bottom part of previous step
        */}
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing(-12),
          })}
          ref={scrollPointRef}
          data-testid={scrollSnapPointTestId}
        />
        <StepNumber
          isActive={isActive}
          stepIndex={stepIndex}
          totalSteps={totalSteps}
        />
        <Sentence sentence={step.sentence} />
        <Media sx={sxStepSection} media={step.media} />
        <SmartIngredientList sx={sxStepSection} recipe={recipe} step={step} />
        <SmartApplianceSetup sx={sxStepSection} step={step} />
        <SmartApplianceControl sx={sxStepSection} step={step} />
        {/* // TODO: Tips */}
      </Box>
    </>
  );
});

// These hooks are not reusable, just extracted for better readability

interface UseScrollToActiveStepArgs {
  elementRef: React.RefObject<HTMLDivElement>;
  isActiveStep: boolean;
}

function useScrollToActiveStep({
  elementRef,
  isActiveStep,
}: UseScrollToActiveStepArgs) {
  const dispatch = useDispatch();

  const scrollToThisStep = useCallback(() => {
    /* istanbul ignore next */
    if (!elementRef.current) {
      return;
    }

    // Webkit doesn't support native smooth scrolling, so using lib
    elementScrollIntoView(elementRef.current, {
      behavior: 'smooth',
    });
  }, [elementRef]);

  const scrollToThisStepPending = useSelector(
    (state: RootState) =>
      isActiveStep && selectScrollToCurrentStepPending(state)
  );

  useEffect(() => {
    if (scrollToThisStepPending) {
      scrollToThisStep();
      dispatch(recipeScrollToCurrentStepFinished());
    }
  }, [scrollToThisStepPending, dispatch, scrollToThisStep]);
}

interface UseStepVisibilityObserverArgs {
  elementRef: React.RefObject<HTMLDivElement>;
  isActiveStep: boolean;
}

function useStepVisibilityObserver({
  elementRef,
  isActiveStep,
}: UseStepVisibilityObserverArgs) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!elementRef.current || !isActiveStep) {
      return;
    }

    const visibilityThreshold = 0.01;
    const observer = new IntersectionObserver(
      (entries) => {
        const { boundingClientRect, rootBounds, isIntersecting } = entries[0];

        const getVisibility = () => {
          if (isIntersecting || !rootBounds) {
            return CurrentStepVisibility.Visible;
          }

          return rootBounds.top > boundingClientRect.top
            ? CurrentStepVisibility.Above
            : CurrentStepVisibility.Below;
        };

        dispatch(recipeCurrentStepVisibilitySet(getVisibility()));
      },
      {
        threshold: visibilityThreshold,
      }
    );

    observer.observe(elementRef.current);
    return () => {
      observer.disconnect();
    };
  }, [dispatch, elementRef, isActiveStep]);
}
