import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import { ApiAuthGrantType, apiPostAuth } from 'api/auth';
import { apiPostDevicesActivation } from 'api/devices';
import type {
  ConnectionInfoFactory,
  OnConnectHandler,
  OnDisconnectHandler,
  OnErrorHandler,
  OnMessageHandler,
  OpenMqttConnection,
} from 'api/mqtt';
import { openMqttConnection } from 'api/mqtt';
import type { RootState } from 'app/store/rootReducer';
import { appConfig } from 'features/config/config';
import { requestDebugMessageAdd } from 'features/debug/debugSlice';
import { cookidooApi } from 'features/device/cookidoo/api';
import { deviceEventsSecretReceived } from 'features/device/deviceEventsSlice';
import { loginSuccess, logoutSuccess } from 'features/login/loginSlice';
import type { PairingMqttEvents } from 'features/pairing/PairingMqttEvents';
import { PairingMqttEventType } from 'features/pairing/PairingMqttEvents';
import { snackbarEnqueued } from 'features/snackbar/snackbarSlice';
import { applianceIdTm6 } from 'features/userAppliances/constants';
import type { AppAuthData } from 'types/auth';
import type { PromiseReturnType } from 'types/promiseReturnType';
import { getTime } from 'utils/getTime';
import { signHmac } from 'utils/signHmac';

export const openPairingEventsMqttConnection =
  openMqttConnection as OpenMqttConnection<PairingMqttEvents>;

export const loginPairingErrorText =
  'Device pairing error, please try again later';

interface PairingState {
  /**
   * This url is shown during pairing in form of QR code for scanning in mobile client
   */
  mobileUrl?: string;
  connected: boolean;
  connectionError?: string;
}

export const initialState: PairingState = { connected: false };

const pairingSlice = createSlice({
  name: 'pairingSlice',
  initialState,
  reducers: {
    pairingMobileUrlReceived(state, { payload }: PayloadAction<string>) {
      state.mobileUrl = payload;
    },
    pairingConnected(state) {
      state.connected = true;
    },
    pairingDisconnected(state) {
      state.connected = false;
    },
    pairingConnectionError(state, { payload }: PayloadAction<string>) {
      state.connectionError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
    builder.addCase(loginSuccess, () => initialState);
  },
});

export const {
  reducer: pairingReducer,
  actions: {
    pairingMobileUrlReceived,
    pairingConnected,
    pairingConnectionError,
    pairingDisconnected,
  },
} = pairingSlice;

export const selectPairingMobileUrl =
  () =>
  (state: RootState): string | undefined =>
    state.pairing.mobileUrl;

export const selectPairingConnected =
  () =>
  (state: RootState): boolean =>
    state.pairing.connected;

export const selectPairingConnectionError =
  () =>
  (state: RootState): string | undefined =>
    state.pairing.connectionError;

export const getPairingConnectionInfo: ConnectionInfoFactory =
  function* getPairingConnectionInfo(): ReturnType<ConnectionInfoFactory> {
    const signatureSecret: ReturnType<
      typeof appConfig.getNativeIdSignatureSecret
    > = yield call(appConfig.getNativeIdSignatureSecret);

    const applianceNativeId: PromiseReturnType<typeof cookidooApi.id> =
      yield call(cookidooApi.id);

    const applianceNativeIdSignature: PromiseReturnType<typeof signHmac> =
      yield call(signHmac, {
        data: applianceNativeId,
        secret: signatureSecret,
      });

    const deviceActivationResponse: PromiseReturnType<
      typeof apiPostDevicesActivation
    > = yield call(apiPostDevicesActivation, {
      applianceId: applianceIdTm6,
      nativeId: applianceNativeId,
      sig: applianceNativeIdSignature,
    });

    if (!deviceActivationResponse.ok) {
      yield put(
        pairingConnectionError(deviceActivationResponse.details.message)
      );
      return;
    }

    const { data, mobileUrl } = deviceActivationResponse.data;
    yield put(pairingMobileUrlReceived(mobileUrl));

    return {
      ...data,
      subscriptionTopics: data.events.map(({ topic }) => topic),
    };
  };

export const handlePairingOnConnect: OnConnectHandler =
  function* handlePairingOnConnect(): ReturnType<OnConnectHandler> {
    yield call(requestDebugMessageAdd, `[P-CONNECTED]`);
    yield put(pairingConnected());
  };

export const handlePairingOnDisconnect: OnDisconnectHandler =
  function* handlePairingOnDisconnect(): ReturnType<OnDisconnectHandler> {
    yield call(requestDebugMessageAdd, `[P-DISCONNECTED]`);
    yield put(pairingDisconnected());
  };

export const handlePairingOnError: OnErrorHandler =
  function* handlePairingOnError({ error }): ReturnType<OnErrorHandler> {
    yield call(requestDebugMessageAdd, `[P-ERROR] ${error}`);
    yield put(pairingConnectionError(error.message));
  };

export const handlePairingOnMessage: OnMessageHandler<PairingMqttEvents> =
  function* handlePairingOnMessage({
    payload,
    topic,
  }): ReturnType<OnMessageHandler<PairingMqttEvents>> {
    yield call(
      requestDebugMessageAdd,
      `[P-IN] ${topic} ${JSON.stringify(payload)}`
    );
    // We are waiting for paired event only
    if (payload.type !== PairingMqttEventType.AppliancePaired) {
      return;
    }

    const loginResponse: PromiseReturnType<typeof apiPostAuth> = yield call(
      apiPostAuth,
      {
        grantType: ApiAuthGrantType.AuthorizationCode,
        code: payload.value.userAuthCode,
      }
    );
    if (!loginResponse.ok) {
      yield put(pairingConnectionError(loginResponse.details.message));
      return;
    }

    const time: number = yield call(getTime);
    const authData: AppAuthData = {
      expiresAt: time + loginResponse.data.expiresIn * 1000,
      refreshToken: loginResponse.data.refreshToken,
      token: loginResponse.data.accessToken,
      userId: loginResponse.data.user.id.toString(),
      userEmail: loginResponse.data.user.email,
    };
    yield put(deviceEventsSecretReceived(payload.value.secret));
    yield put(loginSuccess(authData));
  };

export function* connectToPairingEvents(): SagaIterator<void> {
  // Connection is needed to receive just single event, using `race` to kill connection after login
  yield race([
    call(openPairingEventsMqttConnection, {
      getConnectionInfo: getPairingConnectionInfo,
      onConnect: handlePairingOnConnect,
      onDisconnect: handlePairingOnDisconnect,
      onError: handlePairingOnError,
      onMessage: handlePairingOnMessage,
    }),
    take(loginSuccess),
  ]);
}

/**
 * Mock temporary event to bypass pairing during Alpha
 */
export const alphaLoginAsTestUserRequested = createAction(
  'pairingSlice/alphaLoginAsTestUserRequested'
);

function* requestAlphaLoginAsTestUser(): SagaIterator<void> {
  const nativeId: PromiseReturnType<typeof cookidooApi.id> = yield call(
    cookidooApi.id
  );
  const shortenedId = nativeId.slice(0, 6);

  const loginResponse: PromiseReturnType<typeof apiPostAuth> = yield call(
    apiPostAuth,
    {
      grantType: ApiAuthGrantType.Password,
      username: `tm6test_${shortenedId}@getdrop.com`,
      password: `tm6test_${shortenedId}`,
    }
  );

  if (!loginResponse.ok) {
    yield put(pairingConnectionError(loginResponse.details.message));
    yield put(
      snackbarEnqueued({ text: 'Login failed, please try again later' })
    );
    return;
  }

  const time: number = yield call(getTime);
  const authData: AppAuthData = {
    expiresAt: time + loginResponse.data.expiresIn * 1000,
    refreshToken: loginResponse.data.refreshToken,
    token: loginResponse.data.accessToken,
    userId: loginResponse.data.user.id.toString(),
    userEmail: loginResponse.data.user.email,
  };
  yield put(deviceEventsSecretReceived(`tm6test_${shortenedId}`));
  yield put(loginSuccess(authData));
}

export function* requestAlphaLoginAsTestUserWatcher(): SagaIterator<void> {
  yield takeLatest(alphaLoginAsTestUserRequested, requestAlphaLoginAsTestUser);
}

export const pairingSagasRestartable = [requestAlphaLoginAsTestUserWatcher];
