import snakecaseKeys from 'snakecase-keys';

import { userAppliancesApiUrl } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { AcknowledgedSuccessResponse, ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import type { DropEntityUri } from 'types/api/entity';
import type { DropPaginatedResponse } from 'types/api/response';
import type { DropUserAppliance } from 'types/api/user';

export interface ApiGetUserAppliancesRequest {
  page: number;
}

/**
 * Get all the paired appliances of the user.
 *
 * https://docs.kitchenos.com/reference/list-user-appliances-1
 */
export const apiGetUserAppliances =
  ({
    page,
  }: ApiGetUserAppliancesRequest): ApiRequestFn<
    DropPaginatedResponse<DropUserAppliance>
  > =>
  (apiContext) => {
    return fetchJson<DropPaginatedResponse<DropUserAppliance>>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Get,
      url: `${userAppliancesApiUrl}?page=${page}`,
    });
  };

export interface ApiGetUserAppliancesForRecipeRequest {
  recipeId: number;
}

/**
 * Returns uris of user appliances that are supported by the recipe
 *
 * https://docs.kitchenos.com/reference/list-user-appliances-1
 */
export const apiGetUserAppliancesForRecipe =
  ({
    recipeId,
  }: ApiGetUserAppliancesForRecipeRequest): ApiRequestFn<
    DropPaginatedResponse<DropEntityUri>
  > =>
  (apiContext) => {
    return fetchJson<DropPaginatedResponse<DropUserAppliance>>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Lean,
      httpMethod: HttpMethod.Get,
      url: `${userAppliancesApiUrl}?recipe_id=${recipeId}`,
    });
  };

interface ApiPostUserAppliancesExecuteRequest {
  userApplianceId: string;
  command: Record<string, unknown>;
}

/**
 * Execute a HAL command in an user appliance.
 *
 * https://docs.kitchenos.com/reference/execute-command-1
 */
export const apiPostUserAppliancesExecute =
  ({
    userApplianceId,
    command,
  }: ApiPostUserAppliancesExecuteRequest): ApiRequestFn<AcknowledgedSuccessResponse> =>
  (apiContext) => {
    return fetchJson<AcknowledgedSuccessResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Post,
      body: snakecaseKeys(command),
      url: `${userAppliancesApiUrl}/${userApplianceId}/execute`,
    });
  };

interface ApiPostUserAppliancesStopRequest {
  userApplianceId: string;
}

/**
 * Stops the currently running program in the appliance.
 *
 * https://docs.kitchenos.com/reference/stop-program-1
 */
export const apiPostUserAppliancesStop =
  ({
    userApplianceId,
  }: ApiPostUserAppliancesStopRequest): ApiRequestFn<AcknowledgedSuccessResponse> =>
  (apiContext) => {
    return fetchJson<AcknowledgedSuccessResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Post,
      url: `${userAppliancesApiUrl}/${userApplianceId}/stop`,
    });
  };
