import { Box, Grid } from '@mui/material';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DropColor, DropTypography } from 'app/theme';
import { Image } from 'components/Image/Image';
import { preloadScaleDialogRequested } from 'features/device/deviceEventsSagas';
import { CookingAssistant } from 'features/recipe/make/CookingAssistant';
import { Feedback } from 'features/recipe/make/Feedback';
import { ApplianceScreenModal } from 'features/recipe/make/applianceScreen/ApplianceScreenModal';
import { ApplianceToolbar } from 'features/recipe/make/applianceToolbar/ApplianceToolbar';
import { StepsList } from 'features/recipe/make/step/StepsList';
import { recipeStartRequested } from 'features/recipe/recipeSlice/sagas';
import {
  recipeFetchRequested,
  selectRecipe,
} from 'features/recipe/recipeSlice/slice';
import { getUrlHost } from 'utils/getUrlHost';

export const recipeImageAriaLabel = 'Recipe photo';

export const RecipeMakePage: FC = memo(function RecipeMakePage() {
  const { recipeId } = useParams<{ recipeId: string }>();
  const dispatch = useDispatch();

  const recipe = useSelector(selectRecipe);
  const isExternal = !!recipe?.flags.isExternal;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const id = parseInt(recipeId!);
    dispatch(recipeFetchRequested(id));
    dispatch(recipeStartRequested(id));
  }, [dispatch, recipeId]);

  useEffect(() => {
    dispatch(preloadScaleDialogRequested());
  }, [dispatch]);

  if (!recipe) {
    // TODO: Skeleton
    // For TM6 doesn't make sense because you can't get to make page directly
    // And recipe is already loaded on details page
    return null;
  }

  return (
    <>
      <Grid
        container
        sx={{
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            height: '100vh',
            backgroundColor: DropColor.SurfacePrimary,
            color: DropColor.TextSecondary,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              height: 145,
              position: 'relative',
              flexShrink: 0,
            }}
          >
            {recipe.image?.data && (
              <Image
                sx={{ position: 'absolute' }}
                imageData={recipe.image.data}
                alt={recipeImageAriaLabel}
              />
            )}
            <Box
              sx={{
                position: 'absolute',
                pt: 3,
                px: 4,
                pb: 4,
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.67))`,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  typography: DropTypography.Subtitle2Bold,
                  color: DropColor.TextPrimary,
                  lineClamp: 3,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  boxOrient: 'vertical',
                }}
              >
                {recipe.name}
              </Box>
              <Box
                sx={{
                  typography: DropTypography.Caption,
                  color: DropColor.TextSecondary,
                }}
              >
                {isExternal
                  ? getUrlHost(recipe.sourceUrl)
                  : recipe.user.fullName}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
              paddingBottom: 20,
            }}
          >
            <ApplianceToolbar />
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            px: 'calc(1/12 * 100%)',
            height: '100vh',
            overflowY: 'auto',
            willChange: 'scroll-position',
          }}
        >
          <StepsList recipe={recipe} />
          <Feedback />
        </Grid>
      </Grid>
      <CookingAssistant />
      <ApplianceScreenModal />
    </>
  );
});
