import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReadingTarget } from 'features/recipe/make/applianceScreen/ReadingTarget';
import {
  selectUserApplianceReadingsTimeExtendPending,
  selectUserApplianceReadingsTimeTarget,
} from 'features/userAppliances/selectors';
import { formatSeconds } from 'utils/formatSeconds';

interface TimeReadingTargetProps {
  applianceId: string;
}

export const TimeReadingTarget: FC<TimeReadingTargetProps> = memo(
  function TimeReadingTarget({ applianceId }) {
    const timeTarget = useSelector(
      selectUserApplianceReadingsTimeTarget(applianceId)
    );

    const timeExtend = useSelector(
      selectUserApplianceReadingsTimeExtendPending(applianceId)
    );

    const time = timeExtend ?? timeTarget;

    const value = time !== undefined ? formatSeconds(time) : undefined;

    return <ReadingTarget value={value} />;
  }
);
