import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Sentence } from 'features/recipe/make/step/Sentence';
import { StepNumber } from 'features/recipe/make/step/StepNumber';
import { SmartApplianceControlButtons } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import { selectUserApplianceStepRunning } from 'features/userAppliances/selectors';

interface RunningStepProps {
  sx?: SxProps<Theme>;
  applianceId: string;
}

export const RunningStep: FC<RunningStepProps> = memo(function RunningStep({
  sx,
  applianceId,
}) {
  const recipe = useSelector(selectRecipe);
  const step = useSelector(selectUserApplianceStepRunning(applianceId));

  if (!step || !recipe) {
    return null;
  }

  return (
    <Box sx={sx}>
      <StepNumber
        isActive
        stepIndex={recipe.steps.indexOf(step)}
        totalSteps={recipe.steps.length}
      />
      <Sentence sentence={step.sentence} />
      <SmartApplianceControlButtons
        sx={{
          marginTop: 8,
        }}
        step={step}
      />
    </Box>
  );
});
