import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropTypography } from 'app/theme';

interface SentenceProps {
  sentence?: string;
}

export const Sentence: FC<SentenceProps> = memo(function Sentence({
  sentence,
}) {
  if (!sentence) {
    return null;
  }
  return (
    <Box
      sx={{
        typography: DropTypography.H5,
      }}
    >
      {sentence}
    </Box>
  );
});
