import snakecaseKeys from 'snakecase-keys';

import { devicesActivationApiUrl, devicesHelloApiUrl } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { ApiResponse } from 'api/types';
import { HttpMethod } from 'api/types';
import type { DropMqttConnectionInfo } from 'types/api/events';

export interface ApiPostDevicesActivationRequest {
  nativeId: string;
  applianceId: number;
  sig: string;
}

export interface ApiPostDevicesActivationResponse {
  mobileUrl: string;
  data: DropMqttConnectionInfo;
}

export const apiPostDevicesActivation = (
  body: ApiPostDevicesActivationRequest
): Promise<ApiResponse<ApiPostDevicesActivationResponse>> => {
  return fetchJson<ApiPostDevicesActivationResponse>({
    apiContext: { authData: null },
    httpMethod: HttpMethod.Post,
    body: snakecaseKeys(body),
    url: devicesActivationApiUrl,
  });
};

export const getApiGetDevicesHelloUrl = (nativeId: string) =>
  `${devicesHelloApiUrl}?native_id=${nativeId}&version=1.0.0&type=mqtt5-ws`;

export interface ApiGetDevicesHelloRequest {
  sig: string;
  nativeId: string;
}

export interface ApiGetDevicesHelloResponse {
  action: 'connect';
  data: DropMqttConnectionInfo;
}

export const apiGetDevicesHello = ({
  nativeId,
  sig,
}: ApiGetDevicesHelloRequest): Promise<
  ApiResponse<ApiGetDevicesHelloResponse>
> => {
  return fetchJson<ApiGetDevicesHelloResponse>({
    apiContext: { authData: null },
    httpMethod: HttpMethod.Get,
    url: `${getApiGetDevicesHelloUrl(nativeId)}&sig=${sig}`,
  });
};
