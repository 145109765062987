import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type {
  ConnectionInfoFactory,
  OnConnectHandler,
  OnDisconnectHandler,
  OnErrorHandler,
  OnMessageHandler,
  OpenMqttConnection,
} from 'api/mqtt';
import { openMqttConnection } from 'api/mqtt';
import type { ApiResponse } from 'api/types';
import { apiGetUserEvents } from 'api/userEvents';
import type { RootState } from 'app/store/rootReducer';
import { requestDebugMessageAdd } from 'features/debug/debugSlice';
import { cookidooApi } from 'features/device/cookidoo/api';
import { loginSuccess, logoutSuccess } from 'features/login/loginSlice';
import {
  userApplianceReadingsUpdated,
  UserApplianceReadingType,
  userApplianceStateSet,
} from 'features/userAppliances/userAppliancesSlice';
import type {
  DropUserEventDuration,
  DropUserEventHeating,
  DropUserEventProgram,
  DropUserEventProgramAborted,
  DropUserEventProgramFinished,
  DropUserEventRemainingTime,
  DropUserEventStatus,
  DropUserEventTemperature,
  DropUserEventTemperatureSetpoint,
  UserEventsIncomingMessages,
} from 'features/userEvents/UserEvents';
import { DropUserEventType } from 'features/userEvents/UserEvents';
import type { DropMqttConnectionInfo } from 'types/api/events';
import { DropApplianceState } from 'types/api/userAppliance';
import type { PromiseReturnType } from 'types/promiseReturnType';

export const openUserEventsMqttConnection =
  openMqttConnection as OpenMqttConnection<UserEventsIncomingMessages>;

interface UserAppliancesState {
  connected: boolean;
  connectionError?: string;
}

export const initialState: UserAppliancesState = {
  connected: false,
};

const userEventsSlice = createSlice({
  name: 'userEventsSlice',
  initialState,
  reducers: {
    userEventsConnected(state) {
      state.connected = true;
    },
    userEventsDisconnected(state) {
      state.connected = false;
    },
    userEventsConnectionError(state, { payload }: PayloadAction<string>) {
      state.connectionError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
    builder.addCase(loginSuccess, () => initialState);
  },
});

export const {
  reducer: userEventsReducer,
  actions: {
    userEventsConnected,
    userEventsDisconnected,
    userEventsConnectionError,
  },
} = userEventsSlice;

export const selectUserEventsConnected =
  () =>
  (state: RootState): boolean =>
    state.userEvents.connected;

export const selectUserEventsConnectionError =
  () =>
  (state: RootState): string | undefined =>
    state.userEvents.connectionError;

export const apiGetUserEventsSaga = createRequestApiSaga(
  apiGetUserEvents,
  'User events channel details loading'
);

export const getUserEventsConnectionInfo: ConnectionInfoFactory =
  function* getUserEventsConnectionInfo(): ReturnType<ConnectionInfoFactory> {
    const nativeId: PromiseReturnType<typeof cookidooApi['id']> = yield call(
      cookidooApi.id
    );

    const response: ApiResponse<DropMqttConnectionInfo> = yield call(
      apiGetUserEventsSaga,
      { clientId: nativeId }
    );

    if (!response.ok) {
      yield put(userEventsConnectionError(response.details.message));
      return;
    }

    const { data } = response;

    return {
      ...data,
      subscriptionTopics: data.events.map(({ topic }) => topic),
    };
  };

export const handleUserEventsOnConnect: OnConnectHandler =
  function* handleUserEventsOnConnect(): ReturnType<OnConnectHandler> {
    yield call(requestDebugMessageAdd, `[U-CONNECTED]`);
    yield put(userEventsConnected());
  };

export const handleUserEventsOnDisconnect: OnDisconnectHandler =
  function* handleUserEventsOnDisconnect(): ReturnType<OnDisconnectHandler> {
    yield call(requestDebugMessageAdd, `[U-DISCONNECTED]`);
    yield put(userEventsDisconnected());
  };

export const handleUserEventsOnError: OnErrorHandler =
  function* handleUserEventsOnError({ error }): ReturnType<OnErrorHandler> {
    yield call(requestDebugMessageAdd, `[U-ERROR] ${error}`);
    yield put(userEventsConnectionError(error.message));
  };

export function* handleUserEventsStatus({
  applianceId,
  stepId,
  event,
}: DropUserEventStatus): SagaIterator<void> {
  yield put(
    userApplianceStateSet({
      id: applianceId,
      applianceState: event.value,
      stepId,
    })
  );
}

export function* handleUserEventsDuration({
  applianceId,
  event,
}: DropUserEventDuration): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.TimeTarget,
        value: event.value,
      },
    })
  );
}

export function* handleUserEventsRemainingTime({
  applianceId,
  event,
}: DropUserEventRemainingTime): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.TimeRemaining,
        value: event.value,
      },
    })
  );
}

export function* handleUserEventsTemperatureSetpoint({
  applianceId,
  event,
}: DropUserEventTemperatureSetpoint): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.TemperatureTarget,
        value: event.value,
      },
    })
  );
}

export function* handleUserEventsTemperature({
  applianceId,
  event,
}: DropUserEventTemperature): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.Temperature,
        value: event.value,
      },
    })
  );
}

export function* handleUserEventsHeating({
  applianceId,
  event,
}: DropUserEventHeating): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.Heating,
        value: event.value,
      },
    })
  );
}

export function* handleUserEventsProgram({
  applianceId,
  event,
}: DropUserEventProgram): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.Program,
        value: event.value,
      },
    })
  );
}

export function* handleUserEventsProgramAborted({
  applianceId,
}: DropUserEventProgramAborted): SagaIterator<void> {
  yield put(
    userApplianceReadingsUpdated({
      id: applianceId,
      reading: {
        type: UserApplianceReadingType.Program,
        value: undefined,
      },
    })
  );
}

export function* handleUserEventsProgramFinished({
  applianceId,
  stepId,
}: DropUserEventProgramFinished): SagaIterator<void> {
  yield put(
    userApplianceStateSet({
      id: applianceId,
      applianceState: DropApplianceState.Pending,
      stepId,
    })
  );

  yield call(cookidooApi.audioAlert, 0);
}

export const handleUserEventsOnMessage: OnMessageHandler<UserEventsIncomingMessages> =
  function* handleUserEventsOnMessage({
    payload,
    topic,
  }): ReturnType<OnMessageHandler<UserEventsIncomingMessages>> {
    yield call(
      requestDebugMessageAdd,
      `[U-IN] ${topic} ${JSON.stringify(payload)}`
    );

    // Type narrowing doesn't work for nested object for now, so asserting type with "as"
    // However, there is an open PR https://github.com/microsoft/TypeScript/pull/38839
    // TODO: remove this type assertion when it's merged and new TypeScript arrives
    switch (payload.event.type) {
      case DropUserEventType.Status:
        yield call(handleUserEventsStatus, payload as DropUserEventStatus);
        break;
      case DropUserEventType.Duration:
        yield call(handleUserEventsDuration, payload as DropUserEventDuration);
        break;
      case DropUserEventType.RemainingTime:
        yield call(
          handleUserEventsRemainingTime,
          payload as DropUserEventRemainingTime
        );
        break;
      case DropUserEventType.TemperatureSetpoint:
        yield call(
          handleUserEventsTemperatureSetpoint,
          payload as DropUserEventTemperatureSetpoint
        );
        break;
      case DropUserEventType.Temperature:
        yield call(
          handleUserEventsTemperature,
          payload as DropUserEventTemperature
        );
        break;
      case DropUserEventType.Heating:
        yield call(handleUserEventsHeating, payload as DropUserEventHeating);
        break;
      case DropUserEventType.Program:
        yield call(handleUserEventsProgram, payload as DropUserEventProgram);
        break;
      case DropUserEventType.ProgramAborted:
        yield call(
          handleUserEventsProgramAborted,
          payload as DropUserEventProgramAborted
        );
        break;
      case DropUserEventType.ProgramFinished:
        yield call(
          handleUserEventsProgramFinished,
          payload as DropUserEventProgramFinished
        );
        break;
      default:
        break;
    }
  };

export function* connectToUserEvents(): SagaIterator<void> {
  // Race will cancel MQTT connection on logout
  yield race([
    call(openUserEventsMqttConnection, {
      getConnectionInfo: getUserEventsConnectionInfo,
      onConnect: handleUserEventsOnConnect,
      onDisconnect: handleUserEventsOnDisconnect,
      onError: handleUserEventsOnError,
      onMessage: handleUserEventsOnMessage,
    }),
    take(logoutSuccess),
  ]);
}

export function* connectToUserEventsWatcher(): SagaIterator<void> {
  yield takeLatest(loginSuccess, connectToUserEvents);
}

export const userEventsSagasRestartable = [connectToUserEventsWatcher];
