import type { SxProps, Theme } from '@mui/material';

import { DropColor, DropTypography } from 'app/theme';

export const sxWrapper: SxProps<Theme> = {
  display: 'flex',
};

export const sxButtonWrapper: SxProps<Theme> = {
  flexShrink: 0,
  flexGrow: 0,
};

export const sxServingsWrapper: SxProps<Theme> = {
  flexShrink: 0,
  textAlign: 'center',
  px: 4,
};

export const sxTitle: SxProps<Theme> = {
  typography: DropTypography.Body2,
};

export const sxText = (isScalingPending: boolean): SxProps<Theme> => ({
  typography: DropTypography.Subtitle1Bold,
  transition: 'color 0.4s',
  color: isScalingPending ? DropColor.TextSecondary : DropColor.TextPrimary,
});
