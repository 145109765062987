import type { PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  apiGetCollectionRecipes,
  apiGetUserCollections,
} from 'api/collections';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiRequestFnResponseType } from 'api/types';
import type { RootState } from 'app/store/rootReducer';
import { tm6CollectionName } from 'features/favorites/constants';
import { loginSuccess, logoutSuccess } from 'features/login/loginSlice';
import type { DropRecipeLean } from 'types/api/recipe';
import type { DropPaginatedResponse } from 'types/api/response';
import { getErrorString } from 'utils/getErrorString';

const favoritesAdapter = createEntityAdapter<DropRecipeLean>();

interface FavoritesRecipeState
  extends ReturnType<typeof favoritesAdapter['getInitialState']> {
  isFetching: boolean;
  isFetched: boolean;
  error?: string;
  page: number;
  totalPages?: number;
  totalRecipes?: number;
}

export const initialState: FavoritesRecipeState = {
  ...favoritesAdapter.getInitialState(),
  isFetching: false,
  isFetched: false,
  page: 0,
};

const favoritesSlice = createSlice({
  name: 'favoritesSlice',
  initialState,
  reducers: {
    favoritesRefreshRequested(state) {
      state.error = undefined;
      state.page = 0;
      state.isFetching = false;
      state.isFetched = false;
    },
    favoritesNextPageRequested(state) {
      state.error = undefined;
    },
    favoritesIsFetching(state) {
      state.isFetching = true;
      state.error = undefined;
    },
    favoritesFetchFinished(
      state,
      { payload }: PayloadAction<DropPaginatedResponse<DropRecipeLean>>
    ) {
      state.isFetching = false;
      state.isFetched = true;
      state.error = undefined;
      state.page = payload.meta.page;
      state.totalRecipes = payload.meta.total;
      state.totalPages = Math.ceil(payload.meta.total / payload.meta.perPage);
      if (state.page === 1) {
        favoritesAdapter.setAll(state, payload.results);
      } else {
        favoritesAdapter.addMany(state, payload.results);
      }
    },
    favoritesFetchFailed(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
    builder.addCase(loginSuccess, () => initialState);
  },
});

export const {
  reducer: favoritesReducer,
  actions: {
    favoritesRefreshRequested,
    favoritesNextPageRequested,
    favoritesIsFetching,
    favoritesFetchFinished,
    favoritesFetchFailed,
  },
} = favoritesSlice;

export const selectFavoritesFetching = (state: RootState): boolean =>
  state.favorites.isFetching;

export const selectFavoritesFetched = (state: RootState): boolean =>
  state.favorites.isFetched;

export const selectFavoritesError = (state: RootState): string | undefined =>
  state.favorites.error;

export const selectFavoritesPage = (state: RootState): number =>
  state.favorites.page;

export const selectFavoritesTotalRecipes = (
  state: RootState
): number | undefined => state.favorites.totalRecipes;

export const selectFavoritesHasMore = (state: RootState): boolean =>
  state.favorites.page === 0 ||
  (state.favorites.totalPages !== undefined &&
    state.favorites.page < state.favorites.totalPages);

export const { selectAll: selectFavoritesAll, selectById: selectFavoriteById } =
  favoritesAdapter.getSelectors((state: RootState) => state.favorites);

export const apiGetUserCollectionsSaga = createRequestApiSaga(
  apiGetUserCollections,
  'User collections loading'
);

export const apiGetCollectionRecipesSaga = createRequestApiSaga(
  apiGetCollectionRecipes,
  'Collection recipes loading'
);

export function* requestFavoritesNextPage() {
  const isFavoritesFetching: ReturnType<typeof selectFavoritesFetching> =
    yield select(selectFavoritesFetching);

  if (isFavoritesFetching) {
    return;
  }

  const isFavoritesHasMore: ReturnType<typeof selectFavoritesHasMore> =
    yield select(selectFavoritesHasMore);

  if (!isFavoritesHasMore) {
    return;
  }

  yield put(favoritesIsFetching());
  const page: ReturnType<typeof selectFavoritesPage> = yield select(
    selectFavoritesPage
  );
  try {
    // TODO: make proper collections handling, put into redux store, allow multiple collections
    const userCollectionsResponse: ApiRequestFnResponseType<
      typeof apiGetUserCollections
    > = yield call(apiGetUserCollectionsSaga);

    if (!userCollectionsResponse.ok) {
      yield put(favoritesFetchFailed(userCollectionsResponse.details.message));
      return;
    }

    const tm6Collection = userCollectionsResponse.data.results.find(
      (x) => x.name === tm6CollectionName
    );

    if (!tm6Collection) {
      yield put(favoritesFetchFailed('TM6 collection not found'));
      return;
    }

    const collectionResponse: ApiRequestFnResponseType<
      typeof apiGetCollectionRecipes
    > = yield call(apiGetCollectionRecipesSaga, {
      page: page + 1,
      collectionId: tm6Collection.id,
    });

    if (!collectionResponse.ok) {
      yield put(favoritesFetchFailed(collectionResponse.details.message));
      return;
    }

    yield put(favoritesFetchFinished(collectionResponse.data));

    return;
  } catch (e) {
    yield put(favoritesFetchFailed(getErrorString(e)));
  }
}

export function* requestFavoritesNextPageWatcher() {
  yield takeLatest(favoritesNextPageRequested, requestFavoritesNextPage);
}

export const favoritesSagasRestartable = [requestFavoritesNextPageWatcher];
