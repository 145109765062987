import { Box, ListItem } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from 'app/store/rootReducer';
import { DropColor, DropTypography } from 'app/theme';
import { Image } from 'components/Image/Image';
import { ReadingCurrentSize } from 'features/recipe/make/applianceScreen/ReadingCurrent';
import { TimeReadingCurrent } from 'features/recipe/make/applianceScreen/TimeReadingCurrent';
import {
  selectUserAppliance,
  selectUserApplianceState,
  selectUserApplianceReadingsProgram,
} from 'features/userAppliances/selectors';
import { userApplianceScreenOpen } from 'features/userAppliances/userAppliancesSlice';

interface ApplianceItemProps {
  userApplianceId: string;
}

export const ApplianceItem: FC<ApplianceItemProps> = memo(function LocalTimer({
  userApplianceId,
}) {
  const dispatch = useDispatch();

  const userAppliance = useSelector(selectUserAppliance(userApplianceId));
  const program = useSelector((state: RootState) => {
    // Show appliance state if no program is running
    return (
      selectUserApplianceReadingsProgram(userApplianceId)(state) ||
      selectUserApplianceState(userApplianceId)(state)
    );
  });

  const handleClick = () => {
    dispatch(
      userApplianceScreenOpen({
        id: userApplianceId,
      })
    );
  };

  return (
    <ListItem
      sx={{
        display: 'flex',
        pt: 2,
        px: 4,
        pb: 3,
        alignItems: 'flex-start',
      }}
      key={userApplianceId}
      button
      onClick={handleClick}
      divider
    >
      {userAppliance?.appliance.image?.data && (
        <Image
          sx={{
            height: 32,
            width: 32,
            mt: 2,
            mr: 4,
            // TODO: Replace with colorization to font color when Image color is implemented in HOL-39
            filter: 'brightness(0) contrast(0.5) brightness(2.5)',
          }}
          imageData={userAppliance.appliance.image.data}
          width={32}
        />
      )}
      <div>
        <TimeReadingCurrent
          applianceId={userApplianceId}
          size={ReadingCurrentSize.Small}
        />
        <Box
          sx={{
            typography: DropTypography.Body2,
            color: DropColor.TextSecondary,
            marginTop: -1,
            // State is lowercase
            textTransform: 'capitalize',
          }}
        >
          {program}
        </Box>
      </div>
    </ListItem>
  );
});
