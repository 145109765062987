import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';

import { loginSuccess, logoutSuccess } from 'features/login/loginSlice';
import { recipeFetchFinished } from 'features/recipe/recipeSlice/slice';
import {
  getLocalTimerId,
  localTimerApplianceIdPrefix,
} from 'features/userAppliances/localTimer/getLocalTimerId';
import { shouldStepUseLocalTimer } from 'features/userAppliances/localTimer/shouldStepUseLocalTimer';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropNumericTemperature } from 'types/api/temperature';
import type { DropUserAppliance } from 'types/api/user';
import { DropApplianceState } from 'types/api/userAppliance';

import localTimerIcon from './local-timer.png';

/**
 * Step of extending time in Appliance UI
 */
export const extendTimeStep = 30;

export enum UserApplianceReadingType {
  TimeRemaining = 'TimeRemaining',
  TimeTarget = 'TimeTarget',
  /**
   * Extend button extends time for appliance, this reading
   * is used to store how much time user wants to extend
   */
  TimeExtendPending = 'TimeExtendPending',
  Temperature = 'Temperature',
  TemperatureTarget = 'TemperatureTarget',
  Heating = 'Heating',
  Program = 'Program',
}

interface UserApplianceReadingBase<
  TType extends UserApplianceReadingType,
  TValue
> {
  type: TType;
  value?: TValue;
}

export type UserApplianceReadingTimeRemaining = UserApplianceReadingBase<
  UserApplianceReadingType.TimeRemaining,
  number
>;

export type UserApplianceReadingTimeTarget = UserApplianceReadingBase<
  UserApplianceReadingType.TimeTarget,
  number
>;

export type UserApplianceReadingTimeExtendPending = UserApplianceReadingBase<
  UserApplianceReadingType.TimeExtendPending,
  number
>;

export type UserApplianceReadingTemperature = UserApplianceReadingBase<
  UserApplianceReadingType.Temperature,
  DropNumericTemperature
>;

export type UserApplianceReadingTemperatureTarget = UserApplianceReadingBase<
  UserApplianceReadingType.TemperatureTarget,
  DropNumericTemperature
>;

export type UserApplianceReadingHeating = UserApplianceReadingBase<
  UserApplianceReadingType.Heating,
  boolean
>;

export type UserApplianceReadingProgram = UserApplianceReadingBase<
  UserApplianceReadingType.Program,
  string
>;

export type UserApplianceReading =
  | UserApplianceReadingTimeRemaining
  | UserApplianceReadingTimeTarget
  | UserApplianceReadingTimeExtendPending
  | UserApplianceReadingTemperature
  | UserApplianceReadingTemperatureTarget
  | UserApplianceReadingHeating
  | UserApplianceReadingProgram;

interface UserAppliancesState {
  appliances: Record<string, DropUserAppliance>;
  states: Record<string, DropApplianceState>;
  readings: Record<
    string,
    Partial<Record<UserApplianceReadingType, UserApplianceReading>>
  >;
  runningStepsIds: Record<string, number | undefined>;
  error?: string;
  isFetching: boolean;
  isFetched: boolean;
  /**
   * Id of user appliance currently opened on appliance screen
   */
  applianceScreenApplianceId?: string;
}

export const initialState: UserAppliancesState = {
  isFetching: false,
  isFetched: false,
  appliances: {},
  states: {},
  readings: {},
  runningStepsIds: {},
};

const userAppliancesSlice = createSlice({
  name: 'userAppliancesSlice',
  initialState,
  reducers: {
    userAppliancesFetchFinished(
      state,
      { payload }: PayloadAction<DropUserAppliance[]>
    ) {
      // Preserve timers
      const localTimers = pickBy(state.appliances, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );
      state.appliances = localTimers;

      // Preserve timers states
      const localTimersStates = pickBy(state.states, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );
      state.states = localTimersStates;

      // Preserve timers readings
      const localTimersReadings = pickBy(state.readings, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );
      state.readings = localTimersReadings;

      // Preserve timers running steps
      const localTimersStepsRunning = pickBy(state.runningStepsIds, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );
      state.runningStepsIds = localTimersStepsRunning;

      // Add fetched appliances
      for (const appliance of payload) {
        state.appliances[appliance.id] = appliance;
        // Treat device as off until we receive its state
        state.states[appliance.id] = DropApplianceState.Off;
      }

      state.isFetched = true;
      state.isFetching = false;
      state.error = undefined;
    },
    userAppliancesFetchStarted(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.error = undefined;
    },
    userAppliancesFetchFailed(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.isFetched = false;
      state.error = payload;
    },
    userApplianceStateSet(
      state,
      {
        payload: { id, applianceState, stepId },
      }: PayloadAction<{
        id: string;
        applianceState: DropApplianceState;
        stepId: number | undefined;
      }>
    ) {
      state.states[id] = applianceState;
      state.runningStepsIds[id] = stepId;
    },
    userApplianceReadingsUpdated(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        reading: UserApplianceReading;
      }>
    ) {
      updateUserApplianceReadings(state, payload);
    },
    userApplianceReadingsExtendTimeIncreased(
      state,
      {
        payload: { id },
      }: PayloadAction<{
        id: string;
      }>
    ) {
      const currentExtendTime =
        selectUserApplianceReadingsTimeExtendPendingLocal(id)(state) || 0;

      updateUserApplianceReadings(state, {
        id,
        reading: {
          type: UserApplianceReadingType.TimeExtendPending,
          value: currentExtendTime + extendTimeStep,
        },
      });
    },
    userApplianceReadingsExtendTimeDecreased(
      state,
      {
        payload: { id },
      }: PayloadAction<{
        id: string;
      }>
    ) {
      const currentExtendTime =
        selectUserApplianceReadingsTimeExtendPendingLocal(id)(state) || 0;
      const decreasedTime = currentExtendTime - extendTimeStep;

      updateUserApplianceReadings(state, {
        id,
        reading: {
          type: UserApplianceReadingType.TimeExtendPending,
          // If user decreases time to 0 - exit extend mode
          value: decreasedTime <= 0 ? undefined : decreasedTime,
        },
      });
    },
    userApplianceScreenOpen(
      state,
      {
        payload: { id },
      }: PayloadAction<{
        id: string | undefined;
      }>
    ) {
      state.applianceScreenApplianceId = id;
    },
    userApplianceScreenClosed(state) {
      if (!state.applianceScreenApplianceId) {
        return;
      }
      updateUserApplianceReadings(state, {
        id: state.applianceScreenApplianceId,
        reading: {
          type: UserApplianceReadingType.TimeExtendPending,
          value: undefined,
        },
      });
      state.applianceScreenApplianceId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchFinished, (state, { payload: recipe }) => {
      // Remove previous timers
      state.appliances = omitBy(state.appliances, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );

      state.states = omitBy(state.states, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );

      state.readings = omitBy(state.readings, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );

      state.runningStepsIds = omitBy(state.runningStepsIds, (_, key) =>
        key.startsWith(localTimerApplianceIdPrefix)
      );

      // Creating fake local timer appliance for every step where appliance is not used
      recipe.steps.filter(shouldStepUseLocalTimer).forEach(({ id, action }) => {
        const localTimerId = getLocalTimerId(id);
        state.appliances[localTimerId] =
          createLocalTimerAppliance(localTimerId);
        state.states[localTimerId] = DropApplianceState.Ready;
        state.readings[localTimerId] = {
          [UserApplianceReadingType.Program]: {
            type: UserApplianceReadingType.Program,
            value: action ?? undefined,
          },
        };
        state.runningStepsIds[localTimerId] = id;
      });
    });
    builder.addCase(logoutSuccess, () => initialState);
    builder.addCase(loginSuccess, () => initialState);
  },
});

export const {
  reducer: userAppliancesReducer,
  actions: {
    userAppliancesFetchFinished,
    userAppliancesFetchStarted,
    userAppliancesFetchFailed,
    userApplianceStateSet,
    userApplianceReadingsUpdated,
    userApplianceReadingsExtendTimeIncreased,
    userApplianceReadingsExtendTimeDecreased,
    userApplianceScreenOpen,
    userApplianceScreenClosed,
  },
} = userAppliancesSlice;

function updateUserApplianceReadings(
  state: UserAppliancesState,
  { id, reading }: { id: string; reading: UserApplianceReading }
) {
  if (!state.readings[id]) {
    state.readings[id] = {};
  }
  state.readings[id][reading.type] = reading;
}

/**
 * Local timers do not exist on server, instead fake local appliances are created for every step
 * where appliance is not used, but timer exists
 */
const createLocalTimerAppliance = (
  localTimerId: string
): DropUserAppliance => ({
  id: localTimerId,
  uri: localTimerId,
  appliance: {
    id: 0,
    uri: localTimerId,
    name: DropApplianceCategory.Timer,
    image: {
      id: 0,
      uri: localTimerIcon,
      data: {
        uri: localTimerIcon,
        height: 32,
        width: 32,
        copies: {
          '32x32': {
            uri: localTimerIcon,
          },
        },
      },
    },
    category: DropApplianceCategory.Timer,
  },
});

// TODO: Remove all local selectors that are not used in reducers?

export const selectUserApplianceReadingsLocal =
  <TReading extends UserApplianceReading>(
    id: string,
    readingType: UserApplianceReadingType
  ) =>
  (state: UserAppliancesState): TReading | undefined =>
    state.readings[id]?.[readingType] as TReading | undefined;

export const selectUserApplianceReadingsTimeRemainingLocal =
  (id: string) => (state: UserAppliancesState) =>
    selectUserApplianceReadingsLocal<UserApplianceReadingTimeRemaining>(
      id,
      UserApplianceReadingType.TimeRemaining
    )(state)?.value;

export const selectUserApplianceReadingsTimeTargetLocal =
  (id: string) => (state: UserAppliancesState) =>
    selectUserApplianceReadingsLocal<UserApplianceReadingTimeTarget>(
      id,
      UserApplianceReadingType.TimeTarget
    )(state)?.value;

export const selectUserApplianceReadingsTimeExtendPendingLocal =
  (id: string) => (state: UserAppliancesState) =>
    selectUserApplianceReadingsLocal<UserApplianceReadingTimeExtendPending>(
      id,
      UserApplianceReadingType.TimeExtendPending
    )(state)?.value;

export const selectUserApplianceReadingsTemperatureLocal =
  (id: string) => (state: UserAppliancesState) =>
    selectUserApplianceReadingsLocal<UserApplianceReadingTemperature>(
      id,
      UserApplianceReadingType.Temperature
    )(state)?.value;

export const selectUserApplianceReadingsTemperatureTargetLocal =
  (id: string) => (state: UserAppliancesState) =>
    selectUserApplianceReadingsLocal<UserApplianceReadingTemperatureTarget>(
      id,
      UserApplianceReadingType.TemperatureTarget
    )(state)?.value;

export const selectUserApplianceReadingsProgramLocal =
  (id: string) => (state: UserAppliancesState) =>
    selectUserApplianceReadingsLocal<UserApplianceReadingProgram>(
      id,
      UserApplianceReadingType.Program
    )(state)?.value;
