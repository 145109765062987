import type { AnyAction } from 'redux';
import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { appConfig } from 'features/config/config';
import { cookidooApi } from 'features/device/cookidoo/api';
import { recipeFetchFinished } from 'features/recipe/recipeSlice/slice';
import { getLocalTimerId } from 'features/userAppliances/localTimer/getLocalTimerId';
import {
  selectUserApplianceReadingsTimeTarget,
  selectUserApplianceReadingsTimeRemaining,
} from 'features/userAppliances/selectors';
import {
  userApplianceReadingsUpdated,
  UserApplianceReadingType,
  userApplianceStateSet,
} from 'features/userAppliances/userAppliancesSlice';
import type { DropStepClient } from 'types/api/step';
import { DropApplianceState } from 'types/api/userAppliance';
import type { PromiseReturnType } from 'types/promiseReturnType';
import { getTime } from 'utils/getTime';

/* istanbul ignore next */
export const localTimerInterval = !appConfig.isTestEnv() ? 1000 : 1;

export function* runLocalTimer({ step }: { step: DropStepClient }) {
  const id = getLocalTimerId(step.id);

  const readingsTargetTime: ReturnType<
    ReturnType<typeof selectUserApplianceReadingsTimeTarget>
  > = yield select(selectUserApplianceReadingsTimeTarget(id));

  if (!readingsTargetTime) {
    return;
  }

  // Timer can run from the beginning or to be resumed,
  // initial time is target time if it's running from the beginning
  // or less otherwise
  const readingsTimeRemainingInitial: number =
    (yield select(selectUserApplianceReadingsTimeRemaining(id))) ||
    readingsTargetTime;

  yield put(
    userApplianceStateSet({
      id,
      applianceState: DropApplianceState.Running,
      stepId: step.id,
    })
  );

  const startedAt: ReturnType<typeof getTime> = yield call(getTime);

  const cookidooAlertCancelFn: PromiseReturnType<
    typeof cookidooApi.audioAlert
  > = yield call(cookidooApi.audioAlert, readingsTimeRemainingInitial);

  while (true) {
    const continueTimer: boolean = yield call(
      waitForLocalTimerTickOrStateChange,
      {
        id,
        stepId: step.id,
        readingsTimeRemainingInitial,
        startedAt,
        cookidooAlertCancelFn,
      }
    );

    if (!continueTimer) {
      return;
    }
  }
}

export function* waitForLocalTimerTickOrStateChange({
  id,
  stepId,
  readingsTimeRemainingInitial,
  startedAt,
  cookidooAlertCancelFn,
}: {
  id: string;
  stepId: number;
  readingsTimeRemainingInitial: number;
  startedAt: number;
  cookidooAlertCancelFn: () => void;
}) {
  const {
    timerCancelAction,
  }: {
    timerCancelAction: ReturnType<typeof userApplianceStateSet>;
  } = yield race({
    timerCancelAction: take(
      (action: AnyAction) =>
        // We want to cancel running timer if it's state changed
        (userApplianceStateSet.match(action) && action.payload.id === id) ||
        // Or opened another recipe
        recipeFetchFinished.match(action)
    ),
    nextSecond: delay(localTimerInterval),
  });

  if (timerCancelAction) {
    yield call(cookidooAlertCancelFn);
    return false;
  }

  const currentTime: ReturnType<typeof getTime> = yield call(getTime);
  const timeRemaining =
    readingsTimeRemainingInitial - Math.floor((currentTime - startedAt) / 1000);

  const readingTimeUpdated = Math.max(timeRemaining, 0);

  yield put(
    userApplianceReadingsUpdated({
      id,
      reading: {
        type: UserApplianceReadingType.TimeRemaining,
        value: readingTimeUpdated,
      },
    })
  );

  if (!readingTimeUpdated) {
    yield put(
      userApplianceStateSet({
        id,
        applianceState: DropApplianceState.Pending,
        stepId,
      })
    );
    return false;
  }

  return true;
}
