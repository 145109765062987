import { ApplianceSettingTemperatureThermomixIcon } from '@dropkitchen/icons-react';
import type { FC } from 'react';
import { memo } from 'react';

import { Reading } from 'features/recipe/make/applianceScreen/Reading';
import { TemperatureReadingCurrent } from 'features/recipe/make/applianceScreen/TemperatureReadingCurrent';
import { TemperatureReadingTarget } from 'features/recipe/make/applianceScreen/TemperatureReadingTarget';

export const ariaLabelTemperatureIcon = 'Temperature' as const;

interface TemperatureReadingProps {
  applianceId: string;
}

export const TemperatureReading: FC<TemperatureReadingProps> = memo(
  function TemperatureReading({ applianceId }) {
    return (
      <Reading
        iconElement={
          <ApplianceSettingTemperatureThermomixIcon
            titleAccess={ariaLabelTemperatureIcon}
          />
        }
        readingCurrentElement={
          <TemperatureReadingCurrent applianceId={applianceId} />
        }
        readingTargetElement={
          <TemperatureReadingTarget applianceId={applianceId} />
        }
      />
    );
  }
);
