import type { SnackbarCloseReason } from '@mui/material';
import { Fade, Snackbar as MuiSnackbar } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appConfig } from 'features/config/config';
import {
  selectSnackbarCurrent,
  snackbarDismissed,
} from 'features/snackbar/snackbarSlice';

/* istanbul ignore next */
export const snackBarTimeout = !appConfig.isTestEnv() ? 6000 : 1;
/* istanbul ignore next */
export const snackbarTransition = !appConfig.isTestEnv() ? 300 : 20;

export const Snackbar: FC = memo(function Snackbar() {
  const dispatch = useDispatch();
  const [closed, setClosed] = useState(true);

  const currentSnackbar = useSelector(selectSnackbarCurrent);

  useEffect(() => {
    if (currentSnackbar) {
      setClosed(false);
    }
  }, [currentSnackbar]);

  const handleClose = (_: unknown, reason: SnackbarCloseReason) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    setClosed(true);
  };

  const handleExited = () => {
    dispatch(snackbarDismissed());
  };

  if (!currentSnackbar) {
    return null;
  }

  return (
    <MuiSnackbar
      key={currentSnackbar.id}
      message={currentSnackbar.text}
      open={!closed}
      autoHideDuration={snackBarTimeout}
      onClose={handleClose}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: snackbarTransition, onExited: handleExited }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    />
  );
});
