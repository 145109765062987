// Just wrapping global timeout functions for easier testing
// Otherwise global function is called by react renderer and makes assert harder

export function setTimeout(handler: () => void, timeout?: number): number {
  return window.setTimeout(handler, timeout);
}

export function clearTimeout(handle?: number): void {
  window.clearTimeout(handle);
}
