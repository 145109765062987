import type { FC } from 'react';
import { memo } from 'react';

import { Step } from 'features/recipe/make/step/Step';
import type { DropRecipeClient } from 'types/api/recipe';

interface StepsListProps {
  recipe: DropRecipeClient;
}

export const StepsList: FC<StepsListProps> = memo(function StepsList({
  recipe,
}) {
  return (
    <>
      {recipe.steps.map((step, i) => (
        <Step
          key={step.id}
          sx={{
            marginTop: 8,
          }}
          stepIndex={i}
          recipe={recipe}
        />
      ))}
    </>
  );
});
