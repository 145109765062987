import type { SagaIterator } from '@redux-saga/core';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { SagaReturnType } from 'redux-saga/effects';
import {
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import {
  apiGetRecipe,
  apiPostRecipeFinish,
  apiPostRecipeReview,
  apiPostRecipeStart,
} from 'api/recipe';
import type { ApiRequestFnResponseType } from 'api/types';
import type { apiGetUserAppliancesForRecipe } from 'api/userAppliances';
import { appConfig } from 'features/config/config';
import {
  recipeFetchRequested,
  recipeFetchFailed,
  recipeFetchFinished,
  selectRecipe,
  recipeScalingStarted,
  selectRecipeOriginalServings,
  recipeScalingFailed,
  recipeScalingFinished,
  recipeScalingRequested,
  selectRecipeIsStarted,
  recipeStarted,
  recipeFinished,
  recipeReviewRequested,
  recipeReviewFailed,
} from 'features/recipe/recipeSlice/slice';
import { snackbarEnqueued } from 'features/snackbar/snackbarSlice';
import {
  apiGetUserAppliancesForRecipeSaga,
  selectOrWaitUserAppliancesUsedInRecipe,
} from 'features/userAppliances/userAppliancesSagas';
import { getErrorString } from 'utils/getErrorString';
import { getIdFromUri } from 'utils/getIdFromUri';

export const recipeNotLoadedError = 'Recipe is not loaded';
export const recipeStartError = 'Recipe start failed';
export const recipeScalingWarningMessageText =
  'Take extra care when resizing this recipe to avoid burnt food or personal injury';

/* istanbul ignore next */
/**
 * Debounce for recipe scaling clicks to allow user to click through till desired servings count
 */
export const recipeScalingDebounce = !appConfig.isTestEnv() ? 1000 : 0;

export const apiGetRecipeSaga = createRequestApiSaga(
  apiGetRecipe,
  'Recipe loading'
);

function* requestRecipe({
  payload,
}: PayloadAction<number>): SagaIterator<void> {
  const currentRecipe: ReturnType<typeof selectRecipe> = yield select(
    selectRecipe
  );
  if (currentRecipe?.id === payload) {
    return;
  }

  try {
    const supportedUserAppliancesResponse: ApiRequestFnResponseType<
      typeof apiGetUserAppliancesForRecipe
    > = yield call(apiGetUserAppliancesForRecipeSaga, {
      recipeId: payload,
    });

    if (!supportedUserAppliancesResponse.ok) {
      yield put(
        recipeFetchFailed(supportedUserAppliancesResponse.details.message)
      );
      return;
    }

    const response: ApiRequestFnResponseType<typeof apiGetRecipe> = yield call(
      apiGetRecipeSaga,
      {
        recipeId: payload,
        appliancesIds: supportedUserAppliancesResponse.data.results.map(
          ({ uri }) => getIdFromUri(uri)
        ),
      }
    );

    if (!response.ok) {
      yield put(recipeFetchFailed(response.details.message));
      return;
    }
    yield put(recipeFetchFinished(response.data));

    return;
  } catch (e) {
    yield put(recipeFetchFailed(getErrorString(e)));
  }
}

export function* requestRecipeFetchWatcher(): SagaIterator<void> {
  yield takeLatest(recipeFetchRequested, requestRecipe);
}

export const apiPostRecipeStartSaga = createRequestApiSaga(
  apiPostRecipeStart,
  'Starting recipe'
);

export const recipeStartRequested = createAction<number>(
  'recipeSlice/recipeStartRequested'
);

export function* requestRecipeStart({
  payload: recipeId,
}: ReturnType<typeof recipeStartRequested>): SagaIterator<void> {
  const isRecipeStarted: ReturnType<typeof selectRecipeIsStarted> =
    yield select(selectRecipeIsStarted);
  if (isRecipeStarted) {
    return;
  }

  const userAppliances: SagaReturnType<
    typeof selectOrWaitUserAppliancesUsedInRecipe
  > = yield call(selectOrWaitUserAppliancesUsedInRecipe);

  const response: ApiRequestFnResponseType<typeof apiGetRecipe> = yield call(
    apiPostRecipeStartSaga,
    {
      recipeId,
      appliancesUris: userAppliances.map(({ uri }) => uri),
    }
  );

  if (!response.ok) {
    yield put(
      snackbarEnqueued({
        text: recipeStartError,
      })
    );
    return;
  }

  yield put(recipeStarted());
}

export function* requestRecipeStartWatcher(): SagaIterator<void> {
  yield takeEvery(recipeStartRequested, requestRecipeStart);
}

export const apiPostRecipeFinishSaga = createRequestApiSaga(
  apiPostRecipeFinish,
  'Finishing recipe'
);

export const recipeFinishRequested = createAction(
  'recipeSlice/recipeFinishRequested'
);

export function* requestRecipeFinish(): SagaIterator<void> {
  const recipe: ReturnType<typeof selectRecipe> = yield select(selectRecipe);
  const isRecipeStarted: ReturnType<typeof selectRecipeIsStarted> =
    yield select(selectRecipeIsStarted);

  if (isRecipeStarted && recipe) {
    const userAppliances: SagaReturnType<
      typeof selectOrWaitUserAppliancesUsedInRecipe
    > = yield call(selectOrWaitUserAppliancesUsedInRecipe);

    yield call(apiPostRecipeFinishSaga, {
      recipeId: recipe.id,
      appliancesUris: userAppliances.map(({ uri }) => uri),
    });
  }

  yield put(recipeFinished());
}

export function* requestRecipeFinishWatcher(): SagaIterator<void> {
  yield takeEvery(recipeFinishRequested, requestRecipeFinish);
}

function* requestRecipeScaling({
  payload: targetServings,
}: PayloadAction<number>): SagaIterator<void> {
  const currentRecipe: ReturnType<typeof selectRecipe> = yield select(
    selectRecipe
  );
  const originalServings: ReturnType<typeof selectRecipeOriginalServings> =
    yield select(selectRecipeOriginalServings);

  if (!currentRecipe || !originalServings) {
    yield put(recipeScalingFailed(recipeNotLoadedError));
    return;
  }

  const userAppliances: SagaReturnType<
    typeof selectOrWaitUserAppliancesUsedInRecipe
  > = yield call(selectOrWaitUserAppliancesUsedInRecipe);

  yield put(
    snackbarEnqueued({
      text: recipeScalingWarningMessageText,
    })
  );

  yield put(recipeScalingStarted());

  const ratio = targetServings / originalServings;

  try {
    const response: ApiRequestFnResponseType<typeof apiGetRecipe> = yield call(
      apiGetRecipeSaga,
      {
        recipeId: currentRecipe.id,
        appliancesIds: userAppliances.map(({ id }) => id),
        ratio,
      }
    );

    if (!response.ok) {
      yield put(recipeScalingFailed(response.details.message));
      return;
    }
    yield put(recipeScalingFinished(response.data));

    return;
  } catch (e) {
    yield put(recipeScalingFailed(getErrorString(e)));
  }
}

export function* requestRecipeScalingWatcher(): SagaIterator<void> {
  yield debounce(
    recipeScalingDebounce,
    recipeScalingRequested,
    requestRecipeScaling
  );
}

export const apiPostRecipeReviewSaga = createRequestApiSaga(
  apiPostRecipeReview,
  'Submitting recipe review'
);

export function* requestRecipeReview({
  payload: { rating },
}: ReturnType<typeof recipeReviewRequested>): SagaIterator<void> {
  const recipe: ReturnType<typeof selectRecipe> = yield select(selectRecipe);

  if (!recipe) {
    return;
  }

  const response: ApiRequestFnResponseType<typeof apiPostRecipeReview> =
    yield call(apiPostRecipeReviewSaga, {
      recipeId: recipe.id,
      rating,
    });

  if (!response.ok) {
    yield put(recipeReviewFailed());
  }
}

export function* requestRecipeReviewWatcher(): SagaIterator<void> {
  yield takeLatest(recipeReviewRequested, requestRecipeReview);
}

export const recipeSagasRestartable = [
  requestRecipeFetchWatcher,
  requestRecipeScalingWatcher,
  requestRecipeStartWatcher,
  requestRecipeFinishWatcher,
  requestRecipeReviewWatcher,
];
