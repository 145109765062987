import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { RootState } from 'app/store/rootReducer';
import { appConfig, AppEnvironment } from 'features/config/config';
import { getTime } from 'utils/getTime';

export interface DebugMessage {
  time: number;
  message: string;
}

interface DebugState {
  debugMode: boolean;
  debugMessages: DebugMessage[];
}

export const initialState: DebugState = {
  debugMode: appConfig.isEnv(AppEnvironment.Development),
  debugMessages: [],
};

const debugSlice = createSlice({
  name: 'debugSlice',
  initialState,
  reducers: {
    debugModeToggled(state, { payload }: PayloadAction<boolean>) {
      state.debugMode = payload;
    },
    debugMessageAdded(state, { payload }: PayloadAction<DebugMessage>) {
      state.debugMessages.push(payload);
    },
  },
});

export const {
  reducer: debugReducer,
  actions: { debugModeToggled, debugMessageAdded },
} = debugSlice;

export const selectDebugMode =
  () =>
  (state: RootState): boolean =>
    state.debug.debugMode;

export const selectDebugMessages =
  () =>
  (state: RootState): DebugMessage[] =>
    state.debug.debugMessages;

export function* requestDebugMessageAdd(message: string): SagaIterator<void> {
  const debugMode: ReturnType<ReturnType<typeof selectDebugMode>> =
    yield select(selectDebugMode());

  if (!debugMode) {
    return;
  }

  const time = yield call(getTime);
  yield put(
    debugMessageAdded({
      time,
      message,
    })
  );
}
