import { appConfig } from 'features/config/config';

export const apiPathPrefix = '/api/v1';
/* istanbul ignore next */
const apiHost = appConfig.isDevOrTestEnv() ? '' : appConfig.platformApiUrl();
const apiUrl = `${apiHost}${apiPathPrefix}` as const;

// /api/v1
export const authTokenApiUrl = `${apiUrl}/organizations/1/auth/token` as const;

export const recipesApiUrl = `${apiUrl}/recipes` as const;
export const collectionsApiUrl = `${apiUrl}/collections` as const;

export const favoritesApiUrl = `${apiUrl}/users/me/favorites` as const;
export const userAppliancesApiUrl = `${apiUrl}/users/me/appliances` as const;
export const userEventsApiUrl = `${apiUrl}/users/me/events` as const;
export const userCollectionsApiUrl = `${apiUrl}/users/me/collections` as const;

// /devices

export const devicesActivationApiUrl = `${apiHost}/devices/activation` as const;
export const devicesHelloApiUrl = `${apiHost}/devices/hello` as const;
