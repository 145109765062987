import { RecipeTimeIcon, RecipeVerifiedIcon } from '@dropkitchen/icons-react';
import { Box, useTheme } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropBorderRadius, DropColor, DropTypography } from 'app/theme';
import {
  sxContent,
  sxMediaWrapper,
  sxWrapper,
} from 'features/favorites/RecipeCard.styles';
import type { DropRecipeLean } from 'types/api/recipe';
import { DurationFormat, formatDuration } from 'utils/formatDuration';
import { getImageCopy } from 'utils/getImageCopy';
import { getUrlHost } from 'utils/getUrlHost';
import { sxCompose } from 'utils/sxCompose';

import { recipeCardWidth } from './constants';

export const recipeCardVerifiedUserAriaTitle = 'Verified user';
export const recipeCardImageAriaLabel = 'Recipe image';

interface RecipeCardProps {
  recipe: DropRecipeLean;
}

export const RecipeCard: FC<RecipeCardProps> = memo(function RecipeCard({
  recipe,
}) {
  const theme = useTheme();
  const imageSrc =
    recipe.image?.data &&
    getImageCopy(recipe.image?.data, parseInt(theme.spacing(recipeCardWidth)));

  return (
    <Box sx={sxWrapper}>
      <Box
        sx={sxCompose(sxMediaWrapper, {
          backgroundColor: DropColor.SurfaceTertiary,
        })}
      >
        {imageSrc && (
          <>
            <img alt={recipeCardImageAriaLabel} src={imageSrc} />
            <Box
              sx={{
                backgroundImage:
                  'linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))',
                position: 'absolute',
                top: 0,
                height: '70px',
                width: '100%',
              }}
            />
          </>
        )}
        {recipe.badge && (
          <Box
            sx={{
              typography: DropTypography.CaptionBold,
              border: `2px solid`,
              borderColor: DropColor.CommonWhite,
              borderRadius: DropBorderRadius.Tag,
              padding: '0 7px',
              color: DropColor.CommonWhite,
              position: 'absolute',
              left: theme.spacing(3),
              top: theme.spacing(3),
            }}
          >
            {recipe.badge.name}
          </Box>
        )}
      </Box>
      <Box sx={sxContent}>
        <Box
          sx={{
            typography: DropTypography.Subtitle1Bold,
            color: DropColor.TextPrimary,
            lineClamp: 2,
            display: '-webkit-box',
            overflow: 'hidden',
            boxOrient: 'vertical',
          }}
        >
          {recipe.name}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '2px',
          }}
        >
          <Box
            sx={{
              typography: DropTypography.Body2,
              color: DropColor.TextPrimary,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {recipe.flags.isExternal
              ? getUrlHost(recipe.sourceUrl)
              : recipe.user.fullName}
          </Box>
          {recipe.user.isPartner && (
            <RecipeVerifiedIcon
              sx={{
                height: '20px',
                width: '20px',
                color: DropColor.PrimaryMain,
                marginLeft: 1,
              }}
              titleAccess={recipeCardVerifiedUserAriaTitle}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          typography: DropTypography.Caption,
          flexShrink: 0,
          flexGrow: 0,
          pt: 1,
          px: 3,
          pb: 3,
          color: DropColor.TextSecondary,
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <RecipeTimeIcon
          sx={{
            height: '20px',
            width: '20px',
            color: DropColor.TextSecondary,
            marginRight: 1,
          }}
        />
        {formatDuration(recipe.time, DurationFormat.Hm)}
      </Box>
    </Box>
  );
});
