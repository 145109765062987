import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { DropColor, DropTypography } from 'app/theme';
import { Image } from 'components/Image/Image';
import { WeighButton } from 'features/recipe/make/step/WeighButton';
import { selectRecipeScalingPending } from 'features/recipe/recipeSlice/slice';
import type {
  DropIngredientGroupIngredient,
  DropIngredientSectionIngredient,
} from 'types/api/ingredient';
import { sxCompose } from 'utils/sxCompose';

export interface IngredientProps {
  ingredient: DropIngredientGroupIngredient | DropIngredientSectionIngredient;
  showWeighButton?: boolean;
}

export const Ingredient: FC<IngredientProps> = memo(function Ingredient({
  ingredient,
  showWeighButton,
}) {
  const isScalingPending = useSelector(selectRecipeScalingPending);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flexBasis: 'auto',
          marginTop: 1,
          marginRight: 4,
        }}
      >
        {ingredient.ingredient.image && (
          <Image
            color={ingredient.ingredient.color}
            width={32}
            imageData={ingredient.ingredient.image.data}
            alt={`${ingredient.ingredient.name} icon`}
          />
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            typography: DropTypography.Subtitle1Bold,
          }}
        >
          {ingredient.ingredient.name}
        </Box>
        <Box
          sx={sxCompose(
            {
              typography: DropTypography.Body2,
              color: DropColor.TextPrimary,
              transition: 'color 0.4s',
            },
            isScalingPending && {
              color: DropColor.TextSecondary,
            }
          )}
        >
          {ingredient.quantityString}
        </Box>
        {ingredient.preparation && (
          <Box
            sx={{
              typography: DropTypography.Body2,
              color: DropColor.TextSecondary,
            }}
          >
            {ingredient.preparation}
          </Box>
        )}
      </Box>
      {showWeighButton && ingredient.needsWeighing && (
        <WeighButton
          sx={{
            marginLeft: 2,
          }}
          ingredient={ingredient}
          aria-label={`Weigh ${ingredient.ingredient.name}`}
        />
      )}
    </Box>
  );
});
