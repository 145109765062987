import type { PaletteOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type { InputTypographyOptions } from '@mui/material/styles/createTypography';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/500.css';

/**
 * Every defined color style in theme for using in `sx` `color` property
 * because this property is a string and has no autocomplete
 *
 * Unfortunately can't be used to define these colors in palette
 */
export enum DropColor {
  TextWhiteHigh = 'textWhite.high',
  TextWhiteMedium = 'textWhite.medium',
  TextWhiteLow = 'textWhite.low',
  TextBlackHigh = 'textBlack.high',
  TextBlackMedium = 'textBlack.medium',
  TextBlackLow = 'textBlack.low',
  TextPrimary = 'text.primary',
  TextSecondary = 'text.secondary',
  TextDisabled = 'text.disabled',
  CommonWhite = 'common.white',
  CommonBlack = 'common.black',
  BackgroundDefault = 'background.default',
  BackgroundPaper = 'background.paper',
  SurfacePrimary = 'surfacePrimary',
  SurfaceSecondary = 'surfaceSecondary',
  SurfaceTertiary = 'surfaceTertiary',
  SurfaceWhite = 'surfaceWhite',
  Overlay = 'overlay',
  Disabled = 'disabled',
  PrimaryMain = 'primary.main',
  PrimaryLight = 'primary.light',
  PrimaryContrastText = 'primary.contrastText',
  SecondaryMain = 'secondary.main',
  SecondaryDark = 'secondary.dark',
  SecondaryContrastText = 'secondary.contrastText',
  ErrorMain = 'error.main',
  ErrorContrastText = 'error.contrastText',
}

/**
 * Every defined font style in theme for using in `sx` `typography` property
 * because this property is a string and has no autocomplete
 *
 * Note: Current typography in designs is not very consistent
 * sometimes it uses normal and bold fonts variants, so declaring both for convenience
 */
export enum DropTypography {
  Body1 = 'body1',
  Body1Bold = 'body1Bold',
  Body2 = 'body2',
  Body2Bold = 'body2Bold',
  Button = 'button',
  Caption = 'caption',
  CaptionBold = 'captionBold',
  H1 = 'h1',
  H1Bold = 'h1Bold',
  H2 = 'h2',
  H2Bold = 'h2Bold',
  H3 = 'h3',
  H3Bold = 'h3Bold',
  H4 = 'h4',
  H4Bold = 'h4Bold',
  H5 = 'h5',
  H5Bold = 'h5Bold',
  H6 = 'h6',
  H6Bold = 'h6Bold',
  NumberL = 'numberL',
  NumberS = 'numberS',
  Overline = 'overline',
  OverlineBold = 'overlineBold',
  Subtitle1 = 'subtitle1',
  Subtitle1Bold = 'subtitle1Bold',
  Subtitle2 = 'subtitle2',
  Subtitle2Bold = 'subtitle2Bold',
}

/**
 * All border radius values in theme for using in `sx` `borderRadius` property
 * they are multiples of base border radius of 10
 */
export enum DropBorderRadius {
  Tag = 1,
  Small = 1.5,
  Container = 2,
  Pill = 999,
}

/**
 * Every defined zIndex in theme for using in `sx` `zIndex` property
 * because this property is a string and has no autocomplete
 */
export enum DropZIndex {
  MobileStepper = 'mobileStepper',
  SpeedDial = 'speedDial',
  AppBar = 'appBar',
  Drawer = 'drawer',
  Modal = 'modal',
  Snackbar = 'snackbar',
  Tooltip = 'tooltip',
  CookingAssistant = 'cookingAssistant',
}

declare module '@mui/material/styles' {
  export interface DropPaletteTextColor {
    high: string;
    medium: string;
    low: string;
  }

  interface Palette {
    textWhite: DropPaletteTextColor;
    textBlack: DropPaletteTextColor;
    surfacePrimary: string;
    surfaceSecondary: string;
    surfaceTertiary: string;
    surfaceWhite: string;
    overlay: string;
    disabled: string;
  }

  interface PaletteOptions {
    textWhite: DropPaletteTextColor;
    textBlack: DropPaletteTextColor;
    surfacePrimary: string;
    surfaceSecondary: string;
    surfaceTertiary: string;
    surfaceWhite: string;
    overlay: string;
    disabled: string;
  }
}

declare module '@mui/material/styles/createTypography' {
  /**
   * Designs sometimes use normal and bold fonts variants, so introducing these for convenience
   */
  interface AdditionalTypographyOptions {
    [DropTypography.H1Bold]: TypographyStyleOptions;
    [DropTypography.H2Bold]: TypographyStyleOptions;
    [DropTypography.H3Bold]: TypographyStyleOptions;
    [DropTypography.H4Bold]: TypographyStyleOptions;
    [DropTypography.H5Bold]: TypographyStyleOptions;
    [DropTypography.H6Bold]: TypographyStyleOptions;
    [DropTypography.Subtitle1Bold]: TypographyStyleOptions;
    [DropTypography.Subtitle2Bold]: TypographyStyleOptions;
    [DropTypography.Body1Bold]: TypographyStyleOptions;
    [DropTypography.Body2Bold]: TypographyStyleOptions;
    [DropTypography.CaptionBold]: TypographyStyleOptions;
    [DropTypography.OverlineBold]: TypographyStyleOptions;
    [DropTypography.NumberS]: TypographyStyleOptions;
    [DropTypography.NumberL]: TypographyStyleOptions;
  }

  interface InputTypographyOptions
    extends Record<Variant, TypographyStyleOptions> {
    [DropTypography.NumberS]: TypographyStyleOptions;
    [DropTypography.NumberL]: TypographyStyleOptions;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyOptions extends AdditionalTypographyOptions {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Typography extends AdditionalTypographyOptions {}
}

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {
    cookingAssistant: number;
  }
}

const dropPalette: PaletteOptions = {
  mode: 'dark',
  textWhite: {
    high: 'rgba(255, 255, 255, 0.87)',
    medium: 'rgba(255, 255, 255, 0.60)',
    low: 'rgba(255, 255, 255, 0.38)',
  },
  textBlack: {
    high: 'rgba(0, 0, 0, 0.87)',
    medium: 'rgba(0, 0, 0, 0.60)',
    low: 'rgba(0, 0, 0, 0.38)',
  },
  common: {
    white: '#FFF',
    black: '#000',
  },
  background: {
    default: '#121212',
  },
  surfacePrimary: '#222222',
  surfaceSecondary: '#2E2E2E',
  surfaceTertiary: '#383838',
  surfaceWhite: '#FFF',
  overlay: 'rgba(18, 18, 18, 0.5)',
  disabled: '#474747',
  primary: {
    main: '#66BB6A',
    light: '#81C784',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    main: '#FFF',
    dark: '#ABABAB',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  error: {
    main: '#EF5350',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
};

const dropTypography: InputTypographyOptions = {
  [DropTypography.H1]: {
    fontSize: '102px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '143px',
    letterSpacing: '-1.59px',
  },
  [DropTypography.H2]: {
    fontSize: '66px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '92px',
    letterSpacing: '-0.55px',
  },
  [DropTypography.H3]: {
    fontSize: '54px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '76px',
    letterSpacing: 'normal',
  },
  [DropTypography.H4]: {
    fontSize: '40px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '56px',
    letterSpacing: '0.29px',
  },
  [DropTypography.H5]: {
    fontSize: '30px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '42px',
    letterSpacing: 'normal',
  },
  [DropTypography.H6]: {
    fontSize: '26px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '36px',
    letterSpacing: '0.2px',
  },
  [DropTypography.Subtitle1]: {
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '30px',
    letterSpacing: '0.21px',
  },
  [DropTypography.Subtitle2]: {
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0.14px',
  },
  [DropTypography.Body1]: {
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '30px',
    letterSpacing: '0.61px',
  },
  [DropTypography.Body2]: {
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '28px',
    letterSpacing: '0.36px',
  },
  [DropTypography.Caption]: {
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '25px',
    letterSpacing: '0.6px',
  },
  [DropTypography.Overline]: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '2.4px',
  },
  [DropTypography.Button]: {
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    textTransform: 'none',
    lineHeight: '28px',
    letterSpacing: '1.93px',
  },
  [DropTypography.NumberS]: {
    fontFamily: '"Roboto Mono","Roboto","Helvetica","Arial",sans-serif',
    fontSize: '32px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '45px',
    letterSpacing: '-1.24px',
  },
  [DropTypography.NumberL]: {
    fontFamily: '"Roboto Mono","Roboto","Helvetica","Arial",sans-serif',
    fontSize: '48px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '67px',
    letterSpacing: '-2px',
  },
};

export const appTheme = createTheme({
  spacing: 5,
  shape: {
    borderRadius: 10,
  },
  zIndex: {
    // Just a bit less than everything in MUI
    // https://mui.com/customization/z-index/
    cookingAssistant: 950,
  },
  palette: {
    ...dropPalette,
    background: {
      ...dropPalette.background,
      paper: dropPalette.surfacePrimary,
    },
    text: {
      primary: dropPalette.textWhite.high,
      secondary: dropPalette.textWhite.medium,
      disabled: dropPalette.textWhite.low,
    },
  },
  typography: {
    ...dropTypography,
    fontWeightBold: 700,
    [DropTypography.H1Bold]: {
      ...dropTypography.h1,
      fontWeight: 'bold',
    },
    [DropTypography.H2Bold]: {
      ...dropTypography.h2,
      fontWeight: 'bold',
    },
    [DropTypography.H3Bold]: {
      ...dropTypography.h3,
      fontWeight: 'bold',
    },
    [DropTypography.H4Bold]: {
      ...dropTypography.h4,
      fontWeight: 'bold',
    },
    [DropTypography.H5Bold]: {
      ...dropTypography.h5,
      fontWeight: 'bold',
    },
    [DropTypography.H6Bold]: {
      ...dropTypography.h6,
      fontWeight: 'bold',
    },
    [DropTypography.Subtitle1Bold]: {
      ...dropTypography.subtitle1,
      fontWeight: 'bold',
    },
    [DropTypography.Subtitle2Bold]: {
      ...dropTypography.subtitle2,
      fontWeight: 'bold',
    },
    [DropTypography.Body1Bold]: {
      ...dropTypography.body1,
      fontWeight: 'bold',
    },
    [DropTypography.Body2Bold]: {
      ...dropTypography.body2,
      fontWeight: 'bold',
    },
    [DropTypography.CaptionBold]: {
      ...dropTypography.caption,
      fontWeight: 'bold',
    },
    [DropTypography.OverlineBold]: {
      ...dropTypography.overline,
      fontWeight: 'bold',
    },
  },
  components: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          height: undefined,
          backgroundColor: dropPalette.surfaceTertiary,
        },
        text: {
          borderRadius: DropBorderRadius.Pill,
          transform: 'none',
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiDialog: {
      defaultProps: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        PaperProps: {
          elevation: 0,
          sx: (theme) => ({
            backgroundColor: theme.palette.background.default,
          }),
        },
      },
    },
  },
});
