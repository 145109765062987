import { Box, Skeleton } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import {
  sxContent,
  sxMediaWrapper,
  sxWrapper,
} from 'features/favorites/RecipeCard.styles';
import { sxCompose } from 'utils/sxCompose';

export const recipeCardLoadingAriaLabel = 'Recipe loading';

export const RecipeCardSkeleton: FC = memo(function RecipeCardSkeleton() {
  return (
    <Box
      sx={sxCompose(
        {
          // Workaround for webkit bug, otherwise image placeholder is not cut off by rounded corners
          transform: 'translateZ(0)',
        },
        sxWrapper
      )}
      aria-label={recipeCardLoadingAriaLabel}
    >
      <Box sx={sxMediaWrapper}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
      <Box sx={sxContent}>
        <Skeleton
          variant="text"
          height={16}
          width={275}
          sx={{ marginTop: '7px' }}
        />
        <Skeleton
          variant="text"
          height={16}
          width={104}
          sx={{ marginTop: '14px' }}
        />
        <Skeleton
          variant="text"
          height={15}
          width={168}
          sx={{ marginTop: '15px' }}
        />
        <Skeleton
          variant="text"
          height={14}
          width={82}
          sx={{ marginTop: '17px' }}
        />
      </Box>
    </Box>
  );
});
