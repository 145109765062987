export const localTimerApplianceIdPrefix = 'local-timer-';

/**
 * For every step that has `time`, `hasApplianceControls` but no `usedAppliance`
 * we create fake "Local timer" appliance that will set up countdown and alarm
 *
 * This function generates an id for such a timer.
 */
export const getLocalTimerId = (stepId: number) =>
  `${localTimerApplianceIdPrefix}${stepId}`;
