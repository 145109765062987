import { PlayIcon } from '@dropkitchen/icons-react';
import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { ButtonColor } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { stepProgramResumeRequested } from 'features/userAppliances/requestStepProgramResume';

export const resumeButtonText = 'Resume';

export const ResumeButton: FC<ProgramControlsProps> = memo(
  function ResumeButton({ step }) {
    const dispatch = useDispatch();

    const handleClick: MouseEventHandler = (e) => {
      e.stopPropagation();
      dispatch(stepProgramResumeRequested({ step }));
    };

    return (
      <IconButton
        color={ButtonColor.Secondary}
        onClick={handleClick}
        aria-label={resumeButtonText}
      >
        <PlayIcon />
      </IconButton>
    );
  }
);
