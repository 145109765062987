import { Box, Skeleton } from '@mui/material';
import type { FC } from 'react';

import { DropColor } from 'app/theme';
import {
  sxAppDescriptionWrapper,
  sxAppDescriptionHeader,
  sxAppDescriptionIcon,
  sxAppDescriptionSecondaryText,
  sxBackground,
  sxContentWrapper,
  sxQrCode,
  sxText,
  sxTextSection,
  sxWrapper,
} from 'features/pairing/PairingPage.styles';
import { sxCompose } from 'utils/sxCompose';

export const pairingPageLoadingAriaLabel = 'Loading pairing screen';

export const PairingPageSkeleton: FC = () => {
  return (
    <Box sx={sxWrapper} aria-label={pairingPageLoadingAriaLabel}>
      <Box sx={sxBackground} />
      <Box sx={sxContentWrapper}>
        <Skeleton
          variant="rectangular"
          sx={sxCompose(sxQrCode, {
            backgroundColor: DropColor.SurfaceTertiary,
            // Some margin to fill space of manual code
            marginBottom: 8,
          })}
        />
        <Box sx={sxTextSection}>
          <Box sx={sxAppDescriptionWrapper}>
            <Skeleton
              variant="rectangular"
              sx={sxCompose(sxAppDescriptionIcon, {
                borderRadius: '12.3px',
                marginBottom: '1px',
              })}
            />
            <div>
              <Box sx={sxAppDescriptionHeader}>
                <Skeleton
                  variant="text"
                  width={180}
                  height={22}
                  sx={{ marginTop: '8px' }}
                />
              </Box>
              <Box sx={sxAppDescriptionSecondaryText}>
                <Skeleton
                  variant="text"
                  width={310}
                  height={17}
                  sx={{ marginTop: '17px' }}
                />
              </Box>
            </div>
          </Box>
          <Box sx={sxCompose(sxText, { marginTop: 12 })}>
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton
              variant="text"
              width="100%"
              height={20}
              sx={{ marginTop: '22px' }}
            />
            <Skeleton
              variant="text"
              width={137}
              height={20}
              sx={{ marginTop: '22px' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
