import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectDeviceEventsIsPaired } from 'features/device/deviceEventsSlice';
import { selectLoginIsAuthenticated } from 'features/login/loginSlice';
import { PairingPage } from 'features/pairing/PairingPage';

/**
 * Renders children if user is authenticated and <PairingPage /> otherwise
 */
export const AuthWrapper: FC = memo(function AuthWrapper({ children }) {
  const isAuthenticated = useSelector(selectLoginIsAuthenticated);
  const isPaired = useSelector(selectDeviceEventsIsPaired());
  return <>{isAuthenticated && isPaired ? children : <PairingPage />}</>;
});
