export enum DropTemperatureUnit {
  C = 'c',
  F = 'f',
  S = 's',
}

export interface DropNumericTemperature {
  value: number;
  unit: DropTemperatureUnit.C | DropTemperatureUnit.F;
}

export interface DropEnumTemperature<TTemperature extends string> {
  value: TTemperature;
  unit: DropTemperatureUnit.S;
}
