import type { SxProps, Theme } from '@mui/material';

import { DropColor, DropTypography } from 'app/theme';
import {
  recipesListHeaderHeight,
  recipeCardWidth,
  recipesListGap,
  recipesListTopMargin,
  recipesListBottomMargin,
} from 'features/favorites/constants';

export const sxWrapper: SxProps<Theme> = {
  pt: recipesListTopMargin,
  pb: recipesListBottomMargin,
  px: 4,
  minHeight: '100vh',
  '& a': {
    textDecoration: 'unset',
  },
};

export const sxHeader: SxProps<Theme> = (theme) => ({
  display: 'flex',
  height: theme.spacing(recipesListHeaderHeight),
});

export const sxAppIcon: SxProps<Theme> = {
  height: '64px',
  width: '64px',
  marginRight: 4,
};

export const sxCollectionName: SxProps<Theme> = {
  typography: DropTypography.H5Bold,
  color: DropColor.TextPrimary,
};

export const sxRecipesCount: SxProps<Theme> = {
  typography: DropTypography.Body2,
  color: DropColor.TextSecondary,
};

export const sxList: SxProps<Theme> = (theme) => ({
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: `repeat(auto-fill, ${theme.spacing(recipeCardWidth)})`,
  rowGap: recipesListGap,
  marginTop: recipesListTopMargin,
});
