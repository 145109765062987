import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import type { RootState } from 'app/store/rootReducer';
import type { ReadingCurrentSize } from 'features/recipe/make/applianceScreen/ReadingCurrent';
import {
  ReadingCurrent,
  ReadingCurrentState,
} from 'features/recipe/make/applianceScreen/ReadingCurrent';
import {
  selectUserApplianceReadingsTimeRemaining,
  selectUserApplianceState,
} from 'features/userAppliances/selectors';
import { DropApplianceState } from 'types/api/userAppliance';
import { formatSeconds } from 'utils/formatSeconds';

interface TimeReadingCurrentProps {
  applianceId: string;
  size: ReadingCurrentSize;
}

export const TimeReadingCurrent: FC<TimeReadingCurrentProps> = memo(
  function TimeReadingCurrent({ applianceId, size }) {
    const time = useSelector(
      selectUserApplianceReadingsTimeRemaining(applianceId)
    );

    const state = useSelector((rootState: RootState) => {
      const applianceState = selectUserApplianceState(applianceId)(rootState);

      switch (applianceState) {
        case DropApplianceState.Running:
          return ReadingCurrentState.Running;
        case DropApplianceState.Paused:
          return ReadingCurrentState.Paused;
        case DropApplianceState.Pending:
          return ReadingCurrentState.Pending;
        default:
          return ReadingCurrentState.Default;
      }
    });

    const value = time !== undefined ? formatSeconds(time) : undefined;

    return <ReadingCurrent size={size} state={state} value={value} />;
  }
);
