import type { FC } from 'react';
import { memo } from 'react';

import type { ButtonProps } from 'components/Button/Button';
import { Button } from 'components/Button/Button';
import { sxCompose } from 'utils/sxCompose';

export interface IconButtonProps extends Omit<ButtonProps, 'size'> {
  /**
   * Button height and width in px. Default is 70
   */
  size?: number;
}

export const IconButton: FC<IconButtonProps> = memo(function IconButton({
  children,
  size = 70,
  sx,
  ...props
}) {
  return (
    <Button
      sx={sxCompose(
        {
          height: size,
          width: size,
          flexShrink: 0,
          minHeight: 'unset',
          minWidth: 'unset',
          borderRadius: '50%',
          '& svg': {
            marginRight: 'unset',
          },
        },
        sx
      )}
      {...props}
    >
      {children}
    </Button>
  );
});
