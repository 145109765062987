import type { SxProps, Theme } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { SmartSectionWrapper } from 'features/recipe/make/step/SmartSectionWrapper';
import { SettingsIcons } from 'features/recipe/make/step/applianceControls/SettingsIcons';
import { SmartApplianceControlButtons } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropStepClient } from 'types/api/step';

export const smartTimerTitle = 'Smart Timer';
export const smartApplianceControl = 'Smart Appliance Control';

interface SmartApplianceControlProps {
  sx?: SxProps<Theme>;
  step: DropStepClient;
}

export const SmartApplianceControl: FC<SmartApplianceControlProps> = memo(
  function SmartApplianceControl({ sx, step }) {
    const usedAppliance = useSelector(selectStepUsedAppliance(step));

    if (!step.hasApplianceControls || !usedAppliance) {
      return null;
    }

    const isTimer =
      usedAppliance?.appliance.category === DropApplianceCategory.Timer;

    return (
      <SmartSectionWrapper
        {...(isTimer
          ? {
              title: smartTimerTitle,
            }
          : {
              title: smartApplianceControl,
              secondaryTitle: usedAppliance?.appliance.name,
            })}
        sx={sx}
      >
        <SettingsIcons step={step} />
        <SmartApplianceControlButtons step={step} />
      </SmartSectionWrapper>
    );
  }
);
