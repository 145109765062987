import type { DropNumericTemperature } from 'types/api/temperature';
import type { DropApplianceState } from 'types/api/userAppliance';

export enum DropUserEventType {
  Status = 'Status',
  Duration = 'Duration',
  RemainingTime = 'RemainingTime',
  TemperatureSetpoint = 'TemperatureSetpoint',
  Temperature = 'Temperature',
  Heating = 'Heating',
  Program = 'Program',
  ProgramAborted = 'ProgramAborted',
  ProgramFinished = 'ProgramFinished',
}

interface DropUserEventWrapper<
  TEvent extends DropUserEventType,
  TEventPayload
> {
  id: string;
  nativeId: string;
  stepId?: number;
  applianceId: string;
  event: {
    type: TEvent;
    timestamp: number;
  } & TEventPayload;
}

export type DropUserEventStatus = DropUserEventWrapper<
  DropUserEventType.Status,
  {
    value: DropApplianceState;
  }
>;

export type DropUserEventDuration = DropUserEventWrapper<
  DropUserEventType.Duration,
  {
    value: number;
  }
>;

export type DropUserEventRemainingTime = DropUserEventWrapper<
  DropUserEventType.RemainingTime,
  {
    value: number;
  }
>;

export type DropUserEventTemperatureSetpoint = DropUserEventWrapper<
  DropUserEventType.TemperatureSetpoint,
  {
    value: DropNumericTemperature;
  }
>;

export type DropUserEventTemperature = DropUserEventWrapper<
  DropUserEventType.Temperature,
  {
    value: DropNumericTemperature;
  }
>;

export type DropUserEventHeating = DropUserEventWrapper<
  DropUserEventType.Heating,
  {
    value: boolean;
  }
>;

export type DropUserEventProgram = DropUserEventWrapper<
  DropUserEventType.Program,
  {
    value: string;
  }
>;

export type DropUserEventProgramAborted = DropUserEventWrapper<
  DropUserEventType.ProgramAborted,
  Record<string, unknown>
>;

export type DropUserEventProgramFinished = DropUserEventWrapper<
  DropUserEventType.ProgramFinished,
  Record<string, unknown>
>;

export type UserEventsIncomingMessages =
  | DropUserEventStatus
  | DropUserEventDuration
  | DropUserEventRemainingTime
  | DropUserEventTemperatureSetpoint
  | DropUserEventTemperature
  | DropUserEventHeating
  | DropUserEventProgram
  | DropUserEventProgramAborted
  | DropUserEventProgramFinished;
