import { collectionsApiUrl, userCollectionsApiUrl } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import type { DropCollectionLean } from 'types/api/collection';
import type { DropRecipeLean } from 'types/api/recipe';
import type { DropPaginatedResponse } from 'types/api/response';

/**
 * Default is 25, we have 3 recipes per row, so let it be 24 to divide by 3
 */
export const collectionRecipesPerPage = 24;

export interface ApiGetCollectionRecipesRequest {
  collectionId: number;
  page: number;
}

export const apiGetCollectionRecipes =
  ({
    collectionId,
    page,
  }: ApiGetCollectionRecipesRequest): ApiRequestFn<
    DropPaginatedResponse<DropRecipeLean>
  > =>
  (apiContext) =>
    fetchJson<DropPaginatedResponse<DropRecipeLean>>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Lean,
      httpMethod: HttpMethod.Get,
      url: `${collectionsApiUrl}/${collectionId}/recipes?page=${page}&per_page=${collectionRecipesPerPage}`,
    });

export const apiGetUserCollections =
  (): ApiRequestFn<DropPaginatedResponse<DropCollectionLean>> => (apiContext) =>
    fetchJson<DropPaginatedResponse<DropCollectionLean>>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Lean,
      httpMethod: HttpMethod.Get,
      url: `${userCollectionsApiUrl}?per_page=100`,
    });
