import type { SvgIconTypeMap, SxProps, Theme } from '@mui/material';
import { Box, useTheme, IconButton, SvgIcon } from '@mui/material';
import type { DefaultComponentProps } from '@mui/material/OverridableComponent';
import type { FC } from 'react';
import { useEffect, useRef, useState, memo } from 'react';
import { windowScrollTo } from 'seamless-scroll-polyfill';

import { DropBorderRadius, DropColor } from 'app/theme';
import {
  recipesListRowHeight,
  recipeListTopSectionHeight,
  recipeListScrollSnapOffset,
} from 'features/favorites/constants';
import { getWindowScrollY } from 'utils/getWindowScrollY';
import { sxCompose } from 'utils/sxCompose';

export const scrollUpAriaLabel = 'Scroll up';
export const scrollDownAriaLabel = 'Scroll down';

const UpArrowIcon: FC<DefaultComponentProps<SvgIconTypeMap>> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M16.101 22c.776 0 1.399-.586 1.399-1.342 0-.372-.165-.699-.423-.958L9.837 12l7.24-7.7c.258-.26.423-.598.423-.958C17.5 2.586 16.877 2 16.101 2c-.387 0-.717.135-.975.383l-8.144 8.58c-.33.304-.482.642-.482 1.037 0 .395.153.733.482 1.026l8.144 8.59c.258.249.588.384.975.384z"
      transform="scale(1, -1) rotate(-90.000000)"
    />
  </SvgIcon>
);

const DownArrowIcon: FC<DefaultComponentProps<SvgIconTypeMap>> = (props) => (
  <UpArrowIcon
    {...props}
    sx={{
      transform: 'rotate(180deg)',
    }}
  />
);

const scrollToRow = (row: number, theme: Theme) => {
  windowScrollTo(window, {
    top:
      row === 0
        ? 0
        : parseInt(
            theme.spacing(
              row * recipesListRowHeight +
                recipeListTopSectionHeight -
                // We want to show just a bit of cards row above to give user a hint
                recipeListScrollSnapOffset
            )
          ),
    behavior: 'smooth',
  });
};

const sxButton: SxProps<Theme> = {
  color: DropColor.SecondaryContrastText,
  transition: 'color 0.5s',
  display: 'block',
  padding: '14px',
  '& svg': {
    height: '32px',
    width: '32px',
  },
  '&[disabled]': {
    color: DropColor.Overlay,
  },
};

export const Scroller: FC = memo(function Scroller() {
  const theme = useTheme();
  const windowHeightRef = useRef<number>(0);
  const scrollYRef = useRef<number>(0);
  const contentHeightRef = useRef<number>(0);
  const [hasMoreAbove, setHasMoreAbove] = useState(false);
  const [hasMoreBelow, setHasMoreBelow] = useState(false);

  const updateAvailability = () => {
    if (!contentHeightRef.current || !windowHeightRef.current) {
      return;
    }
    setHasMoreAbove(scrollYRef.current > 0);
    setHasMoreBelow(
      scrollYRef.current + windowHeightRef.current < contentHeightRef.current
    );
  };

  const getCurrentVisibleRow = () =>
    Math.floor(
      (scrollYRef.current +
        windowHeightRef.current -
        parseInt(theme.spacing(recipeListTopSectionHeight))) /
        parseInt(theme.spacing(recipesListRowHeight))
    ) - 1;

  useEffect(() => {
    const handler = () => {
      scrollYRef.current = getWindowScrollY();
      updateAvailability();
    };

    scrollYRef.current = getWindowScrollY();

    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      contentHeightRef.current = entries[0].contentRect.height;
      updateAvailability();
    });

    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    windowHeightRef.current = window.innerHeight;

    const handler = () => {
      windowHeightRef.current = window.innerHeight;
      updateAvailability();
    };

    // It will never happen, just for testing in browser
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  const handleUpClick = () => {
    scrollToRow(getCurrentVisibleRow() - 1, theme);
  };

  const handleDownClick = () => {
    scrollToRow(getCurrentVisibleRow() + 1, theme);
  };

  if (!hasMoreAbove && !hasMoreBelow) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: DropColor.SecondaryMain,
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        borderRadius: DropBorderRadius.Pill,
        overflow: 'hidden',
      }}
    >
      <IconButton
        aria-label={scrollUpAriaLabel}
        sx={sxCompose(sxButton, {
          marginBottom: 4,
        })}
        disabled={!hasMoreAbove}
        onClick={handleUpClick}
        size="large"
      >
        <UpArrowIcon />
      </IconButton>
      <IconButton
        aria-label={scrollDownAriaLabel}
        sx={sxButton}
        disabled={!hasMoreBelow}
        onClick={handleDownClick}
      >
        <DownArrowIcon />
      </IconButton>
    </Box>
  );
});
