import { put } from 'redux-saga/effects';

import { getLocalTimerId } from 'features/userAppliances/localTimer/getLocalTimerId';
import { userApplianceStateSet } from 'features/userAppliances/userAppliancesSlice';
import type { DropStepClient } from 'types/api/step';
import { DropApplianceState } from 'types/api/userAppliance';

export function* requestLocalTimerPause({ step }: { step: DropStepClient }) {
  const id = getLocalTimerId(step.id);

  yield put(
    userApplianceStateSet({
      id,
      applianceState: DropApplianceState.Paused,
      stepId: step.id,
    })
  );
}
