import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Button, ButtonColor } from 'components/Button/Button';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { stepProgramExtendRequested } from 'features/userAppliances/requestStepProgramExtend';

export const extendButtonText = 'Extend';

export const ExtendButton: FC<ProgramControlsProps> = memo(
  function ExtendButton({ step }) {
    const dispatch = useDispatch();

    const handleClick: MouseEventHandler = (e) => {
      e.stopPropagation();
      dispatch(stepProgramExtendRequested({ step }));
    };

    return (
      <Button color={ButtonColor.Secondary} onClick={handleClick}>
        {extendButtonText}
      </Button>
    );
  }
);
