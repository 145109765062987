import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReadingTarget } from 'features/recipe/make/applianceScreen/ReadingTarget';
import { selectUserApplianceReadingsTemperatureTarget } from 'features/userAppliances/selectors';

interface TemperatureReadingTargetProps {
  applianceId: string;
}

export const TemperatureReadingTarget: FC<TemperatureReadingTargetProps> = memo(
  function TemperatureReadingTarget({ applianceId }) {
    const temperature = useSelector(
      selectUserApplianceReadingsTemperatureTarget(applianceId)
    );

    const value = temperature
      ? `${temperature.value}°${temperature.unit.toUpperCase()}`
      : undefined;

    return <ReadingTarget value={value} />;
  }
);
