import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useRef, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';

import { DropBorderRadius, DropColor, DropZIndex } from 'app/theme';
import { favoritesRefreshRequested } from 'features/favorites/favoritesSlice';
import { getWindowScrollY } from 'utils/getWindowScrollY';

export const pullToRefreshAriaLabel = 'Pull down to refresh';

export const touchDeltaLogMultiplier = 30;
const pullToRefreshSize = 50;
const pullToRefreshTreshold = pullToRefreshSize * 3;
const arrowRotationSpeed = 5;
const arrowRotationInitial = 30;
export const overscrollDeltaCssVarName = '--overscrollDelta';
export const overThresholdClassName = 'overThreshold';

function useBlockNativeOverscroll() {
  useEffect(() => {
    document.body.style.setProperty('touch-action', 'pan-y');

    return () => {
      document.body.style.removeProperty('touch-action');
    };
  }, []);
}

/**
 * TM6 WPE browser acts weird with touch events, this component tries to address all differences
 * and tries to work in Chrome at the same time
 *
 * Also TM6 hardware performance is far from perfect, so it avoids rerendering
 * and does everything manually or via CSS transitions when possible
 */
export const PullToRefresh: FC = memo(function PullToRefresh() {
  const dispatch = useDispatch();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const overscrollStartY = useRef<number>();
  const overscrollDelta = useRef(0);
  const isPullToRefreshHappening = useRef(false);

  const pendingAnimationFrame = useRef<number>();

  const setOverscrollDelta = useCallback((delta: number) => {
    overscrollDelta.current = delta;
    if (getWindowScrollY() === 0 && delta > 0) {
      isPullToRefreshHappening.current = true;
    }

    /* istanbul ignore next */
    if (pendingAnimationFrame.current) {
      cancelAnimationFrame(pendingAnimationFrame.current);
    }

    pendingAnimationFrame.current = requestAnimationFrame(() => {
      /* istanbul ignore next */
      if (!wrapperRef.current) {
        return;
      }

      wrapperRef.current.style.setProperty(
        overscrollDeltaCssVarName,
        delta.toFixed()
      );

      if (delta > pullToRefreshTreshold) {
        wrapperRef.current.classList.add(overThresholdClassName);
      } else {
        wrapperRef.current.classList.remove(overThresholdClassName);
      }
    });

    pendingAnimationFrame.current = undefined;
  }, []);

  // Touch start
  useEffect(() => {
    const handler = (e: TouchEvent) => {
      if (getWindowScrollY() > 0) {
        return;
      }

      // Remember where touch started
      const y = e.touches[0].clientY;
      overscrollStartY.current = y;
    };

    document.body.addEventListener('touchstart', handler);
    return () => {
      document.body.removeEventListener('touchstart', handler);
    };
  }, [dispatch]);

  // Touch move
  useEffect(() => {
    const handler = (e: TouchEvent) => {
      if (!overscrollStartY.current) {
        return;
      }

      // If user is scrolling down - disable pull to refresh
      if (getWindowScrollY() > 0) {
        overscrollStartY.current = undefined;
        return;
      }

      // Figure out, how far user dragged finger
      const y = e.touches[0].clientY;

      // Using log to make movement slower at the end to emulate tension
      const delta =
        Math.log(Math.max(y - overscrollStartY.current, 1)) *
        touchDeltaLogMultiplier;
      setOverscrollDelta(delta);

      // Block native scroll or WPE in TM6 will abort touch
      if (isPullToRefreshHappening.current) {
        e.preventDefault();
        return false;
      }
    };

    document.body.addEventListener('touchmove', handler, {
      passive: false,
      capture: true,
    });
    return () => {
      document.body.removeEventListener('touchmove', handler, {
        capture: true,
      });
    };
  }, [setOverscrollDelta]);

  // Touch end
  useEffect(() => {
    const handler = (e: TouchEvent) => {
      if (!isPullToRefreshHappening.current) {
        return;
      }

      if (overscrollDelta.current > pullToRefreshTreshold) {
        dispatch(favoritesRefreshRequested());
      }

      const disappearTransitionDuration = 200;

      overscrollStartY.current = undefined;
      isPullToRefreshHappening.current = false;

      wrapperRef.current?.style.setProperty(
        'transition',
        `transform ${disappearTransitionDuration}ms`
      );

      setOverscrollDelta(0);

      /* istanbul ignore next */
      setTimeout(() => {
        wrapperRef.current?.style.setProperty('transition', null);
      }, disappearTransitionDuration);

      // WPE in TM6 emits click on touchend, it performs navigation when user
      // started and finished pull to refresh on card, aborting it if pull to refresh was happening
      e.preventDefault();
    };

    document.body.addEventListener('touchend', handler);
    return () => {
      document.body.removeEventListener('touchend', handler);
    };
  }, [dispatch, setOverscrollDelta]);

  useBlockNativeOverscroll();

  return (
    <Box
      ref={wrapperRef}
      aria-label={pullToRefreshAriaLabel}
      sx={{
        position: 'fixed',
        top: 0,
        left: '50%',
        height: pullToRefreshSize,
        width: pullToRefreshSize,
        boxSizing: 'border-box',
        padding: 2,
        borderRadius: DropBorderRadius.Pill,
        transform: `translateX(-50%) translateY(calc(var(${overscrollDeltaCssVarName}, 0) * 1px - 200%))`,
        zIndex: DropZIndex.Tooltip,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: DropColor.SecondaryMain,
        '& svg': {
          opacity: 0.3,
        },
        [`&.${overThresholdClassName} svg`]: {
          opacity: 1,
        },
      }}
    >
      <RefreshIcon
        sx={{
          color: DropColor.SecondaryContrastText,
          width: '100%',
          height: '100%',
          transition: 'opacity 0.5s',
          transform: `rotate(calc(var(${overscrollDeltaCssVarName}) * ${arrowRotationSpeed}deg - ${arrowRotationInitial}deg))`,
        }}
      />
    </Box>
  );
});
