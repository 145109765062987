import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { StartButton } from 'features/recipe/make/step/applianceControls/StartButton';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';

export const ProgramReadyControls: FC<ProgramControlsProps> = memo(
  function ProgramReadyControls({ step }) {
    const usedApplianceCategory = useSelector(selectStepUsedAppliance(step))
      ?.appliance.category;

    switch (usedApplianceCategory) {
      case DropApplianceCategory.Timer:
      case DropApplianceCategory.Robot:
      case DropApplianceCategory.Oven:
        return <StartButton step={step} />;
      default:
        return null;
    }
  }
);
