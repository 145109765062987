import {
  ApplianceSettingSpeedThermomixIcon,
  ApplianceSettingTemperatureThermomixIcon,
  ApplianceSettingTimeThermomixIcon,
} from '@dropkitchen/icons-react';
import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';

import { DropColor, DropTypography } from 'app/theme';
import type { DropStepClient } from 'types/api/step';
import { DurationFormat, formatDuration } from 'utils/formatDuration';
import { sxCompose } from 'utils/sxCompose';

export const timeIconTitle = 'Time';
export const temperatureIconTitle = 'Temperature';
export const settingIconTitle = 'Setting';

const sxItem = (isSingleItem: boolean) =>
  sxCompose(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexBasis: '100%',
    },
    isSingleItem && {
      flexDirection: 'row',
      alignItems: 'unset',
    }
  );

const sxIcon: SxProps<Theme> = {
  height: 32,
  width: 32,
};

const sxText = (isSingleItem: boolean) =>
  sxCompose(
    {
      typography: DropTypography.Caption,
    },
    isSingleItem && {
      typography: DropTypography.Body2,
      marginLeft: 2,
    }
  );

interface SettingsIconsProps {
  step: DropStepClient;
}

export const SettingsIcons: FC<SettingsIconsProps> = ({ step }) => {
  const itemsCount =
    Number(!!step.time) + Number(!!step.temperature) + Number(!!step.setting);

  if (itemsCount === 0) {
    return null;
  }

  const isSingleItem = itemsCount === 1;

  return (
    <Box
      sx={{
        color: DropColor.TextPrimary,
        marginBottom: 4,
        display: 'flex',
      }}
    >
      {!!step.time && (
        <Box sx={sxItem(isSingleItem)}>
          <ApplianceSettingTimeThermomixIcon
            sx={sxIcon}
            color="inherit"
            titleAccess={timeIconTitle}
          />
          <Box sx={sxText(isSingleItem)}>
            {formatDuration(step.time, DurationFormat.Hms)}
          </Box>
        </Box>
      )}
      {!!step.temperature && (
        <Box sx={sxItem(isSingleItem)}>
          <ApplianceSettingTemperatureThermomixIcon
            sx={sxIcon}
            color="inherit"
            titleAccess={temperatureIconTitle}
          />
          <Box sx={sxText(isSingleItem)}>{step.temperature.name}</Box>
        </Box>
      )}
      {!!step.setting && (
        <Box sx={sxItem(isSingleItem)}>
          <ApplianceSettingSpeedThermomixIcon
            sx={sxIcon}
            color="inherit"
            titleAccess={settingIconTitle}
          />
          <Box sx={sxText(isSingleItem)}>{step.setting}</Box>
        </Box>
      )}
    </Box>
  );
};
