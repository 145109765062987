import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { CancelButton } from 'features/recipe/make/step/applianceControls/CancelButton';
import { ResumeButton } from 'features/recipe/make/step/applianceControls/ResumeButton';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';

export const ProgramPausedControls: FC<ProgramControlsProps> = memo(
  function ProgramPausedControls({ step }) {
    const usedApplianceCategory = useSelector(selectStepUsedAppliance(step))
      ?.appliance.category;

    switch (usedApplianceCategory) {
      case DropApplianceCategory.Timer:
        // case DropApplianceCategory.Robot:
        return (
          <>
            <CancelButton step={step} />
            <ResumeButton step={step} />
          </>
        );
      default:
        return null;
    }
  }
);
