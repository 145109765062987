import { List } from '@mui/material';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ApplianceItem } from 'features/recipe/make/applianceToolbar/ApplianceItem';
import {
  selectUserAppliancesLocalTimers,
  selectUserAppliancesUsedInRecipe,
} from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';

export const ApplianceToolbar: FC = memo(function ApplianceToolbar() {
  const usedAppliances = useSelector(selectUserAppliancesUsedInRecipe);
  const localTimers = useSelector(selectUserAppliancesLocalTimers);

  const supportedAppliances = useMemo(
    () =>
      usedAppliances
        .filter(
          (userAppliance) =>
            // Only ovens are supported for now
            userAppliance.appliance.category === DropApplianceCategory.Oven
        )
        .concat(localTimers),
    [usedAppliances, localTimers]
  );

  return (
    <List disablePadding>
      {supportedAppliances.map(({ id }) => (
        <ApplianceItem key={id} userApplianceId={id} />
      ))}
    </List>
  );
});
