import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';

import type { RootState } from 'app/store/rootReducer';

interface SnackbarData {
  id: string;
  text: string;
}

interface SnackbarState {
  queue: SnackbarData[];
}

export const initialState: SnackbarState = {
  queue: [],
};

const snackbarSlice = createSlice({
  name: 'snackbarSlice',
  initialState,
  reducers: {
    snackbarEnqueued(
      state,
      { payload }: PayloadAction<Omit<SnackbarData, 'id'>>
    ) {
      state.queue.push({
        ...payload,
        id: uniqueId(),
      });
    },
    snackbarDismissed(state) {
      state.queue.splice(0, 1);
    },
  },
});

export const {
  reducer: snackbarReducer,
  actions: { snackbarEnqueued, snackbarDismissed },
} = snackbarSlice;

export const selectSnackbarCurrent = (
  state: RootState
): SnackbarData | undefined => state.snackbar.queue[0];
