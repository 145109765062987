export const recipesListTopMargin = 6;

export const recipesListBottomMargin = 28;

export const recipesListHeaderHeight = 14;

export const recipesListGap = 4;

export const recipeCardHeight = 72;

export const recipeCardWidth = 63;

export const recipeCardMediaHeight = 43;

export const recipesListRowHeight = recipeCardHeight + recipesListGap;

export const recipeListTopSectionHeight =
  recipesListHeaderHeight + recipesListTopMargin * 2;

/**
 * Offset of partially visible recipes row above scroll snap point
 * to give user a hint that there is more content there
 */
export const recipeListScrollSnapOffset = recipesListGap * 2;

export const tm6CollectionName = 'TM6 Recipes';
