export enum DurationFormat {
  Hm = 'hm',
  Hms = 'hms',
  HrMins = 'hrmins',
}

const hoursStrings: Record<DurationFormat, string> = {
  [DurationFormat.Hm]: 'h',
  [DurationFormat.Hms]: 'h',
  [DurationFormat.HrMins]: 'hr',
};

const minutesStrings: Record<DurationFormat, string> = {
  [DurationFormat.Hm]: 'm',
  [DurationFormat.Hms]: 'm',
  [DurationFormat.HrMins]: 'mins',
};

const secondsStrings: Partial<Record<DurationFormat, string>> = {
  [DurationFormat.Hms]: 's',
};

export const formatDuration = (
  seconds: number,
  format: DurationFormat
): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsRemaining = seconds % 60;

  const hoursString = hoursStrings[format];
  const minutesString = minutesStrings[format];
  const secondsString = secondsStrings[format];

  const parts: string[] = [];

  if (hours > 0) {
    parts.push(`${hours}${hoursString}`);
  }

  if (minutes > 0 || hours > 0 || (!secondsString && !parts.length)) {
    parts.push(`${minutes}${minutesString}`);
  }

  if (secondsString && (secondsRemaining > 0 || !parts.length)) {
    parts.push(`${secondsRemaining}${secondsString}`);
  }

  return parts.join(' ');
};
