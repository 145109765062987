import type { DropEntity } from 'types/api/entity';
import type { DropMedia } from 'types/api/media';

export enum DropApplianceCategory {
  Oven = 'Oven',
  Robot = 'Robot',
  Timer = 'Timer',
}

export interface DropAppliance extends DropEntity<number> {
  image: DropMedia | null;
  name: string;
  category: DropApplianceCategory | null;
}

export interface DropAttachment extends DropEntity<number> {
  image: DropMedia;
  name: string;
}
