import { createAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiRequestFnResponseType } from 'api/types';
import { apiPostUserAppliancesStop } from 'api/userAppliances';
import {
  PendingType,
  runSagaWithPendingState,
} from 'features/pending/pendingSlice';
import { snackbarEnqueued } from 'features/snackbar/snackbarSlice';
import { requestLocalTimerCancel } from 'features/userAppliances/localTimer/requestLocalTimerCancel';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropStepClient } from 'types/api/step';
import { getIdFromUri } from 'utils/getIdFromUri';

export const stepCancelErrorText = 'Step cancellation error';

export const stepProgramCancelRequested = createAction<{
  step: DropStepClient;
}>('userAppliancesSlice/stepProgramCancelRequested');

export function* requestStepProgramCancel({
  payload: { step },
}: ReturnType<typeof stepProgramCancelRequested>) {
  const usedAppliance: ReturnType<ReturnType<typeof selectStepUsedAppliance>> =
    yield select(selectStepUsedAppliance(step));
  const usedApplianceCategory = usedAppliance?.appliance.category;

  switch (usedApplianceCategory) {
    case DropApplianceCategory.Timer: {
      yield call(requestLocalTimerCancel, { step });
      break;
    }
    case DropApplianceCategory.Oven:
      yield call(requestRecipeStepCancel, { step });
      break;
    default:
      break;
  }
}

export function* stepProgramCancelRequestedWatcher() {
  yield takeEvery(stepProgramCancelRequested, requestStepProgramCancel);
}

export const apiPostUserAppliancesStopSaga = createRequestApiSaga(
  apiPostUserAppliancesStop,
  'Stopping user appliance program'
);

interface RequestRecipeStepCancelArgs {
  step: DropStepClient;
}

export function* requestRecipeStepCancel({
  step,
}: RequestRecipeStepCancelArgs): SagaIterator<void> {
  function* request(): SagaIterator {
    if (!step?.usedAppliance?.uri) {
      return;
    }
    const response: ApiRequestFnResponseType<typeof apiPostUserAppliancesStop> =
      yield call(apiPostUserAppliancesStopSaga, {
        userApplianceId: getIdFromUri(step.usedAppliance.uri),
      });

    if (!response.ok) {
      yield put(
        snackbarEnqueued({
          text: stepCancelErrorText,
        })
      );
    }
  }

  yield call(runSagaWithPendingState, request, {
    pendingType: PendingType.StepCancel,
    pendingId: step.id,
  });
}
