import type { RouteProps } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { RecipeMakePage } from 'features/recipe/make/RecipeMakePage';
import { RecipeViewPage } from 'features/recipe/view/RecipeViewPage';

import { FavoritesPage } from '../features/favorites/FavoritesPage';

export const favoritesRoute: RouteProps = {
  path: '/favorites',
  children: <FavoritesPage />,
};

export const recipeViewRoute: RouteProps = {
  path: '/recipes/:recipeId',
  children: <RecipeViewPage />,
};

export const recipeMakeRoute: RouteProps = {
  path: '/recipes/:recipeId/make',
  children: <RecipeMakePage />,
};

const catchAllRedirect: RouteProps = {
  path: '*',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  children: <Navigate to={favoritesRoute.path!} />,
};

export const routes: RouteProps[] = [
  favoritesRoute,
  recipeViewRoute,
  recipeMakeRoute,
  catchAllRedirect,
];
