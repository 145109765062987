import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';

import { DropBorderRadius, DropColor, DropTypography } from 'app/theme';
import { Ingredient } from 'features/recipe/make/step/Ingredient';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import {
  sxTitleWrapper,
  sxTitle,
} from 'features/recipe/view/Ingredients.styles';
import { Servings } from 'features/recipe/view/Servings';

export const ingredientsTitle = 'Ingredients';

export const Ingredients: FC = memo(function Ingredients() {
  const recipe = useSelector(selectRecipe);

  if (!recipe) {
    return null;
  }

  return (
    <>
      <Box sx={sxTitleWrapper}>
        <Typography component="h2" sx={sxTitle}>
          {ingredientsTitle}
        </Typography>
        <Servings />
      </Box>
      {recipe.ingredientSections.map((section, i) => (
        <Fragment key={i}>
          <Typography
            component="h3"
            sx={{
              typography: DropTypography.Subtitle1Bold,
              color: DropColor.TextSecondary,
              my: 4,
              width: '100%',
            }}
          >
            {section.name}
          </Typography>
          <Box
            sx={{
              columns: 2,
              columnGap: 4,
            }}
          >
            {section.ingredients.map((ingredient, j) => (
              <Box
                key={j}
                sx={{
                  display: 'inline-block',
                  backgroundColor: DropColor.SurfacePrimary,
                  borderRadius: DropBorderRadius.Container,
                  width: '100%',
                  py: 3,
                  px: 4,
                  mb: 4,
                }}
              >
                <Ingredient ingredient={ingredient} />
              </Box>
            ))}
          </Box>
        </Fragment>
      ))}
    </>
  );
});
