import type { SagaIterator } from '@redux-saga/core';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import type { RootState } from 'app/store/rootReducer';

// This slice keeps pending state for long running operations

/**
 * Define every possible prefix here to avoid naming collisions
 */
export enum PendingType {
  StepStart = 'stepStart',
  StepCancel = 'stepCancel',
  Weight = 'weight',
}

export interface PendingKeyArgs {
  pendingType: PendingType;
  /**
   * Some id of long running process, unique for given `pendingType`
   */
  pendingId: string | number;
}

type PendingKey = `${PendingType}-${string | number}`;

const getPendingKey = ({
  pendingType,
  pendingId,
}: PendingKeyArgs): PendingKey => `${pendingType}-${pendingId}`;

interface PendingState {
  states: Partial<Record<PendingKey, boolean>>;
}

export const initialState: PendingState = {
  states: {},
};

const pendingSlice = createSlice({
  name: 'pendingSlice',
  initialState,
  reducers: {
    pendingUpdated(
      state,
      {
        payload: { pendingKeyArgs, isPending },
      }: PayloadAction<{
        pendingKeyArgs: PendingKeyArgs;
        isPending: boolean;
      }>
    ) {
      const pendingKey = getPendingKey(pendingKeyArgs);
      if (isPending) {
        state.states[pendingKey] = true;
      } else {
        delete state.states[pendingKey];
      }
    },
  },
});

export const {
  reducer: pendingReducer,
  actions: { pendingUpdated },
} = pendingSlice;

export const selectPending =
  (pendingKeyArgs: PendingKeyArgs) =>
  (state: RootState): boolean =>
    !!state.pending.states[getPendingKey(pendingKeyArgs)];

/**
 * Setting pending state for saga, runs it and resetting pending state\
 * Pending state can be selected via {@link selectPending}
 */
export function* runSagaWithPendingState(
  saga: () => SagaIterator,
  pendingKeyArgs: PendingKeyArgs
): SagaIterator {
  yield put(pendingUpdated({ pendingKeyArgs, isPending: true }));
  try {
    yield call(saga);
  } finally {
    yield put(pendingUpdated({ pendingKeyArgs, isPending: false }));
  }
}
