import type { Reducer } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import type { RouterState } from 'redux-first-history';

import { debugReducer } from 'features/debug/debugSlice';
import { deviceEventsReducer } from 'features/device/deviceEventsSlice';
import { favoritesReducer } from 'features/favorites/favoritesSlice';
import { loginReducer } from 'features/login/loginSlice';
import { pairingReducer } from 'features/pairing/pairingSlice';
import { pendingReducer } from 'features/pending/pendingSlice';
import { recipeReducer } from 'features/recipe/recipeSlice/slice';
import { snackbarReducer } from 'features/snackbar/snackbarSlice';
import { userAppliancesReducer } from 'features/userAppliances/userAppliancesSlice';
import { userEventsReducer } from 'features/userEvents/userEventsSlice';

export const createRootReducer = (routerReducer: Reducer<RouterState>) =>
  combineReducers({
    router: routerReducer,
    login: loginReducer,
    recipe: recipeReducer,
    favorites: favoritesReducer,
    userAppliances: userAppliancesReducer,
    pairing: pairingReducer,
    userEvents: userEventsReducer,
    deviceEvents: deviceEventsReducer,
    pending: pendingReducer,
    snackbar: snackbarReducer,
    debug: debugReducer,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
