import * as Sentry from '@sentry/react';
import camelcaseKeys from 'camelcase-keys';

import type {
  ApiResponseRequestVariant,
  ApiContext,
  ErrorResponse,
  ErrorResponseDetails,
  ErrorPayload,
  ApiResponse,
} from 'api/types';
import { HttpMethod } from 'api/types';
import { Locale } from 'types/locale';

export const acceptHeader = 'application/vnd.drop.v4';
export const defaultLocale = Locale.EnUS;

const tryParseJson = <T>(responseText: string): T | undefined => {
  try {
    return JSON.parse(responseText);
  } catch {
    return undefined;
  }
};

type MergeDataFromHeadersMap<T> = {
  [propertyName in keyof T]: string;
};

export interface FetchJsonParams<T> {
  locale?: Locale;
  url: string;
  body?: URLSearchParams | unknown;
  httpMethod?: HttpMethod;
  responseVariant?: ApiResponseRequestVariant;
  mergeDataFromHeadersMap?: MergeDataFromHeadersMap<T>;
  apiContext: ApiContext;
  signal?: AbortSignal;
  excludeCamelCase?: (string | RegExp)[];
}

/**
 * Sends HTTP request with `Accept: application/json`
 *
 * - if `body` is not passed - sends GET HTTP
 * - if `body` is `URLSearchParams` - sends POST with `Content-Type: application/x-www-form-urlencoded`
 * - if `body` is `FormData` - sends POST with `Content-Type: multipart/form-data`
 * - otherwise sends POST as `JSON.stringify(body)` with `Content-Type: application/json`
 */
export const fetchJson = async <TResponse>({
  locale,
  url,
  body,
  httpMethod,
  responseVariant,
  mergeDataFromHeadersMap,
  apiContext,
  signal,
  excludeCamelCase,
}: FetchJsonParams<TResponse>): Promise<ApiResponse<TResponse>> => {
  let response: Response | undefined;
  const method = httpMethod || (body ? HttpMethod.Post : HttpMethod.Get);
  const requestContentType = getContentType(body);

  const handleError = (
    errorDetails: ErrorResponseDetails,
    responseText?: string
  ): ErrorResponse => {
    Sentry.captureException(new Error(`HTTP request error ${url}`), {
      extra: {
        errorDetails,
        url,
        method,
        responseStatusText: response?.statusText,
        responseStatus: response?.status,
        responseText,
      },
    });
    return {
      ...errorDetails,
      ok: false,
    };
  };

  try {
    response = await fetch(url, {
      method,
      body: getBody(body),
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: responseVariant
          ? `${acceptHeader}.${responseVariant}+json`
          : 'application/json',
        'Accept-Language': locale || defaultLocale,
        ...(apiContext.authData && {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${apiContext.authData.token}`,
        }),
        ...(requestContentType && {
          'Content-Type': requestContentType,
        }),
      },
      signal,
    });
  } catch (e: unknown) {
    return handleError({
      details: {
        message: String(e),
      },
    });
  }

  const responseText = await response.text();

  if (!response.ok) {
    const details = getErrorDetails(responseText, response);
    return handleError(
      {
        httpStatus: response.status,
        details,
      },
      responseText
    );
  }

  const responseJson = tryParseJson<TResponse>(responseText);
  let data = responseJson
    ? camelcaseKeys(responseJson, {
        deep: true,
        exclude: excludeCamelCase,
      })
    : ({} as TResponse);
  if (mergeDataFromHeadersMap) {
    data = {
      ...data,
      ...getDataFromHeaders(response.headers, mergeDataFromHeadersMap),
    };
  }

  return {
    data,
    ok: true,
  };
};

function getErrorDetails(
  responseText: string,
  response: Response
): ErrorPayload {
  const responseJson = tryParseJson<ErrorPayload>(responseText);

  if (isPlatformError(responseJson)) {
    return responseJson;
  }

  return {
    message: responseText,
    code: response.status,
    name: response.statusText,
  };
}

function isPlatformError(
  error: ErrorPayload | undefined
): error is ErrorPayload {
  return !!(error as ErrorPayload)?.message;
}

function getBody(body: unknown): string | URLSearchParams | FormData {
  return body instanceof URLSearchParams || body instanceof FormData
    ? body
    : JSON.stringify(body);
}

function getDataFromHeaders<T extends { [key: string]: unknown }>(
  headers: Headers,
  mergeDataFromHeadersMap: MergeDataFromHeadersMap<T>
): { [key in keyof T]: string | null } {
  const data = {} as { [key in keyof T]: string | null };
  for (const key of Object.keys(mergeDataFromHeadersMap)) {
    data[key as keyof T] = headers.get(mergeDataFromHeadersMap[key]);
  }
  return data;
}

function getContentType(body: unknown): string | undefined {
  if (
    body &&
    !(body instanceof URLSearchParams) &&
    !(body instanceof FormData)
  ) {
    return 'application/json';
  }
  return undefined;
}
