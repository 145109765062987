import { createAction } from '@reduxjs/toolkit';
import { call, select, takeEvery } from 'redux-saga/effects';

import { requestLocalTimerPause } from 'features/userAppliances/localTimer/requestLocalTimerPause';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropStepClient } from 'types/api/step';

export const stepProgramPauseRequested = createAction<{
  step: DropStepClient;
}>('userAppliancesSlice/stepProgramPauseRequested');

export function* requestStepProgramPause({
  payload: { step },
}: ReturnType<typeof stepProgramPauseRequested>) {
  const usedAppliance: ReturnType<ReturnType<typeof selectStepUsedAppliance>> =
    yield select(selectStepUsedAppliance(step));
  const usedApplianceCategory = usedAppliance?.appliance.category;

  switch (usedApplianceCategory) {
    case DropApplianceCategory.Timer: {
      yield call(requestLocalTimerPause, { step });
      break;
    }

    default:
      break;
  }
}

export function* stepProgramPauseRequestedWatcher() {
  yield takeEvery(stepProgramPauseRequested, requestStepProgramPause);
}
