import type { AppAuthData } from 'types/auth';
import type { PromiseReturnType } from 'types/promiseReturnType';

export interface LocationResponse {
  location: string;
}

export enum HttpMethod {
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum ApiResponseRequestVariant {
  Client = 'client',
  Lean = 'lean',
  Default = 'default',
  Web = 'web',
  Full = 'full',
  I18n = 'i18n',
}

export interface SuccessResponse<T> {
  data: T;
  ok: true;
}

export type AcknowledgedSuccessResponse = SuccessResponse<{
  acknowledged: true;
}>;

export enum ApiErrorCode {
  ApplianceCantPerformOperation = 908,
}

export interface ErrorPayload {
  code?: ApiErrorCode;
  name?: string;
  message: string;
}

export type ApiResponse<TResponse> = SuccessResponse<TResponse> | ErrorResponse;

export interface ErrorResponseDetails {
  details: ErrorPayload;
  httpStatus?: number;
}

export interface ErrorResponse extends ErrorResponseDetails {
  ok: false;
}

export interface ApiContext {
  authData: AppAuthData | null;
}

export type ApiRequestFn<TResponse> = (
  apiContext: ApiContext
) => Promise<ApiResponse<TResponse>>;

export type ApiRequestFnResponseType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TApiRequestFn extends (args?: any) => ApiRequestFn<any>
> = PromiseReturnType<ReturnType<TApiRequestFn>>;
