import { ApplianceSettingTimeThermomixIcon } from '@dropkitchen/icons-react';
import type { FC } from 'react';
import { memo } from 'react';

import { Reading } from 'features/recipe/make/applianceScreen/Reading';
import { ReadingCurrentSize } from 'features/recipe/make/applianceScreen/ReadingCurrent';
import { TimeReadingCurrent } from 'features/recipe/make/applianceScreen/TimeReadingCurrent';
import { TimeReadingTarget } from 'features/recipe/make/applianceScreen/TimeReadingTarget';

export const ariaLabelTimeIcon = 'Time' as const;

interface TimeReadingsProps {
  applianceId: string;
}

export const TimeReading: FC<TimeReadingsProps> = memo(function TimeReadings({
  applianceId,
}) {
  return (
    <Reading
      iconElement={
        <ApplianceSettingTimeThermomixIcon titleAccess={ariaLabelTimeIcon} />
      }
      readingCurrentElement={
        <TimeReadingCurrent
          applianceId={applianceId}
          size={ReadingCurrentSize.Large}
        />
      }
      readingTargetElement={<TimeReadingTarget applianceId={applianceId} />}
    />
  );
});
