import type { SxProps, Theme } from '@mui/material';

import { DropBorderRadius, DropColor } from 'app/theme';

export const externalRecipeImageSize = 64;

export const sxWrapper: SxProps<Theme> = {
  color: DropColor.TextPrimary,
  backgroundSize: 'cover',
  height: '350px',
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  paddingLeft: 'calc(1/12 * 100%)',
  paddingRight: 'calc(1/12 * 100%)',
  paddingTop: 12,
  '& > *': {
    marginLeft: 4,
  },
};

export const sxExternalRecipeImage: SxProps<Theme> = {
  height: externalRecipeImageSize,
  width: externalRecipeImageSize,
  borderRadius: DropBorderRadius.Small,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};
