import { Box, Skeleton } from '@mui/material';
import range from 'lodash/range';
import type { FC } from 'react';
import { memo } from 'react';

import { DropAppIcon } from 'components/DropAppIcon/DropAppIcon';
import {
  sxAppIcon,
  sxCollectionName,
  sxHeader,
  sxList,
  sxRecipesCount,
  sxWrapper,
} from 'features/favorites/FavoritesPage.styles';
import { RecipeCardSkeleton } from 'features/favorites/RecipeCardSkeleton';

export const favoritesPageLoadingAriaLabel = 'Loading recipes';

export const FavoritesPageSkeleton: FC = memo(function FavoritesPageSkeleton() {
  return (
    <Box sx={sxWrapper} aria-label={favoritesPageLoadingAriaLabel}>
      <Box sx={sxHeader}>
        <DropAppIcon sx={sxAppIcon} />
        <div>
          <Box sx={sxCollectionName}>
            <Skeleton
              variant="text"
              height={20}
              width={316}
              sx={{ marginTop: '7px' }}
            />
          </Box>
          <Box sx={sxRecipesCount}>
            <Skeleton
              variant="text"
              height={15}
              width={100}
              sx={{ marginTop: '18px' }}
            />
          </Box>
        </div>
      </Box>
      <Box sx={sxList}>
        {range(1, 7).map((i) => (
          <RecipeCardSkeleton key={i} />
        ))}
      </Box>
    </Box>
  );
});
