import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import type { FC } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { appTheme } from 'app/theme';
import { DeveloperMenuLoader } from 'features/debug/DeveloperMenuLoader';
import { LazyMockCookidooUI } from 'features/device/cookidoo/mockUI/LazyMockCookidooUI';
import { AuthWrapper } from 'features/pairing/AuthWrapper';
import { Snackbar } from 'features/snackbar/Snackbar';

import { routes } from './routes';
import { appStore, appHistory } from './store/store';

export const App: FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Provider store={appStore}>
          <Router history={appHistory}>
            <AuthWrapper>
              <Routes>
                {routes.map(({ children, ...route }) => (
                  <Route key={route.path} element={children} {...route} />
                ))}
              </Routes>
            </AuthWrapper>
            <DeveloperMenuLoader />
          </Router>
          <Snackbar />
        </Provider>
        <LazyMockCookidooUI />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
