import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { DropBorderRadius, DropColor, DropTypography } from 'app/theme';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import { Author } from 'features/recipe/view/Author';

export const aboutTitle = 'About';

export const About: FC = memo(function About() {
  const recipe = useSelector(selectRecipe);

  if (!recipe || recipe.flags.isExternal || !recipe.introduction) {
    return null;
  }

  return (
    <>
      <Typography
        component="h2"
        sx={{
          typography: DropTypography.H5Bold,
          my: 8,
        }}
      >
        {aboutTitle}
      </Typography>
      <Box
        sx={{
          backgroundColor: DropColor.SurfacePrimary,
          padding: 4,
          borderRadius: DropBorderRadius.Container,
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            typography: DropTypography.Body1,
          }}
        >
          {recipe.introduction}
        </Box>
        <Author />
      </Box>
    </>
  );
});
