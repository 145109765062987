import type { AppAuthData } from 'types/auth';

// For now we don't want any cookies to expire, using just as a storage
export const cookiePersistentExpiryDate = new Date(2100, 0);
export const cookieExpiredDate = new Date(2000, 0);

export enum CookieStorageKey {
  Auth = 'auth',
  CookidooId = 'cookidoo-id',
  ApplianceSecret = 'appliance-secret',
}

interface CookieStorage<T> {
  getValue: () => T | null;
  setValue(body: T | null): void;
  removeValue(): void;
}

function setCookie(key: string, value: string, expiryDate: Date) {
  document.cookie = `${key}=${value}; expires=${expiryDate.toUTCString()}`;
}

function getCookie(key: string) {
  const allCookies = document.cookie.split(';');
  for (const cookie of allCookies) {
    const [name, value] = cookie.split('=').map((x) => x.trim());
    if (name === key) {
      return value;
    }
  }
  return null;
}

function removeCookie(key: string) {
  setCookie(key, '', cookieExpiredDate);
}

export const createCookieStringStorage = (
  key: CookieStorageKey
): CookieStorage<string> => ({
  getValue: () => getCookie(key),
  setValue: (body) => {
    if (body) {
      setCookie(key, body, cookiePersistentExpiryDate);
    } else {
      removeCookie(key);
    }
  },
  removeValue: () => {
    removeCookie(key);
  },
});

export const createCookieJsonStorage = <T>(
  key: CookieStorageKey
): CookieStorage<T> => {
  const stringStorage = createCookieStringStorage(key);

  return {
    getValue: () => {
      const value = stringStorage.getValue();
      return value ? JSON.parse(value) : null;
    },
    setValue: (body) => {
      stringStorage.setValue(body ? JSON.stringify(body) : null);
    },
    removeValue: () => {
      stringStorage.removeValue();
    },
  };
};

export const cookieStorageAuth = createCookieJsonStorage<AppAuthData>(
  CookieStorageKey.Auth
);

export const cookieStorageCookidooId = createCookieStringStorage(
  CookieStorageKey.CookidooId
);

export const cookieStorageApplianceSecret = createCookieStringStorage(
  CookieStorageKey.ApplianceSecret
);
