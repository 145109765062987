import type { SagaIterator } from 'redux-saga';
import { call, takeLatest } from 'redux-saga/effects';

import { loadSecretFromCookies } from 'features/device/deviceEventsSagas';
import { loadTokenFromCookies, logoutSuccess } from 'features/login/loginSlice';
import { connectToPairingEvents } from 'features/pairing/pairingSlice';

export function* loginInit(): SagaIterator<void> {
  const isAuthenticated: boolean = yield call(loadTokenFromCookies);
  const isPaired: boolean = yield call(loadSecretFromCookies);

  if (!isAuthenticated || !isPaired) {
    yield call(connectToPairingEvents);
  }
}

export function* loginInitWatcher(): SagaIterator<void> {
  // Need to rerun whole initialization after logout to initiate pairing
  yield takeLatest(logoutSuccess, loginInit);
}

export const loginInitSagasRunOnce = [loginInit];

export const loginInitSagasRestartable = [loginInitWatcher];
