/* istanbul ignore file */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { appConfig } from 'features/config/config';

import { App } from './app/App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://b4469fabfcd148b78208e35e78d6f4c6@o761960.ingest.sentry.io/5797994',
  enabled: appConfig.isSentryEnabled(),
  environment: appConfig.environment(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
