import type { DropMediaData } from 'types/api/media';

export const getImageCopy = (
  imageData: DropMediaData,
  desiredWidth: number
): string => {
  if (!imageData.copies) {
    return imageData.uri;
  }

  const copiesSizes = Object.keys(imageData.copies);
  let closestCopySize: string | undefined;
  let closestWidth = imageData.width;
  for (const copySize of copiesSizes) {
    const copyWidth = parseInt(copySize.split('X')[0]);
    if (copyWidth === desiredWidth) {
      return imageData.copies[copySize].uri;
    }
    if (closestWidth > copyWidth && copyWidth > desiredWidth) {
      closestWidth = copyWidth;
      closestCopySize = copySize;
    }
  }
  if (!closestCopySize) {
    return imageData.uri;
  }

  return imageData.copies[closestCopySize].uri;
};
