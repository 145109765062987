/**
 * https://getdrop.atlassian.net/wiki/spaces/mobile/pages/994443332/Appliance+Control+Transitions
 */
export enum DropApplianceState {
  Off = 'off',
  Error = 'error',
  Ready = 'ready',
  Running = 'running',
  Paused = 'paused',
  Pending = 'pending',
  Programmed = 'programmed',
  ActionRequired = 'actionRequired',
  KeepWarm = 'keepWarm',
}
