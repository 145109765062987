import { deviceEventsSagasRunOnce } from 'features/device/deviceEventsSagas';
import { loginInitSagasRunOnce } from 'features/login/loginInit';

/**
 * Some sagas may be designed to run only once, for example handling
 * some single action during startup. It doesn't make sense to restart them
 * even in case of unhandled error
 */
export const getRunOnceSagas = (): ((...args: unknown[]) => unknown)[] => [
  ...loginInitSagasRunOnce,
  ...deviceEventsSagasRunOnce,
];
