import type { SvgIconTypeMap } from '@mui/material';
import { Box, SvgIcon } from '@mui/material';
import type { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { format } from 'date-fns';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { DropBorderRadius, DropColor, DropTypography } from 'app/theme';
import { Image } from 'components/Image/Image';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';

export const ariaLabelVerifiedUser = 'Verified user';
export const ariaLabelAuthorPhoto = 'Recipe author photo';

const authorImageSize = 64;
const authorBadgeSize = 24;

const VerifiedUserBadge: FC<DefaultComponentProps<SvgIconTypeMap>> = (
  props
) => (
  <SvgIcon viewBox="0 0 24 24" titleAccess={ariaLabelVerifiedUser} {...props}>
    <g transform="translate(.533 .533)" fillRule="nonzero" fill="none">
      <circle fill="#222" cx="11.733" cy="11.733" r="11.733" />
      <circle fill="#66BB6A" cx="11.733" cy="11.733" r="9.6" />
      <path
        d="m10.4 12.781 4.39-4.39a1.333 1.333 0 0 1 1.886 1.885l-5.333 5.333c-.52.521-1.365.521-1.886 0l-2.666-2.666a1.333 1.333 0 1 1 1.885-1.886l1.724 1.724z"
        fill="#222"
      />
    </g>
  </SvgIcon>
);

export const Author: FC = memo(function Author() {
  const recipe = useSelector(selectRecipe);

  if (!recipe) {
    return null;
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {recipe.user.image?.data && (
        <div>
          <Image
            sx={{
              borderRadius: DropBorderRadius.Pill,
              marginRight: 4,
            }}
            imageData={recipe.user.image?.data}
            alt={ariaLabelAuthorPhoto}
            width={authorImageSize}
          />
          {recipe.user.isPartner && (
            <VerifiedUserBadge
              sx={{
                position: 'absolute',
                top: authorImageSize - authorBadgeSize,
                left: authorImageSize - authorBadgeSize,
                width: authorBadgeSize,
                height: authorBadgeSize,
              }}
            />
          )}
        </div>
      )}
      <div>
        <Box
          sx={{
            typography: DropTypography.Subtitle1Bold,
          }}
        >
          {recipe.user.fullName}
        </Box>
        <Box
          sx={{
            typography: DropTypography.Body1,
            color: DropColor.TextSecondary,
          }}
        >
          Updated {format(recipe.updatedAt * 1000, 'd MMM yyyy')}
        </Box>
      </div>
    </Box>
  );
});
