import type { SxProps, Theme } from '@mui/material';

import { DropColor, DropTypography } from 'app/theme';

import background from './background.webp';

export const sxWrapper: SxProps<Theme> = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: DropColor.CommonBlack,
};

export const sxBackground: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: `url(${background}) center/cover`,
  opacity: 0.2,
};

export const sxContentWrapper: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  width: 10 / 12,
};

export const sxQrCode: SxProps<Theme> = {
  borderRadius: '25px',
  width: 230,
  height: 230,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: DropColor.CommonWhite,
};

export const sxTextSection: SxProps<Theme> = {
  marginLeft: 'calc(1/10 * 100%)',
  width: 6 / 10,
};

export const sxAppDescriptionWrapper: SxProps<Theme> = {
  display: 'flex',
};

export const sxAppDescriptionIcon: SxProps<Theme> = {
  marginTop: '3px',
  marginRight: 3,
  width: 64,
  height: 64,
};

export const sxAppDescriptionHeader: SxProps<Theme> = {
  typography: DropTypography.H5Bold,
  color: DropColor.TextWhiteHigh,
};

export const sxAppDescriptionSecondaryText: SxProps<Theme> = {
  typography: DropTypography.Body2,
  color: DropColor.TextWhiteMedium,
};

export const sxText: SxProps<Theme> = {
  typography: DropTypography.H5Bold,
  color: DropColor.TextWhiteHigh,
  marginTop: 9,
};
