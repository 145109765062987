import { PauseIcon } from '@dropkitchen/icons-react';
import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { ButtonColor } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { stepProgramPauseRequested } from 'features/userAppliances/requestStepProgramPause';

export const pauseButtonText = 'Pause';

export const PauseButton: FC<ProgramControlsProps> = memo(function PauseButton({
  step,
}) {
  const dispatch = useDispatch();

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    dispatch(stepProgramPauseRequested({ step }));
  };

  return (
    <IconButton
      color={ButtonColor.Secondary}
      onClick={handleClick}
      aria-label={pauseButtonText}
    >
      <PauseIcon />
    </IconButton>
  );
});
