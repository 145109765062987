/* istanbul ignore file */
import type { FC } from 'react';
import { lazy, memo, Suspense } from 'react';

import { appConfig } from 'features/config/config';

const AudioAlert = lazy(() => import('./AudioAlert'));
const TTSDialog = lazy(() => import('./TTSDialog'));
const ScaleDialog = lazy(() => import('./ScaleDialog'));

export const LazyMockCookidooUI: FC = memo(function LazyMockTM6UI() {
  return (
    <>
      {appConfig.isMockCookidooAudioAlertEnabled() && (
        <Suspense fallback={<></>}>
          <AudioAlert />
        </Suspense>
      )}
      {appConfig.isMockCookidooTTSEnabled() && (
        <Suspense fallback={<></>}>
          <TTSDialog />
        </Suspense>
      )}
      {appConfig.isMockCookidooScaleEnabled() && (
        <Suspense fallback={<></>}>
          <ScaleDialog />
        </Suspense>
      )}
    </>
  );
});
