import { createAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiPostRecipeStepExecute } from 'api/recipe';
import type { ApiRequestFnResponseType, ErrorResponse } from 'api/types';
import { ApiErrorCode } from 'api/types';
import {
  PendingType,
  runSagaWithPendingState,
} from 'features/pending/pendingSlice';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import { snackbarEnqueued } from 'features/snackbar/snackbarSlice';
import { runLocalTimer } from 'features/userAppliances/localTimer/runLocalTimer';
import {
  selectStepUsedAppliance,
  selectUserApplianceReadingsTimeExtendPending,
} from 'features/userAppliances/selectors';
import {
  userApplianceReadingsUpdated,
  UserApplianceReadingType,
} from 'features/userAppliances/userAppliancesSlice';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropStepClient } from 'types/api/step';

export const stepExecutionErrorText = 'Step execution error';
export const stepActionIsNotSupportedText =
  'Sorry! This mode is not yet supported.';

export const stepProgramStartRequested = createAction<{
  step: DropStepClient;
}>('userAppliancesSlice/stepProgramStartRequested');

function* requestStepProgramStart({
  payload: { step },
}: ReturnType<typeof stepProgramStartRequested>) {
  const usedAppliance: ReturnType<ReturnType<typeof selectStepUsedAppliance>> =
    yield select(selectStepUsedAppliance(step));

  if (!usedAppliance) {
    return;
  }

  const usedApplianceCategory = usedAppliance.appliance.category;

  switch (usedApplianceCategory) {
    case DropApplianceCategory.Timer: {
      const readingTimeExtendPending: ReturnType<
        ReturnType<typeof selectUserApplianceReadingsTimeExtendPending>
      > = yield select(
        selectUserApplianceReadingsTimeExtendPending(usedAppliance.id)
      );

      const timeTarget = readingTimeExtendPending || step.time || undefined;

      yield put(
        userApplianceReadingsUpdated({
          id: usedAppliance.id,
          reading: {
            type: UserApplianceReadingType.TimeTarget,
            value: timeTarget,
          },
        })
      );

      yield put(
        userApplianceReadingsUpdated({
          id: usedAppliance.id,
          reading: {
            type: UserApplianceReadingType.TimeRemaining,
            value: timeTarget,
          },
        })
      );

      yield put(
        userApplianceReadingsUpdated({
          id: usedAppliance.id,
          reading: {
            type: UserApplianceReadingType.TimeExtendPending,
            value: undefined,
          },
        })
      );

      yield call(runLocalTimer, {
        step,
      });
      break;
    }
    case DropApplianceCategory.Robot:
    case DropApplianceCategory.Oven:
      yield call(requestRecipeStepExecute, { step });
      // IDK why does coverage report complain about these
      /* istanbul ignore next */
      break;
    default:
      /* istanbul ignore next */
      break;
  }
}

export function* stepProgramStartRequestedWatcher() {
  yield takeEvery(stepProgramStartRequested, requestStepProgramStart);
}

export const apiPostRecipeStepExecuteSaga = createRequestApiSaga(
  apiPostRecipeStepExecute,
  'Executing recipe step'
);

const getStepExecuteErrorText = (response: ErrorResponse) => {
  if (response.details.code === ApiErrorCode.ApplianceCantPerformOperation) {
    return stepActionIsNotSupportedText;
  }
  return stepExecutionErrorText;
};

interface RequestRecipeStepExecuteArgs {
  step: DropStepClient;
}

export function* requestRecipeStepExecute({
  step,
}: RequestRecipeStepExecuteArgs): SagaIterator<void> {
  function* request(): SagaIterator {
    const recipe: ReturnType<typeof selectRecipe> = yield select(selectRecipe);

    if (!recipe || !step?.usedAppliance?.uri) {
      return;
    }

    const response: ApiRequestFnResponseType<typeof apiPostRecipeStepExecute> =
      yield call(apiPostRecipeStepExecuteSaga, {
        recipeId: recipe.id,
        stepId: step.id,
        applianceUri: step.usedAppliance.uri,
      });

    if (!response.ok) {
      yield put(
        snackbarEnqueued({
          text: getStepExecuteErrorText(response),
        })
      );
    }
  }

  yield call(runSagaWithPendingState, request, {
    pendingType: PendingType.StepStart,
    pendingId: step.id,
  });
}
