import { cookieStorageCookidooId } from 'utils/cookieStorage';

import { generateNativeId } from './generateNativeId';

/**
 * Polyfill for testing in browsers
 */
export const cookidooIdPolyfill = (): Promise<string> => {
  const cookiesId = cookieStorageCookidooId.getValue();
  if (cookiesId) {
    return Promise.resolve(cookiesId);
  }
  const generatedId = generateNativeId();
  cookieStorageCookidooId.setValue(generatedId);
  return Promise.resolve(generatedId);
};
