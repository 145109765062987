import { Box, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'rooks';

import { DropTypography } from 'app/theme';
import { selectRecipe } from 'features/recipe/recipeSlice/slice';
import {
  externalRecipeImageSize,
  sxExternalRecipeImage,
  sxWrapper,
} from 'features/recipe/view/Header.styles';
import { getImageCopy } from 'utils/getImageCopy';
import { getUrlHost } from 'utils/getUrlHost';
import { sxCompose } from 'utils/sxCompose';

import backgroundPlaceholder from './header-background-placeholder.png';

export const externalRecipeImageAltText = 'Recipe image thumbnail';

// CSS can't do a solid tint for background image, but can be achieved via linear-gradient
export const overlayStyle = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`;

export const Header: FC = memo(function Header() {
  const recipe = useSelector(selectRecipe);

  const { innerWidth } = useWindowSize();
  const isExternal = !!recipe?.flags.isExternal;
  const recipeImage = useMemo(() => {
    if (!recipe?.image?.data) {
      return undefined;
    }

    if (isExternal) {
      return getImageCopy(recipe.image.data, externalRecipeImageSize);
    }

    return innerWidth
      ? getImageCopy(recipe?.image?.data, innerWidth)
      : undefined;
  }, [recipe, isExternal, innerWidth]);

  const backgroundImage = !isExternal && recipeImage;

  if (!recipe) {
    return null;
  }

  return (
    <Box
      sx={sxCompose(
        {
          background: backgroundImage
            ? `${overlayStyle}, url(${backgroundImage})`
            : `url(${backgroundPlaceholder})`,
        },
        sxWrapper
      )}
    >
      {isExternal && recipeImage && (
        <Box
          role="img"
          component="img"
          alt={externalRecipeImageAltText}
          src={recipeImage}
          sx={sxExternalRecipeImage}
        />
      )}
      <Box>
        <Typography
          component="h1"
          sx={{
            typography: DropTypography.H5Bold,
            margin: 0,
          }}
        >
          {recipe.name}
        </Typography>
        <Box
          sx={{
            typography: DropTypography.Body2,
          }}
        >
          {isExternal ? getUrlHost(recipe.sourceUrl) : recipe.user.fullName}
        </Box>
      </Box>
    </Box>
  );
});
