import camelcaseKeys from 'camelcase-keys';

import type { DropMqttConnectionInfo } from 'types/api/events';

import mockUserEventsChannel from './eventsChannel.json';

export { mockUserEventsChannel };

export const mockDropMqttConnectionInfo = camelcaseKeys(mockUserEventsChannel, {
  deep: true,
}) as unknown as DropMqttConnectionInfo;

export const mockUserEventsChannelCamelcased = mockDropMqttConnectionInfo;
