import { ArrowDownIcon, CheckIcon } from '@dropkitchen/icons-react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Fab, Zoom } from '@mui/material';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from 'app/store/rootReducer';
import {
  DropBorderRadius,
  DropColor,
  DropTypography,
  DropZIndex,
} from 'app/theme';
import { appConfig } from 'features/config/config';
import {
  CurrentStepVisibility,
  recipeScrollToCurrentStepRequested,
  selectCurrentStepIndex,
  selectRecipe,
  selectCurrentStepVisibility,
  recipeCurrentStepCompleted,
} from 'features/recipe/recipeSlice/slice';
import { sxCompose } from 'utils/sxCompose';
import { setTimeout, clearTimeout } from 'utils/timeout';
import { useTemporaryValue } from 'utils/useTemporaryValue';

export const textNextStep = 'Next step';
export const textFinishRecipe = 'Finish recipe';
export const textYoureDone = 'You’re done. Enjoy!';
export const textMovingOn = 'Great, moving on';
export const textBackToCurrent = 'Back to current';

export const testIdArrowIcon = 'arrow-icon';
export const testIdCheckIcon = 'check-icon';

/* istanbul ignore next */
export const transitionsDuration = !appConfig.isTestEnv() ? 300 : 2;

/* istanbul ignore next */
/**
 * Period of time that Cooking Assistant transitional completing states are shown to user
 */
export const completingStatesTimeout = !appConfig.isTestEnv() ? 1000 : 1;

/* istanbul ignore next */
/**
 * Cooking Assistant appear on make page after short delay
 */
export const appearDelay = !appConfig.isTestEnv() ? 500 : 0;

const iconSize = 32;

export enum CookingAssistantState {
  CurrentStep = 'current-step',
  LastStep = 'last-step',
  Finished = 'finished',
}

export const getCookingAssistantText = (
  assistantState: CookingAssistantState,
  isCompleting: boolean,
  isVisible: boolean
): string | undefined => {
  if (isCompleting) {
    if (assistantState === CookingAssistantState.Finished) {
      return textYoureDone;
    }
    return textMovingOn;
  }

  if (!isVisible) {
    return textBackToCurrent;
  }
  return undefined;
};

export const getCookingAssistantAriaLabel = (
  assistantState: CookingAssistantState,
  isCompleting: boolean,
  isVisible: boolean
): string | undefined => {
  if (isCompleting || !isVisible) {
    return undefined;
  }

  return assistantState === CookingAssistantState.LastStep
    ? textFinishRecipe
    : textNextStep;
};

const sxIcon: SxProps<Theme> = {
  height: iconSize,
  width: iconSize,
  transition: 'transform 0.2s ease-in-out',
};

export const CookingAssistant: FC = memo(function CookingAssistant() {
  const dispatch = useDispatch();

  const recipe = useSelector(selectRecipe);
  const currentStepVisibility = useSelector(selectCurrentStepVisibility);
  const cookingAssistantState = useSelector((state: RootState) => {
    const currentStepIndex = selectCurrentStepIndex(state);

    if (currentStepIndex === null || !recipe?.steps.length) {
      return CookingAssistantState.Finished;
    }

    return currentStepIndex < recipe.steps.length - 1
      ? CookingAssistantState.CurrentStep
      : CookingAssistantState.LastStep;
  });

  const labelRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number>(iconSize);
  const [isCompleting, setIsCompleting] = useTemporaryValue({
    defaultValue: false,
    temporaryValue: true,
    timeout: completingStatesTimeout,
  });

  const buttonText = getCookingAssistantText(
    cookingAssistantState,
    isCompleting,
    currentStepVisibility === CurrentStepVisibility.Visible
  );

  const buttonAriaLabel = getCookingAssistantAriaLabel(
    cookingAssistantState,
    isCompleting,
    currentStepVisibility === CurrentStepVisibility.Visible
  );

  useLayoutEffect(() => {
    // Want to animate button width with transition
    // Width value should be defined to do it with CSS
    // So measuring button label width after initial render and text change
    const width = labelRef.current?.getBoundingClientRect().width;
    if (width) {
      setButtonWidth(width);
    }
  }, [buttonText]);

  const [appeared] = useAppearDelay();

  // We want to sequence animations when cooking assistant is disappearing, so first it will
  // shrink and zoom out disappearing afterwards
  // Using this temporary value to delay desappearing while button is shrinking
  const [isTransitionAnimating, setIsTransitionAnimating] = useTemporaryValue({
    defaultValue: false,
    temporaryValue: true,
    timeout: transitionsDuration,
  });

  useEffect(() => {
    setIsTransitionAnimating();
  }, [isCompleting, setIsTransitionAnimating]);

  const handleClick = () => {
    if (cookingAssistantState === CookingAssistantState.Finished) {
      return;
    }

    if (currentStepVisibility !== CurrentStepVisibility.Visible) {
      dispatch(recipeScrollToCurrentStepRequested());
      return;
    }

    dispatch(recipeCurrentStepCompleted());
    setIsCompleting();
  };

  if (!recipe) {
    return null;
  }

  return (
    <Zoom
      in={
        appeared &&
        (cookingAssistantState !== CookingAssistantState.Finished ||
          isCompleting ||
          isTransitionAnimating)
      }
      timeout={transitionsDuration}
      mountOnEnter
      unmountOnExit
    >
      {/* Extra div is needed because otherwise Slide transitions overwrite Fab transitions */}
      <Box
        sx={(theme) => ({
          position: 'fixed',
          zIndex: DropZIndex.CookingAssistant,
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Fab
          aria-label={buttonAriaLabel}
          variant="extended"
          sx={{
            '&, &:hover': {
              backgroundColor: DropColor.PrimaryMain,
            },
            height: iconSize,
            minWidth: iconSize,
            borderRadius: DropBorderRadius.Pill,
            boxSizing: 'content-box',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            padding: 4,
            willChange: 'width, content',
            transition: `width ${transitionsDuration}ms ease-in-out`,
            boxShadow: 'none',
            width: buttonWidth,
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              typography: DropTypography.Subtitle1Bold,
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              width: 'auto',
              '& span': {
                mx: 2,
              },
            }}
            ref={labelRef}
          >
            {isCompleting ||
            (cookingAssistantState === CookingAssistantState.LastStep &&
              currentStepVisibility === CurrentStepVisibility.Visible) ? (
              <CheckIcon sx={sxIcon} data-testid={testIdCheckIcon} />
            ) : (
              <ArrowDownIcon
                sx={sxCompose(
                  sxIcon,
                  currentStepVisibility === CurrentStepVisibility.Above && {
                    transform: 'rotate(180deg)',
                  }
                )}
                data-testid={testIdArrowIcon}
              />
            )}
            {buttonText && <span>{buttonText}</span>}
          </Box>
        </Fab>
      </Box>
    </Zoom>
  );
});

function useAppearDelay() {
  const [appeared, setAppeared] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAppeared(true);
    }, appearDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return [appeared];
}
