import { createAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';

import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import {
  userApplianceReadingsUpdated,
  UserApplianceReadingType,
  userApplianceScreenOpen,
} from 'features/userAppliances/userAppliancesSlice';
import type { DropStepClient } from 'types/api/step';

export const stepProgramExtendRequested = createAction<{
  step: DropStepClient;
}>('userAppliancesSlice/stepProgramExtendRequested');

export function* requestStepProgramExtend({
  payload: { step },
}: ReturnType<typeof stepProgramExtendRequested>) {
  const usedAppliance: ReturnType<ReturnType<typeof selectStepUsedAppliance>> =
    yield select(selectStepUsedAppliance(step));

  if (!usedAppliance) {
    return;
  }

  yield put(
    userApplianceReadingsUpdated({
      id: usedAppliance.id,
      reading: {
        type: UserApplianceReadingType.TimeExtendPending,
        value: 0,
      },
    })
  );
  yield put(
    userApplianceScreenOpen({
      id: usedAppliance.id,
    })
  );
}

export function* stepProgramExtendRequestedWatcher() {
  yield takeEvery(stepProgramExtendRequested, requestStepProgramExtend);
}
