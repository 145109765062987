import type { SxProps, Theme } from '@mui/material';

import {
  DropBorderRadius,
  DropColor,
  DropTypography,
  DropZIndex,
} from 'app/theme';
import { sxCompose } from 'utils/sxCompose';

export const sxStickyWrapper: SxProps<Theme> = {
  // Interesting trick that allows IntersectionObserver to detect sticky element stuck
  // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
  top: '-1px',
  position: 'sticky',
  marginTop: '-160px',
  marginBottom: '60px',
  width: '100%',
  zIndex: DropZIndex.AppBar,
};

export const sxBackgroundWrapper: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
};

// Background is a separate element in order to animate it
export const sxBackground = (isStuck: boolean): SxProps<Theme> =>
  sxCompose(
    {
      // Using width instead of flexBasis because WPE can't animate flexBasis
      width: 10 / 12,
      backgroundColor: DropColor.SurfaceSecondary,
      borderRadius: DropBorderRadius.Container,
      height: '100%',
      transitionProperty: 'width, border-radius',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-in-out',
    },
    isStuck && {
      width: '100%',
      borderRadius: 0,
    }
  );

export const sxTitle: SxProps<Theme> = {
  typography: DropTypography.Body2,
};

export const sxText: SxProps<Theme> = {
  typography: DropTypography.Subtitle1Bold,
};

export const sxContentFlexWrapper: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

export const sxContentWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  width: 10 / 12,
  padding: 4,
  boxSizing: 'border-box',
  zIndex: 1,
};

export const sxSectionWrapper: SxProps<Theme> = { flexBasis: '20%' };

export const sxButton: SxProps<Theme> = {
  flexBasis: '30%',
  marginLeft: '10%',
  minHeight: 60,
};
