import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC, RefCallback } from 'react';
import { useCallback, useEffect, useState, memo } from 'react';

import type { DropMediaData } from 'types/api/media';
import { getImageCopy } from 'utils/getImageCopy';
import { sxCompose } from 'utils/sxCompose';

interface ImageProps extends BoxProps {
  color?: string;
  imageData: DropMediaData;
  /**
   * If not provided - equals to 100%
   */
  width?: number;
  /**
   * If not provided - equals to width
   */
  height?: number;
  alt?: string;
}

export const Image: FC<ImageProps> = memo(function Image({
  color,
  imageData,
  width,
  height = width,
  alt,
  sx = [],
  ...props
}) {
  const [wrapperWidth, setWrapperWidth] = useState(width);
  const [imgSrc, setImgSrc] = useState<string>();

  const setRef: RefCallback<HTMLDivElement> = useCallback(
    (ref) => {
      if (width) {
        setWrapperWidth(width);
        return;
      }

      if (!ref) {
        return;
      }

      const rect = ref.getBoundingClientRect();
      setWrapperWidth(rect.width);
    },
    [width]
  );

  useEffect(() => {
    if (!wrapperWidth) {
      return;
    }
    // Might be good to add resize observer to dynamically choose image size
    setImgSrc(getImageCopy(imageData, wrapperWidth));
  }, [imageData, wrapperWidth]);

  return (
    <Box
      ref={setRef}
      sx={sxCompose(
        {
          height: height || '100%',
          width: width || '100%',
          background: `url(${imgSrc}) center/cover`,
          ...(color
            ? {
                filter: 'contrast(0.5) brightness(100)',
              }
            : {}),
        },
        sx
      )}
      role="img"
      aria-label={alt}
      {...props}
    />
  );
});
