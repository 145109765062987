import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import type { RootState } from 'app/store/rootReducer';
import { ProgramCompletedControls } from 'features/recipe/make/step/applianceControls/ProgramCompletedControls';
import { ProgramPausedControls } from 'features/recipe/make/step/applianceControls/ProgramPausedControls';
import { ProgramReadyControls } from 'features/recipe/make/step/applianceControls/ProgramReadyControls';
import { ProgramRunningControls } from 'features/recipe/make/step/applianceControls/ProgramRunningControls';
import {
  selectStepUsedAppliance,
  selectUserApplianceReadingsTimeExtendPending,
  selectUserApplianceState,
  selectUserApplianceStepRunning,
} from 'features/userAppliances/selectors';
import type { DropStepClient } from 'types/api/step';
import { DropApplianceState } from 'types/api/userAppliance';
import { sxCompose } from 'utils/sxCompose';

/**
 * States of appliance control UI\
 * Depends on appliance category and state
 * https://getdrop.atlassian.net/wiki/spaces/mobile/pages/994639930/Appliance+Control+States
 */
export enum ApplianceControlState {
  ProgramReady = 'ProgramReady',
  ProgramReceived = 'ProgramReceived',
  ProgramRunning = 'ProgramRunning',
  ProgramPaused = 'ProgramPaused',
  ProgramCompleted = 'ProgramCompleted',
  ProgramKeepWarm = 'ProgramKeepWarm',
}

export interface ProgramControlsProps {
  step: DropStepClient;
}

/**
 * Mapping between appliance state and controls for that state
 * https://getdrop.atlassian.net/wiki/spaces/mobile/pages/994443332/Appliance+Control+Transitions
 */
const applianceStateToControlStateMap: Record<
  DropApplianceState,
  ApplianceControlState
> = {
  [DropApplianceState.Off]: ApplianceControlState.ProgramReady,
  [DropApplianceState.Error]: ApplianceControlState.ProgramReady,
  [DropApplianceState.Ready]: ApplianceControlState.ProgramReady,
  [DropApplianceState.Running]: ApplianceControlState.ProgramRunning,
  [DropApplianceState.Paused]: ApplianceControlState.ProgramPaused,
  [DropApplianceState.Pending]: ApplianceControlState.ProgramCompleted,
  [DropApplianceState.Programmed]: ApplianceControlState.ProgramReceived,
  [DropApplianceState.ActionRequired]: ApplianceControlState.ProgramReceived,
  [DropApplianceState.KeepWarm]: ApplianceControlState.ProgramKeepWarm,
};

/**
 * Mapping between ApplianceControlState and exact components that represent UI for this state
 */
const applianceControlStateToComponentMap: Partial<
  Record<ApplianceControlState, FC<ProgramControlsProps>>
> = {
  [ApplianceControlState.ProgramReady]: ProgramReadyControls,
  [ApplianceControlState.ProgramRunning]: ProgramRunningControls,
  [ApplianceControlState.ProgramPaused]: ProgramPausedControls,
  [ApplianceControlState.ProgramCompleted]: ProgramCompletedControls,
};

interface SmartApplianceControlButtonsProps {
  sx?: SxProps<Theme>;
  step: DropStepClient;
}

export const SmartApplianceControlButtons: FC<SmartApplianceControlButtonsProps> =
  memo(function SmartApplianceControlButtons({ sx, step }) {
    const usedAppliance = useSelector(selectStepUsedAppliance(step));

    const applianceControlState = useSelector((state: RootState) => {
      if (!usedAppliance?.id) {
        return undefined;
      }

      const readingsTimeExtend =
        selectUserApplianceReadingsTimeExtendPending(usedAppliance.id)(state) ||
        0;
      if (readingsTimeExtend > 0) {
        return ApplianceControlState.ProgramReady;
      }

      const applianceState = selectUserApplianceState(usedAppliance.id)(state);
      if (!applianceState) {
        return ApplianceControlState.ProgramReady;
      }

      const runningStep = selectUserApplianceStepRunning(usedAppliance.id)(
        state
      );
      if (step.id === runningStep?.id) {
        return applianceStateToControlStateMap[applianceState];
      }

      return ApplianceControlState.ProgramReady;
    });

    if (!step.hasApplianceControls || !applianceControlState) {
      return null;
    }

    const ProgramControlsComponent =
      applianceControlStateToComponentMap[applianceControlState];

    if (!ProgramControlsComponent) {
      return null;
    }

    return (
      <Box
        sx={sxCompose(
          {
            display: 'flex',
            '& > *:not(:first-of-type)': {
              marginLeft: 4,
            },
            '& button div[role="img"]': {
              // TODO: Replace with colorization to font color when Image color is implemented in HOL-39
              filter: 'brightness(0)',
            },
          },
          sx
        )}
      >
        <ProgramControlsComponent step={step} />
      </Box>
    );
  });
