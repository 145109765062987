import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import type { SagaReturnType } from 'redux-saga/effects';
import { call, put, takeEvery } from 'redux-saga/effects';

import type { ApiRequestFnResponseType } from 'api/types';
import type { apiPostUserAppliancesExecute } from 'api/userAppliances';
import { DropDeviceEventsType } from 'features/device/DeviceEvents';
import {
  PendingType,
  runSagaWithPendingState,
} from 'features/pending/pendingSlice';
import { snackbarEnqueued } from 'features/snackbar/snackbarSlice';
import {
  apiPostUserAppliancesExecuteSaga,
  selectOrWaitUserApplianceTM6,
} from 'features/userAppliances/userAppliancesSagas';
import type { DropRecipeIngredient } from 'types/api/ingredient';
import { DropWeightUnit } from 'types/api/weight';

export const scalesDialogOpenErrorText = 'Scales error';

interface ExecuteWeightData {
  ingredient: DropRecipeIngredient;
}

export const userAppliancesExecuteWeightRequested =
  createAction<ExecuteWeightData>('userAppliancesSlice/executeWeightRequested');

// TODO: Think of better place for that while working on unit preferences support
const gramsToOunces = (grams: number): number =>
  parseFloat((grams * 0.035274).toFixed(2));

export function* requestUserAppliancesExecuteWeight({
  payload,
}: PayloadAction<ExecuteWeightData>): SagaIterator<void> {
  function* request(): SagaIterator {
    const userAppliance: SagaReturnType<typeof selectOrWaitUserApplianceTM6> =
      yield call(selectOrWaitUserApplianceTM6);

    const response: ApiRequestFnResponseType<
      typeof apiPostUserAppliancesExecute
    > = yield call(apiPostUserAppliancesExecuteSaga, {
      userApplianceId: userAppliance.id,
      command: {
        command: DropDeviceEventsType.Weight,
        extra: {
          ingredient: payload.ingredient.ingredient.name,
          targetWeight: {
            value: gramsToOunces(payload.ingredient.grams),
            unit: DropWeightUnit.Oz,
          },
        },
      },
    });

    if (!response.ok) {
      yield put(
        snackbarEnqueued({
          text: scalesDialogOpenErrorText,
        })
      );
    }
  }

  yield call(runSagaWithPendingState, request, {
    pendingType: PendingType.Weight,
    pendingId: payload.ingredient.id,
  });
}

export function* requestUserAppliancesExecuteWeightWatcher(): SagaIterator<void> {
  yield takeEvery(
    userAppliancesExecuteWeightRequested,
    requestUserAppliancesExecuteWeight
  );
}
