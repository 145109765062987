import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';

export const scaleDialogHeader = 'Weigh ingredient';

interface DeviceEventsState {
  connected: boolean;
  connectionError?: string;
  secret?: string;
}

export const initialState: DeviceEventsState = {
  connected: false,
};

const deviceEventsSlice = createSlice({
  name: 'deviceEventsSlice',
  initialState,
  reducers: {
    deviceEventsSecretReceived(state, { payload }: PayloadAction<string>) {
      state.secret = payload;
    },
    deviceEventsConnected(state) {
      state.connected = true;
    },
    deviceEventsDisconnected(state) {
      state.connected = false;
    },
    deviceEventsConnectionError(state, { payload }: PayloadAction<string>) {
      state.connectionError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: deviceEventsReducer,
  actions: {
    deviceEventsSecretReceived,
    deviceEventsConnected,
    deviceEventsDisconnected,
    deviceEventsConnectionError,
  },
} = deviceEventsSlice;

export const selectDeviceEventsSecret = () => (state: RootState) =>
  state.deviceEvents.secret;

export const selectDeviceEventsIsPaired =
  () =>
  (state: RootState): boolean =>
    !!selectDeviceEventsSecret()(state);

export const selectDeviceEventsConnected =
  () =>
  (state: RootState): boolean =>
    state.deviceEvents.connected;

export const selectDeviceEventsConnectionError =
  () =>
  (state: RootState): string | undefined =>
    state.deviceEvents.connectionError;
