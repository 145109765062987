import type { ButtonProps as MuiButtonProps, Theme } from '@mui/material';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import type { SystemStyleObject } from '@mui/system';
import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { DropBorderRadius, DropColor, DropTypography } from 'app/theme';
import { sxCompose } from 'utils/sxCompose';

const iconSize = 32;

export enum ButtonColor {
  Primary = 'Primary',
  Secondary = 'Secondary',
  SecondaryDark = 'SecondaryDark',
  Tertiary = 'Tertiary',
  Negative = 'Negative',
  Transparent = 'Transparent',
}

const buttonColorsSx: Record<ButtonColor, SystemStyleObject<Theme>> = {
  [ButtonColor.Primary]: {
    backgroundColor: DropColor.PrimaryMain,
    color: DropColor.PrimaryContrastText,
  },
  [ButtonColor.Secondary]: {
    backgroundColor: DropColor.SecondaryMain,
    color: DropColor.SecondaryContrastText,
  },
  [ButtonColor.SecondaryDark]: {
    backgroundColor: DropColor.SecondaryDark,
    color: DropColor.SecondaryContrastText,
  },
  [ButtonColor.Tertiary]: {
    backgroundColor: DropColor.SurfaceTertiary,
    color: DropColor.TextPrimary,
  },
  [ButtonColor.Negative]: {
    backgroundColor: DropColor.ErrorMain,
    color: DropColor.ErrorContrastText,
  },
  [ButtonColor.Transparent]: {
    backgroundColor: 'transparent',
    color: DropColor.TextPrimary,
  },
};

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color: ButtonColor;
  onClick?: MouseEventHandler;
  to?: string;
  pending?: boolean;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = memo(function Button({
  color,
  to,
  onClick,
  children,
  disabled,
  pending,
  sx = [],
  ...props
}) {
  const colorSx = buttonColorsSx[color];

  return (
    <MuiButton
      sx={sxCompose(
        {
          typography: DropTypography.Subtitle1Bold,
          p: 3,
          borderRadius: DropBorderRadius.Pill,
          transition: 'background-color 0.4s',
          minHeight: 70,
          '& svg, & div[role="img"]': {
            height: iconSize,
            width: iconSize,
            marginRight: 2,
          },
          '&:hover': colorSx,
          '&[disabled], &:hover[disabled]': !pending
            ? {
                backgroundColor: DropColor.Disabled,
                color: DropColor.SecondaryContrastText,
              }
            : // Just to override disabled styles
              colorSx,
        },
        colorSx,
        sx
      )}
      disabled={disabled || pending}
      onClick={onClick}
      fullWidth
      variant="contained"
      disableElevation
      {...(to
        ? {
            to,
            component: Link,
            role: 'link',
          }
        : undefined)}
      {...props}
    >
      {pending ? (
        <CircularProgress
          variant="indeterminate"
          size={iconSize}
          color="inherit"
        />
      ) : (
        children
      )}
    </MuiButton>
  );
});
