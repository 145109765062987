import { userEventsApiUrl } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { DropMqttConnectionInfo } from 'types/api/events';

export interface ApiGetUserEventsArgs {
  /*
   * Unique identifier of the device, required for mqtt connection
   */
  clientId: string;
}

/**
 * Get current user events channel connection details for MQTT over WS
 *
 * https://docs.kitchenos.com/reference#get-events-channel-1
 */
export const apiGetUserEvents =
  ({ clientId }: ApiGetUserEventsArgs): ApiRequestFn<DropMqttConnectionInfo> =>
  (apiContext) =>
    fetchJson<DropMqttConnectionInfo>({
      apiContext,
      httpMethod: HttpMethod.Get,
      url: `${userEventsApiUrl}?client_id=${clientId}&type=mqtt5-ws`,
    });
