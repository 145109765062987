import { StarIcon } from '@dropkitchen/icons-react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Rating, Fade } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

import type { RootState } from 'app/store/rootReducer';
import { DropColor, DropTypography } from 'app/theme';
import {
  recipeReviewRequested,
  selectRecipeUserRating,
  selectCurrentStepIndex,
} from 'features/recipe/recipeSlice/slice';
import { sxCompose } from 'utils/sxCompose';

export const thankYouHeaderText = 'Thank you!';

const sxIcon: SxProps<Theme> = {
  width: 50,
  height: 50,
  mx: 4,
};

export const Feedback: FC = () => {
  const dispatch = useDispatch();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const userRating = useSelector(selectRecipeUserRating);
  const isActive = useSelector((state: RootState) => {
    const currentStepIndex = selectCurrentStepIndex(state);
    return currentStepIndex === null;
  });

  useEffect(() => {
    if (!isActive || !wrapperRef.current) {
      return;
    }

    // Webkit doesn't support native smooth scrolling, so using lib
    elementScrollIntoView(wrapperRef.current, {
      behavior: 'smooth',
    });
  }, [isActive]);

  const handleChange = (_: unknown, newValue: number | null) => {
    // Unfortunately user can't undo review via our API
    if (newValue === null) {
      return;
    }

    dispatch(
      recipeReviewRequested({
        rating: newValue,
      })
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: isActive ? 1 : 0.5,
        transition: 'opacity 0.5s',
        px: 11,
        pt: 8,
        minHeight: '100vh',
      }}
      ref={wrapperRef}
    >
      <Box
        sx={{
          typography: DropTypography.Subtitle1Bold,
          color: DropColor.TextPrimary,
          mb: 6,
        }}
      >
        Tap to rate recipe
      </Box>
      <Rating
        name="user-rating"
        value={userRating ?? null}
        onChange={handleChange}
        icon={
          <StarIcon sx={sxCompose(sxIcon, { color: DropColor.TextPrimary })} />
        }
        emptyIcon={<StarIcon sx={sxIcon} />}
      />
      <Fade in={!!userRating}>
        <Box
          sx={{
            mt: 8,
            color: DropColor.TextPrimary,
          }}
        >
          <Box
            sx={{
              typography: DropTypography.Subtitle1Bold,
              textAlign: 'center',
            }}
          >
            {thankYouHeaderText}
          </Box>
          <Box
            sx={{
              typography: DropTypography.Body2,
              mt: 2,
              textAlign: 'center',
            }}
          >
            We use your feedback to constantly improve your experience.
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};
