import { recipesApiUrl } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { AcknowledgedSuccessResponse, ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import type { DropRecipeClient } from 'types/api/recipe';

export interface ApiGetRecipeRequest {
  recipeId: number;
  ratio?: number;
  appliancesIds: string[];
}

export const apiGetRecipe =
  ({
    recipeId,
    appliancesIds,
    ratio,
  }: ApiGetRecipeRequest): ApiRequestFn<DropRecipeClient> =>
  (apiContext) => {
    const urlParams = new URLSearchParams();
    urlParams.append('appliances', appliancesIds.join(','));
    // TODO: Implement user preferences
    urlParams.append('units', 'oz');
    urlParams.append('temperature', 'f');

    if (ratio) {
      urlParams.append('ratio', ratio.toString());
    }

    const queryString = urlParams.toString();

    return fetchJson<DropRecipeClient>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Get,
      url: `${recipesApiUrl}/${recipeId}?${queryString}`,
    });
  };

interface ApiPostRecipeStepExecuteRequest {
  recipeId: number;
  stepId: number;
  applianceUri: string;
}

export const apiPostRecipeStepExecute =
  ({
    recipeId,
    stepId,
    applianceUri,
  }: ApiPostRecipeStepExecuteRequest): ApiRequestFn<AcknowledgedSuccessResponse> =>
  (apiContext) =>
    fetchJson<AcknowledgedSuccessResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Post,
      body: {
        appliance: {
          uri: applianceUri,
        },
        // TODO: Implement user preferences
        temperature: 'f',
      },
      url: `${recipesApiUrl}/${recipeId}/steps/${stepId}/execute`,
    });

interface ApiPostRecipeStartRequest {
  recipeId: number;
  appliancesUris: string[];
}

export const apiPostRecipeStart =
  ({
    recipeId,
    appliancesUris,
  }: ApiPostRecipeStartRequest): ApiRequestFn<AcknowledgedSuccessResponse> =>
  (apiContext) =>
    fetchJson<AcknowledgedSuccessResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Post,
      body: {
        appliances: appliancesUris.map((uri) => ({
          uri,
        })),
      },
      url: `${recipesApiUrl}/${recipeId}/start`,
    });

interface ApiPostRecipeFinishRequest {
  recipeId: number;
  appliancesUris: string[];
}

export const apiPostRecipeFinish =
  ({
    recipeId,
    appliancesUris,
  }: ApiPostRecipeFinishRequest): ApiRequestFn<AcknowledgedSuccessResponse> =>
  (apiContext) =>
    fetchJson<AcknowledgedSuccessResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Post,
      body: {
        appliances: appliancesUris.map((uri) => ({
          uri,
        })),
      },
      url: `${recipesApiUrl}/${recipeId}/finish`,
    });

interface ApiPostRecipeReviewRequest {
  recipeId: number;
  rating: number;
  comment?: string;
}

export const apiPostRecipeReview =
  ({
    recipeId,
    rating,
    comment,
  }: ApiPostRecipeReviewRequest): ApiRequestFn<AcknowledgedSuccessResponse> =>
  (apiContext) =>
    fetchJson<AcknowledgedSuccessResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Client,
      httpMethod: HttpMethod.Post,
      body: {
        rating,
        comment,
      },
      url: `${recipesApiUrl}/${recipeId}/reviews`,
    });
