import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';
import {
  selectRecipe,
  selectStepById,
} from 'features/recipe/recipeSlice/slice';
import { applianceIdTm6 } from 'features/userAppliances/constants';
import { getLocalTimerId } from 'features/userAppliances/localTimer/getLocalTimerId';
import { shouldStepUseLocalTimer } from 'features/userAppliances/localTimer/shouldStepUseLocalTimer';
import {
  selectUserApplianceReadingsTimeExtendPendingLocal,
  selectUserApplianceReadingsProgramLocal,
  selectUserApplianceReadingsTimeTargetLocal,
  selectUserApplianceReadingsTimeRemainingLocal,
  selectUserApplianceReadingsTemperatureLocal,
  selectUserApplianceReadingsTemperatureTargetLocal,
} from 'features/userAppliances/userAppliancesSlice';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropStepClient } from 'types/api/step';
import type { DropUserAppliance } from 'types/api/user';
import type { DropApplianceState } from 'types/api/userAppliance';
import { getIdFromUri } from 'utils/getIdFromUri';

export const selectUserAppliances = createSelector(
  (state: RootState) => state.userAppliances.appliances,
  (appliances) => Object.values(appliances)
);

export const selectUserAppliancesLocalTimers = createSelector(
  selectUserAppliances,
  (appliances) =>
    appliances.filter(
      (x) => x.appliance.category === DropApplianceCategory.Timer
    )
);

export const selectUserAppliance =
  (id: string) =>
  (state: RootState): DropUserAppliance =>
    state.userAppliances.appliances[id];

export const selectUserAppliancesFetching = (state: RootState): boolean =>
  state.userAppliances.isFetching;

export const selectUserAppliancesFetched = (state: RootState): boolean =>
  state.userAppliances.isFetched;

export const selectUserAppliancesError = (
  state: RootState
): string | undefined => state.userAppliances.error;

export const selectUserApplianceState =
  (id: string) =>
  (state: RootState): DropApplianceState | undefined =>
    state.userAppliances.states[id];

export const selectUserApplianceReadingsTimeRemaining =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsTimeRemainingLocal(id)(state.userAppliances);

export const selectUserApplianceReadingsTimeTarget =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsTimeTargetLocal(id)(state.userAppliances);

export const selectUserApplianceReadingsTimeExtendPending =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsTimeExtendPendingLocal(id)(state.userAppliances);

export const selectUserApplianceReadingsTemperature =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsTemperatureLocal(id)(state.userAppliances);

export const selectUserApplianceReadingsTemperatureTarget =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsTemperatureTargetLocal(id)(state.userAppliances);

export const selectUserApplianceReadingsProgram =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsProgramLocal(id)(state.userAppliances);

/**
 * In order not to introduce a separate property treat undefined as exend controls hidden
 * and any other value including zero as extend controls visible
 */
export const selectUserApplianceIsTimeExtending =
  (id: string) => (state: RootState) =>
    selectUserApplianceReadingsTimeExtendPending(id)(state) !== undefined;

/**
 * Returns TM6 from user appliances\
 * Assuming there could be only single TM6 paired
 */
export const selectUserApplianceTM6 = createSelector(
  (state: RootState) => state.userAppliances.appliances,
  (appliances) =>
    Object.values(appliances).find(
      ({ appliance }) => appliance.id === applianceIdTm6
    )
);

export const selectUserAppliancesUsedInRecipe = createSelector(
  selectUserAppliances,
  selectRecipe,
  (appliances, recipe) =>
    appliances.filter(({ appliance }) =>
      recipe?.appliances.some(
        (recipeAppliance) => recipeAppliance.id === appliance.id
      )
    )
);

export const getStepUsedApplianceId = (
  step: DropStepClient
): string | undefined => {
  const shouldUseLocalTimer = shouldStepUseLocalTimer(step);

  return shouldUseLocalTimer
    ? getLocalTimerId(step.id)
    : getIdFromUri(step.usedAppliance?.uri);
};

export const selectStepUsedAppliance =
  (step: DropStepClient) =>
  (state: RootState): DropUserAppliance | undefined => {
    const usedApplianceId = getStepUsedApplianceId(step);

    if (!usedApplianceId) {
      return undefined;
    }

    return selectUserAppliance(usedApplianceId)(state);
  };

export const selectUserApplianceStepRunning =
  (id: string) =>
  (state: RootState): DropStepClient | undefined => {
    const stepId = state.userAppliances.runningStepsIds[id];
    return stepId ? selectStepById(stepId)(state) : undefined;
  };

/**
 * Id of user appliance currently open on appliance screen
 */
export const selectApplianceScreenApplianceId =
  () =>
  (state: RootState): string | undefined => {
    return state.userAppliances.applianceScreenApplianceId;
  };

/**
 * Returns if appliance screen currently open
 */
export const selectApplianceScreenIsOpen =
  () =>
  (state: RootState): boolean => {
    return !!selectApplianceScreenApplianceId()(state);
  };

/**
 * User appliance currently open on appliance screen
 */
export const selectApplianceScreenAppliance =
  () =>
  (state: RootState): DropUserAppliance | undefined => {
    const id = selectApplianceScreenApplianceId()(state);
    return id ? state.userAppliances.appliances[id] : undefined;
  };
