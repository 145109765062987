import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropColor, DropTypography } from 'app/theme';

export interface StepNumberProps {
  stepIndex: number;
  totalSteps: number;
  isActive: boolean;
}

export const StepNumber: FC<StepNumberProps> = memo(function Step({
  isActive,
  stepIndex,
  totalSteps,
}) {
  return (
    <Box
      sx={{
        typography: DropTypography.Caption,
        color: isActive ? DropColor.PrimaryMain : DropColor.TextPrimary,
      }}
    >
      Step {stepIndex + 1}/{totalSteps}
    </Box>
  );
});
