import { AddIcon, RemoveIcon } from '@dropkitchen/icons-react';
import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonColor } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import {
  recipeScalingRequested,
  selectRecipe,
  selectRecipeOriginalServings,
  selectRecipeScalingPending,
  selectRecipeTargetServings,
} from 'features/recipe/recipeSlice/slice';
import {
  sxButtonWrapper,
  sxServingsWrapper,
  sxText,
  sxTitle,
  sxWrapper,
} from 'features/recipe/view/Servings.styles';
import { sxCompose } from 'utils/sxCompose';

enum ScalingDirection {
  Increase,
  Decrease,
}

export const ariaLabelIncreaseServingButton = 'Increase serving';
export const ariaLabelDecreaseServingButton = 'Decrease serving';

interface ServingsProps {
  sx?: SxProps<Theme>;
}

export const Servings: FC<ServingsProps> = memo(function Servings({ sx }) {
  const dispatch = useDispatch();

  const recipe = useSelector(selectRecipe);
  const targetServings = useSelector(selectRecipeTargetServings);
  const originalServings = useSelector(selectRecipeOriginalServings);
  const isScalingPending = useSelector(selectRecipeScalingPending);

  const [scalingDirection, setScalingDirection] = useState<ScalingDirection>();

  if (!targetServings || !originalServings || !recipe) {
    return null;
  }

  const {
    ratioLimits: { min, max },
  } = recipe;

  const isDecreaseDisabled =
    isScalingPending || (targetServings - 1) / originalServings < min;
  const isIncreaseDisabled =
    isScalingPending || (targetServings + 1) / originalServings > max;
  const isDecreasePending =
    isScalingPending && scalingDirection === ScalingDirection.Decrease;
  const isIncreasePending =
    isScalingPending && scalingDirection === ScalingDirection.Increase;

  const handleIncreaseClick = () => {
    setScalingDirection(ScalingDirection.Increase);
    dispatch(recipeScalingRequested(targetServings + 1));
  };

  const handleDecreaseClick = () => {
    setScalingDirection(ScalingDirection.Decrease);
    dispatch(recipeScalingRequested(targetServings - 1));
  };

  return (
    <Box sx={sxCompose(sxWrapper, sx)}>
      <Box sx={sxButtonWrapper}>
        <IconButton
          aria-label={ariaLabelDecreaseServingButton}
          color={ButtonColor.SecondaryDark}
          disabled={isDecreaseDisabled}
          pending={isDecreasePending}
          onClick={handleDecreaseClick}
          size={60}
        >
          <RemoveIcon />
        </IconButton>
      </Box>
      <Box sx={sxServingsWrapper}>
        <Box sx={sxTitle}>Serves</Box>
        <Box sx={sxText(isScalingPending)}>{targetServings} People</Box>
      </Box>
      <Box sx={sxButtonWrapper}>
        <IconButton
          aria-label={ariaLabelIncreaseServingButton}
          color={ButtonColor.SecondaryDark}
          disabled={isIncreaseDisabled}
          pending={isIncreasePending}
          onClick={handleIncreaseClick}
          size={60}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
});
