import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { DoneButton } from 'features/recipe/make/step/applianceControls/DoneButton';
import { ExtendButton } from 'features/recipe/make/step/applianceControls/ExtendButton';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';

export const ProgramCompletedControls: FC<ProgramControlsProps> = memo(
  function ProgramCompletedControls({ step }) {
    const usedApplianceCategory = useSelector(selectStepUsedAppliance(step))
      ?.appliance.category;

    switch (usedApplianceCategory) {
      // case DropApplianceCategory.Robot:
      case DropApplianceCategory.Timer:
        return (
          <>
            <Box sx={{ flexBasis: '60%' }}>
              <DoneButton step={step} />
            </Box>
            <Box
              sx={{
                flexBasis: '40%',
              }}
            >
              <ExtendButton step={step} />
            </Box>
          </>
        );
      case DropApplianceCategory.Oven:
        return <DoneButton step={step} />;
      default:
        return null;
    }
  }
);
