import { createAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';

import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import {
  userApplianceScreenClosed,
  userApplianceStateSet,
} from 'features/userAppliances/userAppliancesSlice';
import type { DropStepClient } from 'types/api/step';
import { DropApplianceState } from 'types/api/userAppliance';

export const stepProgramDoneRequested = createAction<{
  step: DropStepClient;
}>('userAppliancesSlice/stepProgramDoneRequested');

export function* requestStepProgramDone({
  payload: { step },
}: ReturnType<typeof stepProgramDoneRequested>) {
  yield put(userApplianceScreenClosed());

  const usedAppliance: ReturnType<ReturnType<typeof selectStepUsedAppliance>> =
    yield select(selectStepUsedAppliance(step));
  if (!usedAppliance) {
    return;
  }

  yield put(
    userApplianceStateSet({
      id: usedAppliance?.id,
      applianceState: DropApplianceState.Ready,
      stepId: step.id,
    })
  );
}

export function* stepProgramDoneRequestedWatcher() {
  yield takeEvery(stepProgramDoneRequested, requestStepProgramDone);
}
