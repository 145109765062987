import type { DropEntity } from 'types/api/entity';

export enum PairingMqttEventType {
  AppliancePaired = 'AppliancePaired',
}

export interface DropEventAppliancePaired {
  type: PairingMqttEventType.AppliancePaired;
  timestamp: number;
  value: {
    id: string;
    uri: string;
    appliance: DropEntity<number>;
    secret: string;
    userAuthCode: string;
  };
}

export type PairingMqttEvents = DropEventAppliancePaired;
