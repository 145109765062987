import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonColor } from 'components/Button/Button';
import { Image } from 'components/Image/Image';
import { PendingType, selectPending } from 'features/pending/pendingSlice';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { stepProgramStartRequested } from 'features/userAppliances/requestStepProgramStart';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';

export const startButtonText = 'Start';

export const StartButton: FC<ProgramControlsProps> = memo(function StartButton({
  step,
}) {
  const dispatch = useDispatch();
  const usedAppliance = useSelector(selectStepUsedAppliance(step));
  const isPending = useSelector(
    selectPending({
      pendingType: PendingType.StepStart,
      pendingId: step.id,
    })
  );

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    dispatch(stepProgramStartRequested({ step }));
  };

  return (
    <Button
      color={ButtonColor.Secondary}
      onClick={handleClick}
      pending={isPending}
    >
      {usedAppliance?.appliance.image?.data && (
        <Image imageData={usedAppliance.appliance.image.data} width={32} />
      )}
      {startButtonText}
    </Button>
  );
});
