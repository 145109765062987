import { useCallback, useState } from 'react';

import { useTimeout } from 'utils/useTimeout';

export interface UseTemporaryValueArgs<TValue> {
  temporaryValue: TValue;
  defaultValue: TValue;
  timeout: number;
}

/**
 * Hook to set a temporary value for a given time. Value is reset to `defaultValue` after timeout.
 *
 * `currentValue` is the current value (default or temporary).\
 * `setTemporaryValue` is a function to set current value to `temporaryValue`.
 */
export function useTemporaryValue<TValue>({
  temporaryValue,
  defaultValue,
  timeout,
}: UseTemporaryValueArgs<TValue>): [TValue, () => void] {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [startTimeout] = useTimeout(() => {
    setCurrentValue(defaultValue);
  }, timeout);

  const setValue = useCallback(() => {
    setCurrentValue(temporaryValue);
    startTimeout();
  }, [startTimeout, temporaryValue]);

  return [currentValue, setValue];
}
