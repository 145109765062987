import { useCallback, useEffect, useRef } from 'react';

import { setTimeout, clearTimeout } from 'utils/timeout';

/**
 * Returns `start` and `clear` functions.\
 * `start` creates a timeout via `setTimeout` that calls `callback` after `delay` milliseconds.\
 * Next call to `start` will cancel the previous timeout and create a new one.\
 * `clear` clears previously created timeout.
 */
export const useTimeout = (
  callback: () => void,
  delay: number
): [start: () => void, clear: () => void] => {
  const latestCallback = useRef<() => void>();

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  const timeout = useRef<number>();

  const clear = useCallback(() => {
    if (!timeout.current) {
      return;
    }

    clearTimeout(timeout.current);
    timeout.current = undefined;
  }, []);

  const start = useCallback(() => {
    clear();

    timeout.current = setTimeout(() => {
      latestCallback.current?.();
      clear();
    }, delay);
  }, [clear, delay]);

  // Cleanup on umount
  useEffect(() => clear, [clear]);

  return [start, clear];
};
