// Secret is distributed in TM6 firmware and is passed from bootstrapping index.html in FW
// to app hosted on server via #hash parameter in format 'native-id-signature-secret=wowSuchSecret'
const nativeIdSignatureSecret = window.location.hash.split('=')[1];

const getNativeIdSignatureSecret = (): string =>
  nativeIdSignatureSecret ||
  // Staging environment has this nonsense secret for convenience, setting it as a default
  isEnv(AppEnvironment.Development, AppEnvironment.Test, AppEnvironment.Staging)
    ? 'wowSuchSecret'
    : '';

export enum AppEnvironment {
  Production = 'production',
  Partners = 'partners',
  Staging = 'staging',
  Development = 'development',
  Test = 'test',
}

const envVarsGetters = {
  environment: (): AppEnvironment =>
    process.env.REACT_APP_ENV as AppEnvironment,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  platformApiUrl: (): string => process.env.REACT_APP_PLATFORM_API_URL!,
  isSentryEnabled: (): boolean =>
    process.env.REACT_APP_SENTRY_ENABLED === 'true',
};

const isCookidooTTSAvailable = (): boolean =>
  !!window.COOKIDOO?.device?.ttsDialog;
const isCookidooScaleAvailable = (): boolean =>
  !!window.COOKIDOO?.device?.scaleDialog;
const isCookidooAudioAlertAvailable = (): boolean =>
  !!window.COOKIDOO?.device?.audioAlert;

const isMockCookidooTTSEnabled = (): boolean => !isCookidooTTSAvailable();
const isMockCookidooScaleEnabled = (): boolean => !isCookidooScaleAvailable();
const isMockCookidooAudioAlertEnabled = (): boolean =>
  !isCookidooAudioAlertAvailable();

const isEnv = (...args: AppEnvironment[]) => {
  const environment = envVarsGetters.environment();
  return args.includes(environment);
};

const isTestEnv = () => isEnv(AppEnvironment.Test);
const isDevOrTestEnv = () =>
  isEnv(AppEnvironment.Development, AppEnvironment.Test);

export const appConfig = {
  ...envVarsGetters,
  isEnv,
  isTestEnv,
  isDevOrTestEnv,
  isCookidooTTSAvailable,
  isCookidooScaleAvailable,
  isCookidooAudioAlertAvailable,
  isMockCookidooTTSEnabled,
  isMockCookidooScaleEnabled,
  isMockCookidooAudioAlertEnabled,
  getNativeIdSignatureSecret,
};
