import type { SxProps, Theme } from '@mui/material';
import type { SystemStyleObject } from '@mui/system';

/**
 * Utility function to compose multiple `SxProps<Theme>`
 *
 * Can be used like:
 * ```
 * sxCompose(
 *   sxWrapper,
 *   isActive && {
 *     backgroundColor: 'red',
 *   },
 * )
 * ```
 *
 * Witout this function we'll have to write:
 * ```
 * ..(Array.isArray(sxWrapper) ? sxWrapper : [sxWrapper]),
 * ```
 */
export const sxCompose = (
  ...args: (SxProps<Theme> | undefined | false)[]
): SxProps<Theme> =>
  args.reduce<
    (SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>))[]
  >((acc, arg) => {
    if (!arg) {
      return acc;
    }

    (Array.isArray(arg) ? arg : [arg]).forEach((sx) => {
      if (typeof sx === 'boolean' || !sx) {
        return;
      }

      (acc as SxProps<Theme>[]).push(sx);
    });
    return acc;
  }, []);
