import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonColor } from 'components/Button/Button';
import { Image } from 'components/Image/Image';
import type { ProgramControlsProps } from 'features/recipe/make/step/applianceControls/SmartApplianceControlButtons';
import { stepProgramDoneRequested } from 'features/userAppliances/requestStepProgramDone';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';

export const doneButtonText = 'Done';

export const DoneButton: FC<ProgramControlsProps> = memo(function DoneButton({
  step,
}) {
  const dispatch = useDispatch();
  const usedAppliance = useSelector(selectStepUsedAppliance(step));

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    dispatch(stepProgramDoneRequested({ step }));
  };

  return (
    <Button color={ButtonColor.Secondary} onClick={handleClick}>
      {usedAppliance?.appliance.image?.data && (
        <Image imageData={usedAppliance.appliance.image.data} width={32} />
      )}
      {doneButtonText}
    </Button>
  );
});
