import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropColor, DropTypography } from 'app/theme';

import background from './background.webp';
import screenRecording from './screen-recording.webp';

export const IntroductionScreen: FC = memo(function IntroductionScreen() {
  return (
    <>
      <Box
        component="img"
        src={screenRecording}
        sx={{
          position: 'fixed',
          top: 0,
          left: 102,
          width: 340,
          height: '100%',
        }}
      />
      <Box
        sx={{
          background: `url(${background}) no-repeat`,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        sx={{
          typography: DropTypography.H5Bold,
          color: DropColor.TextPrimary,
          position: 'fixed',
          top: 140,
          left: 500,
          width: 460,
        }}
      >
        <Box>
          1. Browse and save recipes to your TM6 collection using the app
        </Box>
        <Box sx={{ mt: 3 }}>
          2. Pull to refresh this screen to see the recipes you&apos;ve saved
        </Box>
        <Box sx={{ mt: 3 }}>3. Get cooking!</Box>
      </Box>
    </>
  );
});
