import { createAction } from '@reduxjs/toolkit';
import { call, select, takeEvery } from 'redux-saga/effects';

import { runLocalTimer } from 'features/userAppliances/localTimer/runLocalTimer';
import { selectStepUsedAppliance } from 'features/userAppliances/selectors';
import { DropApplianceCategory } from 'types/api/appliance';
import type { DropStepClient } from 'types/api/step';

export const stepProgramResumeRequested = createAction<{
  step: DropStepClient;
}>('userAppliancesSlice/stepProgramResumeRequested');

function* requestStepProgramResume({
  payload: { step },
}: ReturnType<typeof stepProgramResumeRequested>) {
  const usedAppliance: ReturnType<ReturnType<typeof selectStepUsedAppliance>> =
    yield select(selectStepUsedAppliance(step));
  const usedApplianceCategory = usedAppliance?.appliance.category;

  switch (usedApplianceCategory) {
    case DropApplianceCategory.Timer: {
      yield call(runLocalTimer, {
        step,
      });
      break;
    }
    default:
      break;
  }
}

export function* stepProgramResumeRequestedWatcher() {
  yield takeEvery(stepProgramResumeRequested, requestStepProgramResume);
}
