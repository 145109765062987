import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';

import { DropBorderRadius, DropColor, DropTypography } from 'app/theme';
import { sxCompose } from 'utils/sxCompose';

interface SmartSectionWrapperProps {
  sx?: SxProps<Theme>;
  title: string;
  secondaryTitle?: string;
}

export const SmartSectionWrapper: FC<SmartSectionWrapperProps> = ({
  sx,
  title,
  secondaryTitle,
  children,
}) => {
  return (
    <Box
      sx={sxCompose(
        {
          backgroundColor: DropColor.BackgroundDefault,
          borderRadius: DropBorderRadius.Container,
          padding: 5,
        },
        sx
      )}
    >
      {!!title && (
        <Box
          sx={{
            typography: DropTypography.Subtitle1Bold,
          }}
        >
          {title}
        </Box>
      )}

      <Box
        sx={{
          typography: DropTypography.Caption,
          marginBottom: 5,
        }}
      >
        {secondaryTitle}
      </Box>
      {children}
    </Box>
  );
};
