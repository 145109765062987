import type { FC } from 'react';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';

const DeveloperMenuLazy = lazy(() => import('./DeveloperMenu'));

export const DeveloperMenuLoader: FC = () => {
  const touchesCount = useRef(0);
  const [isOpen, setIsOpen] = useState(false);

  // Touch start
  useEffect(() => {
    const handler = (e: TouchEvent) => {
      const { clientX, clientY } = e.changedTouches[0];
      if (clientX < 50 && clientY < 50) {
        touchesCount.current++;
      } else {
        touchesCount.current = 0;
      }
      if (touchesCount.current >= 10) {
        setTimeout(() => {
          setIsOpen(true);
        }, 10);
      }
    };

    document.body.addEventListener('touchend', handler);
    return () => {
      document.body.removeEventListener('touchend', handler);
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Suspense fallback={<></>}>
          <DeveloperMenuLazy open onClose={handleClose} />
        </Suspense>
      )}
    </>
  );
};
