import type { SxProps, Theme } from '@mui/material';
import { Box, useTheme } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { DropTypography } from 'app/theme';
import { Image } from 'components/Image/Image';
import { SmartSectionWrapper } from 'features/recipe/make/step/SmartSectionWrapper';
import type { DropStepClient } from 'types/api/step';

export const smartApplianceSetupTitle = 'Smart Appliance Setup';

interface AttachmentProps {
  sx?: SxProps<Theme>;
  step: DropStepClient;
}

export const SmartApplianceSetup: FC<AttachmentProps> = memo(
  function SmartApplianceSetup({ sx, step }) {
    const theme = useTheme();
    const { attachment } = step;
    if (!attachment) {
      return null;
    }

    return (
      <SmartSectionWrapper
        sx={sx}
        title={smartApplianceSetupTitle}
        secondaryTitle={step.appliance?.name}
      >
        <Box
          sx={{
            typography: DropTypography.Body2,
            display: 'flex',
            alignItems: 'center',
            py: 2,
          }}
        >
          <Image
            sx={{
              marginRight: 2,
            }}
            imageData={attachment.image.data}
            width={32}
            alt={`${attachment.name} icon`}
            color={theme.palette.text.primary}
          />
          {attachment.name}
        </Box>
      </SmartSectionWrapper>
    );
  }
);
