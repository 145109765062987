import { AddIcon, RemoveIcon } from '@dropkitchen/icons-react';
import type { SxProps, Theme } from '@mui/material';
import { Grid, Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonColor } from 'components/Button/Button';
import { selectUserApplianceIsTimeExtending } from 'features/userAppliances/selectors';
import {
  extendTimeStep,
  userApplianceReadingsExtendTimeDecreased,
  userApplianceReadingsExtendTimeIncreased,
} from 'features/userAppliances/userAppliancesSlice';

export const ariaLabelIncreaseButton =
  `Increase time by ${extendTimeStep} seconds` as const;
export const ariaLabelDecreaseButton =
  `Decrease time by ${extendTimeStep} seconds` as const;

interface TimeExtendControlsProps {
  sx?: SxProps<Theme>;
  applianceId: string;
}

export const TimeExtendControls: FC<TimeExtendControlsProps> = memo(
  function TimeExtendControls({ sx, applianceId }) {
    const dispatch = useDispatch();

    const isExtending = useSelector(
      selectUserApplianceIsTimeExtending(applianceId)
    );

    const handleRemoveClick = () => {
      dispatch(userApplianceReadingsExtendTimeDecreased({ id: applianceId }));
    };

    const handleAddClick = () => {
      dispatch(userApplianceReadingsExtendTimeIncreased({ id: applianceId }));
    };

    if (!isExtending) {
      return null;
    }

    return (
      <Box sx={sx}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button
              aria-label={ariaLabelDecreaseButton}
              color={ButtonColor.Tertiary}
              onClick={handleRemoveClick}
            >
              <RemoveIcon />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              aria-label={ariaLabelIncreaseButton}
              color={ButtonColor.Tertiary}
              onClick={handleAddClick}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
);
