import { CloseIcon } from '@dropkitchen/icons-react';
import type { DialogProps } from '@mui/material';
import { Dialog } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonColor } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import { ApplianceScreen } from 'features/recipe/make/applianceScreen/ApplianceScreen';
import { selectApplianceScreenIsOpen } from 'features/userAppliances/selectors';
import { userApplianceScreenClosed } from 'features/userAppliances/userAppliancesSlice';

export const ariaLabelCloseButton = 'Close Appliance Screen';

export const ApplianceScreenModal: FC<Partial<Omit<DialogProps, 'open'>>> =
  memo(function ApplianceScreenModal(props) {
    const dispatch = useDispatch();
    const isApplianceScreenOpen = useSelector(selectApplianceScreenIsOpen());
    const handleCloseClick = () => dispatch(userApplianceScreenClosed());

    return (
      <Dialog open={isApplianceScreenOpen} fullScreen {...props}>
        <IconButton
          color={ButtonColor.Transparent}
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing(4),
            right: theme.spacing(4),
          })}
          onClick={handleCloseClick}
          aria-label={ariaLabelCloseButton}
        >
          <CloseIcon />
        </IconButton>
        <ApplianceScreen />
      </Dialog>
    );
  });
