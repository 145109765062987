import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { Ingredient } from 'features/recipe/make/step/Ingredient';
import { SmartSectionWrapper } from 'features/recipe/make/step/SmartSectionWrapper';
import type { DropRecipeClient } from 'types/api/recipe';
import type { DropStepClient } from 'types/api/step';

export const smartIngredientListTitle = 'Smart Ingredient List';

export interface SmartIngredientListProps {
  sx?: SxProps<Theme>;
  recipe: DropRecipeClient;
  step: DropStepClient;
}

export const SmartIngredientList: FC<SmartIngredientListProps> = memo(
  function Step({ sx, recipe, step }) {
    const ingredients = useMemo(
      () =>
        (step.ingredientGroup?.uri &&
          recipe.ingredientGroups.find(
            (x) => x.uri === step.ingredientGroup?.uri
          )?.ingredients) ||
        null,
      [recipe, step]
    );

    if (!ingredients) {
      return null;
    }

    return (
      <SmartSectionWrapper sx={sx} title={smartIngredientListTitle}>
        {ingredients.map((ingredient, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              py: 2,
            }}
          >
            <Ingredient ingredient={ingredient} showWeighButton />
          </Box>
        ))}
      </SmartSectionWrapper>
    );
  }
);
