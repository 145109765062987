import type {
  DropEnumTemperature,
  DropNumericTemperature,
} from 'types/api/temperature';
import type { DropApplianceState } from 'types/api/userAppliance';
import type { DropWeightUnit } from 'types/api/weight';

export enum DropDeviceEventsType {
  Cook = 'Cook',
  Weight = 'Weight',
}

interface DropDeviceEventCommand<TCommand extends DropDeviceEventsType> {
  command: TCommand;
  id: string;
  replyTopic: string;
}

export interface DropDeviceEventWeight
  extends DropDeviceEventCommand<DropDeviceEventsType.Weight> {
  settings?: {
    ingredient?: string;
    targetWeight?: {
      value: number;
      unit: DropWeightUnit;
    };
  };
}

/**
 * TM6 can rotate knifes clockwise for chopping, or counterclockwise for mixing. Speeds
 * directions are marked with corresponding prefixes
 */
export enum DropDeviceEventTTSDialogSpeed {
  CwSoft = 'cw_soft',
  Cw0 = 'cw_0',
  Cw05 = 'cw_0.5',
  Cw1 = 'cw_1',
  Cw15 = 'cw_1.5',
  Cw2 = 'cw_2',
  Cw25 = 'cw_2.5',
  Cw3 = 'cw_3',
  Cw35 = 'cw_3.5',
  Cw4 = 'cw_4',
  Cw45 = 'cw_4.5',
  Cw5 = 'cw_5',
  Cw55 = 'cw_5.5',
  Cw6 = 'cw_6',
  Cw65 = 'cw_6.5',
  Cw7 = 'cw_7',
  Cw75 = 'cw_7.5',
  Cw8 = 'cw_8',
  Cw85 = 'cw_8.5',
  Cw9 = 'cw_9',
  Cw95 = 'cw_9.5',
  Cw10 = 'cw_10',
  CcwSoft = 'ccw_soft',
  Ccw05 = 'ccw_0.5',
  Ccw1 = 'ccw_1',
  Ccw15 = 'ccw_1.5',
  Ccw2 = 'ccw_2',
  Ccw25 = 'ccw_2.5',
  Ccw3 = 'ccw_3',
  Ccw35 = 'ccw_3.5',
  Ccw4 = 'ccw_4',
  Ccw45 = 'ccw_4.5',
  Ccw5 = 'ccw_5',
  Ccw55 = 'ccw_5.5',
  Ccw6 = 'ccw_6',
  Ccw65 = 'ccw_6.5',
  Ccw7 = 'ccw_7',
  Ccw75 = 'ccw_7.5',
  Ccw8 = 'ccw_8',
  Ccw85 = 'ccw_8.5',
  Ccw9 = 'ccw_9',
  Ccw95 = 'ccw_9.5',
  Ccw10 = 'ccw_10',
}

export interface DropDeviceEventCook
  extends DropDeviceEventCommand<DropDeviceEventsType.Cook> {
  settings?: {
    speed?: DropDeviceEventTTSDialogSpeed;
    temperature?: DropNumericTemperature | DropEnumTemperature<'varoma'>;
    time?: number;
  };
}

export type DropDeviceEventsIncomingMessages =
  | DropDeviceEventCook
  | DropDeviceEventWeight;

export interface DropDeviceEventReplySuccess {
  ok: true;
}

export enum DropDeviceEventsReplyErrorCode {
  DialogOpenError = 'dialog_open_error',
}

interface DropDeviceEventReplyError {
  ok: false;
  code: DropDeviceEventsReplyErrorCode;
}

export enum DropDeviceEventsOutgoingType {
  Status = 'status',
}

interface DropDeviceEventOutgoingStatus {
  type: DropDeviceEventsOutgoingType.Status;
  data: {
    state: DropApplianceState;
  };
}

export type DeviceEventsOutgoingMessages =
  | DropDeviceEventReplySuccess
  | DropDeviceEventReplyError
  | DropDeviceEventOutgoingStatus;
