import type { SxProps, Theme } from '@mui/material';

import { DropTypography } from 'app/theme';

export const sxTitleWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  pt: 8,
  pb: 5,
  width: '100%',
};

export const sxTitle: SxProps<Theme> = {
  typography: DropTypography.H5Bold,
  margin: 0,
  flexGrow: 1,
};
