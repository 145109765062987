import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import type { QoS } from 'mqtt';
import type { SagaIterator, Task } from 'redux-saga';
import {
  call,
  delay,
  join,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { apiGetDevicesHello, getApiGetDevicesHelloUrl } from 'api/devices';
import type {
  ConnectionInfoFactory,
  OnConnectHandler,
  OnDisconnectHandler,
  OnErrorHandler,
  OnMessageHandler,
  OpenMqttConnection,
  PublishMessageSaga,
} from 'api/mqtt';
import { openMqttConnection } from 'api/mqtt';
import { appConfig } from 'features/config/config';
import { requestDebugMessageAdd } from 'features/debug/debugSlice';
import type {
  DeviceEventsOutgoingMessages,
  DropDeviceEventsIncomingMessages,
  DropDeviceEventWeight,
  DropDeviceEventCook,
  DropDeviceEventTTSDialogSpeed,
} from 'features/device/DeviceEvents';
import {
  DropDeviceEventsReplyErrorCode,
  DropDeviceEventsOutgoingType,
  DropDeviceEventsType,
} from 'features/device/DeviceEvents';
import type {
  CookidooDialogResult,
  CookidooScaleDialogFnArgs,
  CookidooScaleDialogWeightUnit,
  CookidooTTSDialogFnArgs,
  CookidooTTSDialogSpeedValue,
} from 'features/device/cookidoo/api';
import {
  cookidooApi,
  CookidooTTSDialogSpeedDirection,
  CookidooTTSDialogTemperatureUnit,
} from 'features/device/cookidoo/api';
import {
  deviceEventsConnected,
  deviceEventsConnectionError,
  deviceEventsDisconnected,
  deviceEventsSecretReceived,
  selectDeviceEventsSecret,
} from 'features/device/deviceEventsSlice';
import { logoutSuccess } from 'features/login/loginSlice';
import type { DropMqttConnectionInfo } from 'types/api/events';
import { DropTemperatureUnit } from 'types/api/temperature';
import { DropApplianceState } from 'types/api/userAppliance';
import type { PromiseReturnType } from 'types/promiseReturnType';
import { cookieStorageApplianceSecret } from 'utils/cookieStorage';
import { signHmac } from 'utils/signHmac';

export const openDeviceEventsMqttConnection =
  openMqttConnection as OpenMqttConnection<DropDeviceEventsIncomingMessages>;

export const scaleDialogHeader = 'Weigh ingredient';

function* saveSecretToCookies({
  payload,
}: ReturnType<typeof deviceEventsSecretReceived>): SagaIterator<void> {
  yield call(cookieStorageApplianceSecret.setValue, payload);
}

export function* saveSecretToCookiesWatcher(): SagaIterator<void> {
  yield takeLatest(deviceEventsSecretReceived, saveSecretToCookies);
}

export function* loadSecretFromCookies(): SagaIterator<boolean> {
  const secret: string | undefined = yield call(
    cookieStorageApplianceSecret.getValue
  );
  if (secret) {
    yield put(deviceEventsSecretReceived(secret));
    return true;
  }
  return false;
}

function* clearSecretFromCookies(): SagaIterator<void> {
  yield call(cookieStorageApplianceSecret.removeValue);
}

export function* clearSecretFromCookiesWatcher(): SagaIterator<void> {
  yield takeLatest(logoutSuccess, clearSecretFromCookies);
}

export const getDeviceEventsConnectionInfo: ConnectionInfoFactory =
  function* getDeviceEventsConnectionInfo(): ReturnType<ConnectionInfoFactory> {
    const secret: ReturnType<ReturnType<typeof selectDeviceEventsSecret>> =
      yield select(selectDeviceEventsSecret());

    if (!secret) {
      return;
    }

    const nativeId: PromiseReturnType<typeof cookidooApi['id']> = yield call(
      cookidooApi.id
    );

    const helloUrl: ReturnType<typeof getApiGetDevicesHelloUrl> = yield call(
      getApiGetDevicesHelloUrl,
      nativeId
    );

    const sig: PromiseReturnType<typeof signHmac> = yield call(signHmac, {
      data: `${
        // Absolute url signature is needed, but on local env - host is empty, so adding it
        /* istanbul ignore next */
        appConfig.isDevOrTestEnv() ? appConfig.platformApiUrl() : ''
      }${helloUrl}`,
      secret,
    });

    const response: PromiseReturnType<typeof apiGetDevicesHello> = yield call(
      apiGetDevicesHello,
      { nativeId, sig }
    );

    if (!response.ok) {
      yield put(deviceEventsConnectionError(response.details.message));
      return;
    }

    const {
      data: { data },
    } = response;

    return {
      ...data,
      subscriptionTopics: data.control.map(({ topic }) => topic),
    };
  };

export const handleDeviceEventsOnConnect: OnConnectHandler =
  function* handleDeviceEventsOnConnect(): ReturnType<OnConnectHandler> {
    yield call(requestDebugMessageAdd, `[D-CONNECTED]`);
    yield put(deviceEventsConnected());
  };
export const handleDeviceEventsOnDisconnect: OnDisconnectHandler =
  function* handleDeviceEventsOnDisconnect(): ReturnType<OnDisconnectHandler> {
    yield call(requestDebugMessageAdd, `[D-DISCONNECTED]`);
    yield put(deviceEventsDisconnected());
  };
export const handleDeviceEventsOnError: OnErrorHandler =
  function* handleDeviceEventsOnError({ error }): ReturnType<OnErrorHandler> {
    yield call(requestDebugMessageAdd, `[D-ERROR] ${error}`);
    yield put(deviceEventsConnectionError(error.message));
  };

export interface HandleDeviceEventsOpenDialogEventArgs<TOpenDialogFnArgs> {
  openDialogFn: (
    args: TOpenDialogFnArgs,
    state?: string
  ) => Promise<CookidooDialogResult>;
  openDialogArgs: TOpenDialogFnArgs;
  replyTopic: string;
  connectionInfo: DropMqttConnectionInfo;
}

/**
 * Both dialogs are handled same way, the only difference is open dialog API function
 */
export function* handleDeviceEventsOpenDialogEvent<TOpenDialogFnArgs>({
  openDialogFn,
  openDialogArgs,
  replyTopic,
  connectionInfo,
}: HandleDeviceEventsOpenDialogEventArgs<TOpenDialogFnArgs>): SagaIterator<void> {
  try {
    // Dialogs return a Promise that rejects if dialog could not be opened
    const task: Task = yield spawn(openDialogFn, openDialogArgs);
    const [eventsTopic] = connectionInfo.events;

    yield delay(100);
    if (!task.error()) {
      // Assuming that if promise not rejected - dialog open was successful
      yield put(
        deviceEventsPublishMessageRequested({
          topic: replyTopic,
          qos: 2,
          message: {
            ok: true,
          },
        })
      );
      yield put(
        deviceEventsPublishMessageRequested({
          topic: eventsTopic.topic,
          qos: 2,
          message: {
            type: DropDeviceEventsOutgoingType.Status,
            data: {
              state: DropApplianceState.Running,
            },
          },
        })
      );
    }
    // If promise rejected - join will throw
    yield join(task);
    yield put(
      deviceEventsPublishMessageRequested({
        topic: eventsTopic.topic,
        qos: 2,
        message: {
          type: DropDeviceEventsOutgoingType.Status,
          data: {
            state: DropApplianceState.Ready,
          },
        },
      })
    );
  } catch (e) {
    yield put(
      deviceEventsPublishMessageRequested({
        topic: replyTopic,
        qos: 2,
        message: {
          ok: false,
          code: DropDeviceEventsReplyErrorCode.DialogOpenError,
        },
      })
    );
    yield call(Sentry.captureException, e);
  }
}

const getScaleIngredientString = ({ settings }: DropDeviceEventWeight) => {
  if (!settings?.ingredient) {
    return undefined;
  }
  const { ingredient, targetWeight } = settings;
  return `${ingredient}${
    targetWeight ? ` (${targetWeight.value} ${targetWeight.unit})` : ''
  }`;
};

export function* handleDeviceEventsWeight({
  payload,
  connectionInfo,
}: {
  payload: DropDeviceEventWeight;
  connectionInfo: DropMqttConnectionInfo;
}): SagaIterator<void> {
  const ingredientString = getScaleIngredientString(payload);
  const dialogArgs: CookidooScaleDialogFnArgs = {
    description: scaleDialogHeader,
    details: ingredientString ? [ingredientString] : undefined,
    weight: payload.settings?.targetWeight && {
      unit: payload.settings?.targetWeight
        .unit as string as CookidooScaleDialogWeightUnit,
      value: payload.settings.targetWeight.value,
    },
  };

  yield call(
    handleDeviceEventsOpenDialogEvent as (
      args: HandleDeviceEventsOpenDialogEventArgs<CookidooScaleDialogFnArgs>
    ) => SagaIterator<void>,
    {
      openDialogFn: cookidooApi.openScaleDialog,
      openDialogArgs: dialogArgs,
      replyTopic: payload.replyTopic,
      connectionInfo,
    }
  );
}

const getTTSDialogSpeed = (
  eventSpeed: DropDeviceEventTTSDialogSpeed | null | undefined
): {
  value?: CookidooTTSDialogSpeedValue;
  direction?: CookidooTTSDialogSpeedDirection;
} => {
  if (!eventSpeed) {
    return {};
  }

  const [direction, speed] = eventSpeed.split('_');
  return {
    value: speed as CookidooTTSDialogSpeedValue,
    direction:
      direction === 'ccw'
        ? CookidooTTSDialogSpeedDirection.CCW
        : CookidooTTSDialogSpeedDirection.CW,
  };
};

const getTemperatureUnit = (
  eventTemperatureUnit: DropTemperatureUnit | undefined
): CookidooTTSDialogTemperatureUnit | undefined => {
  switch (eventTemperatureUnit) {
    case DropTemperatureUnit.C:
      return CookidooTTSDialogTemperatureUnit.C;
    case DropTemperatureUnit.F:
      return CookidooTTSDialogTemperatureUnit.F;
    default:
      return undefined;
  }
};

export function* handleDeviceEventsCook({
  payload,
  connectionInfo,
}: {
  payload: DropDeviceEventCook;
  connectionInfo: DropMqttConnectionInfo;
}): SagaIterator<void> {
  const { direction, value } = getTTSDialogSpeed(payload.settings?.speed);
  const dialogArgs: CookidooTTSDialogFnArgs = {
    speed: {
      value,
      direction,
    },
    temperature: {
      value: payload.settings?.temperature?.value,
      unit: getTemperatureUnit(payload.settings?.temperature?.unit),
    },
    time: payload.settings?.time ?? undefined,
  };

  yield call(
    handleDeviceEventsOpenDialogEvent as (
      args: HandleDeviceEventsOpenDialogEventArgs<CookidooTTSDialogFnArgs>
    ) => SagaIterator<void>,
    {
      openDialogFn: cookidooApi.openTTSDialog,
      openDialogArgs: dialogArgs,
      replyTopic: payload.replyTopic,
      connectionInfo,
    }
  );
}

export const handleDeviceEventsOnMessage: OnMessageHandler<DropDeviceEventsIncomingMessages> =
  function* handleDeviceEventsOnMessage({
    payload,
    topic,
    connectionInfo,
  }): ReturnType<OnMessageHandler<DropDeviceEventsIncomingMessages>> {
    yield call(
      requestDebugMessageAdd,
      `[D-IN] ${topic} ${JSON.stringify(payload)}`
    );
    switch (payload.command) {
      case DropDeviceEventsType.Weight:
        yield call(handleDeviceEventsWeight, {
          payload,
          connectionInfo,
        });
        break;
      case DropDeviceEventsType.Cook:
        yield call(handleDeviceEventsCook, {
          payload,
          connectionInfo,
        });
        break;
      default:
        break;
    }
  };

export interface DeviceEventsPublishMessageRequestedPayload {
  topic: string;
  qos?: QoS;
  message: DeviceEventsOutgoingMessages;
}

export const deviceEventsPublishMessageRequested =
  createAction<DeviceEventsPublishMessageRequestedPayload>(
    'deviceEventsSlice/deviceEventsPublishMessageRequested'
  );

export const deviceEventsPublishMessageSaga: PublishMessageSaga =
  function* deviceEventsPublishMessageSaga({
    publishMessage,
  }): ReturnType<PublishMessageSaga> {
    while (true) {
      const {
        payload,
      }: PayloadAction<DeviceEventsPublishMessageRequestedPayload> = yield take(
        deviceEventsPublishMessageRequested
      );
      yield call(
        requestDebugMessageAdd,
        `[D-OUT] ${payload.topic} ${JSON.stringify(payload.message)}`
      );
      yield call(publishMessage, payload);
    }
  };

export function* connectToDeviceEvents(): SagaIterator<void> {
  // Race will cancel MQTT connection on logout
  yield race([
    call(openDeviceEventsMqttConnection, {
      getConnectionInfo: getDeviceEventsConnectionInfo,
      onConnect: handleDeviceEventsOnConnect,
      onDisconnect: handleDeviceEventsOnDisconnect,
      onError: handleDeviceEventsOnError,
      onMessage: handleDeviceEventsOnMessage,
      publishMessageSaga: deviceEventsPublishMessageSaga,
    }),
    take(logoutSuccess),
  ]);
}

export function* connectToDeviceEventsWatcher(): SagaIterator<void> {
  yield takeLatest(deviceEventsSecretReceived, connectToDeviceEvents);
}

export const preloadScaleDialogRequested = createAction<void>(
  'deviceEventsSlice/preloadScaleDialogRequested'
);

export function* requestPreloadScaleDialog(): SagaIterator<void> {
  // requestIdleCallback is not available in WPE, we can't understand if app is busy
  // so let's just wait a bit untill loading finishes
  yield delay(500);
  yield call(cookidooApi.preloadScaleDialog);
}

export function* requestPreloadScaleDialogWatcher(): SagaIterator<void> {
  // We need to preload scale UI only once
  yield take(preloadScaleDialogRequested);
  yield call(requestPreloadScaleDialog);
}

export const deviceEventsSagasRestartable = [
  clearSecretFromCookiesWatcher,
  saveSecretToCookiesWatcher,
  connectToDeviceEventsWatcher,
];

export const deviceEventsSagasRunOnce = [requestPreloadScaleDialogWatcher];
