import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import type { RootState } from 'app/store/rootReducer';
import {
  ReadingCurrent,
  ReadingCurrentSize,
  ReadingCurrentState,
} from 'features/recipe/make/applianceScreen/ReadingCurrent';
import {
  selectUserApplianceReadingsProgram,
  selectUserApplianceReadingsTemperature,
} from 'features/userAppliances/selectors';

interface TemperatureReadingCurrentProps {
  applianceId: string;
}

export const TemperatureReadingCurrent: FC<TemperatureReadingCurrentProps> =
  memo(function TemperatureReadingCurrent({ applianceId }) {
    const temperature = useSelector(
      selectUserApplianceReadingsTemperature(applianceId)
    );

    const isProgramRunning = useSelector(
      (state: RootState) =>
        !!selectUserApplianceReadingsProgram(applianceId)(state)
    );

    return (
      <ReadingCurrent
        size={ReadingCurrentSize.Large}
        state={
          isProgramRunning
            ? ReadingCurrentState.Running
            : ReadingCurrentState.Default
        }
        value={temperature?.value.toString()}
      />
    );
  });
